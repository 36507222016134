import usePage from "../../../usePage/usePage";
function TabSelect() {
  const { state, reset, set } = usePage();
  const { tab } = state;

  return (
    <div className="grid-bar smooth list">
      <h4
        className={`quarter tab ${tab === "address" ? "selected" : ""}`}
        onClick={() => {
          reset("product_id");
          reset("process_id");
          reset("process_name");
          reset("process_desc");
          reset("process_steps");
          reset("product_desc");
          reset("product_process_id");
          reset("product_process_name");
          reset("product_parts");
          set("tab", "address");
          set("mode", "idle");
        }}
      >
        Address Book
      </h4>
      <h4
        className={`quarter tab ${tab === "process" ? "selected" : ""}`}
        onClick={() => {
          reset("product_id");
          reset("process_id");
          reset("process_name");
          reset("process_desc");
          reset("process_steps");
          reset("product_desc");
          reset("product_process_id");
          reset("product_process_name");
          reset("product_parts");
          set("tab", "process");
          set("mode", "idle");
        }}
      >
        Proccess
      </h4>
      <h4
        className={`quarter tab ${tab === "products" ? "selected" : ""}`}
        onClick={() => {
          reset("product_id");
          reset("process_id");
          reset("process_name");
          reset("process_desc");
          reset("process_steps");
          reset("product_desc");
          reset("product_process_id");
          reset("product_process_name");
          reset("product_parts");
          set("tab", "products");
          set("mode", "idle");
        }}
      >
        Products
      </h4>
      <h4
        className={`quarter tab ${tab === "invoice" ? "selected" : ""}`}
        onClick={() => {
          reset("process_id");
          reset("process_name");
          reset("process_desc");
          reset("process_steps");
          reset("product_desc");
          reset("product_process_id");
          reset("product_process_name");
          reset("product_parts");
          set("tab", "invoice");
        }}
      >
        Invoices
      </h4>
    </div>
  );
}
export default TabSelect;
