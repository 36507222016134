import DropDown from "../../../../components/DropDown";
import usePage from "../../../../usePage/usePage";
import Card from "../../../../components/Card";
import Text from "../../../../components/Text";
import ButtonPrimary from "../../../../components/ButtonPrimary";
import useServer from "../../../../hooks/useServer";

function ProductHeader() {
  const { state, set, reset, load } = usePage();
  const { post } = useServer();

  const { products, process, product_id, product_name } = state;
  return (
    <Card
      regions={`
    '. title title title .'
    '. button1 dropdown dropdown .'
    '. button2 dropdown2 dropdow2 .'
    `}
      style={`width: 75vw;`}
    >
      <Text
        region="title"
        title="Product Information"
        element="h3"
        style={`background-color: var(--tertiary); border-radius: var(--border-radius); padding: 0.5rem;`}
      />
      <ButtonPrimary
        region="button1"
        title="New"
        onClick={() => {
          set("mode", "new");
          reset("product_id");
          reset("product_skus");
          reset("product_parts");
          reset("product_process_id");
          reset("product_process_name");
          reset("product_skus_delete");
        }}
      />

      <DropDown
        region="dropdown"
        title={product_name === "" ? "Load Product" : product_name}
        value={product_id ?? ""}
      >
        {products.length > 0 &&
          products.map((store) => {
            return (
              <DropDown.Options
                onClick={async () => {
                  set("mode", "edit");
                  set("product_id", store._id);
                  set("product_name", store.name);
                  set("product_desc", store.desc);
                  set("product_process_id", store.processId);
                  const name = process.find(
                    (process) => process._id === store.processId
                  );
                  set("product_process_name", name.name);
                  set("product_parts", store.parts);
                  set("product_type", store.type);
                  load(
                    "product_skus",
                    () => post("product/skus", { productId: store._id }),
                    {
                      type: "set",
                      then: (res) => {
                        res.forEach((sku) => {
                          sku.edit = false;
                          sku.show = false;
                        });
                        return res;
                      },
                    }
                  );
                }}
                key={store._id}
                value={store._id}
                data-name={store.name}
              >
                {store.name}
              </DropDown.Options>
            );
          })}
      </DropDown>
    </Card>
  );
}
export default ProductHeader;
