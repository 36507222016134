import styled from "styled-components";

const Button = styled.a`
  grid-area: ${(props) => props.$region};
  margin-bottom: var(--mar);
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: var(--primary);
  &:hover {
    text-decoration: underline;
  }
  ${(props) => props.$more}
`;

export default function ButtonLink({
  children,
  style,
  title,
  onClick,
  region,
  href,
  newTab = false,
}) {
  return (
    <Button
      href={href}
      target={newTab ? "_blank" : ""}
      $more={style}
      $region={region}
    >
      {title}
      {children}
    </Button>
  );
}
