import Card from "../../components/Card";
import Text from "../../components/Text";
import Button from "../../components/Button";
import Table, { Row, Cell } from "../../components/Table";
import usePage from "../../usePage/usePage";
import HiddenBarcodeReader from "../../components/HiddenBarcodeReader";
import useServer from "../../hooks/useServer";
export default function AddInventory() {
  const { state, set } = usePage();

  const { post } = useServer();
  const { modal } = state;
  return (
    <Card regions={`'title totals' 'table table'`}>
      <HiddenBarcodeReader
        scan={modal.scan}
        onChange={(e) => {
          if (e.target.value === modal.scan) {
            set("modal.msg", "Already Scanned");
            set("modal.scan", "");
            return;
          }
          set("modal.scan", e.target.value);
        }}
        onSubmit={async () => {
          const data = await post("/inventory/scan", { _id: modal.scan });
          if (data.err) {
            set("modal.msg", data.err);
            set("modal.scan", "");
            return;
          }
          if (data) {
            set("modal.msg", "Scan Barcode");
            set("modal.scan", "");
            if (modal?.inventory[data.skuId]) {
              let oldTotal = parseInt(modal.inventory[data.skuId].total);
              let oldCount = parseInt(modal.inventory[data.skuId].count);
              let newTotal = oldTotal + 1;
              let newCount = oldCount + 1;
              let modaltotal = parseInt(modal.total) + newTotal;
              let modalcount = parseInt(modal.count) + newCount;
              set(`modal.inventory.${data.skuId}.count`, newCount);
              set(`modal.inventory.${data.skuId}.total`, newTotal);
              set(`modal.total`, modaltotal);
              set(`modal.count`, modalcount);
            } else {
              set(`modal.inventory.${data.skuId}`, data);
              set(`modal.total`, data.total + modal.total);
              set(`modal.count`, data.count + modal.count);
            }
          }
        }}
      />
      <Text region="title" title={modal.msg} />
      <Text
        region="totals"
        title={`Total: ${modal.total} Count: ${modal.count}`}
        style={`justify-self: end;`}
      />

      <Table
        region="table"
        header={`'customer product sku total count'`}
        style={`width: 100%`}
      >
        <Row>
          <Cell region="customer">Customer</Cell>
          <Cell region="product">Product</Cell>
          <Cell region="sku">Sku</Cell>
          <Cell region="total">Total</Cell>
          <Cell region="count">Added</Cell>
        </Row>
        {Object.entries(modal.inventory).map(([key, value]) => {
          return (
            <Row key={key}>
              <Cell region="customer">{value.customer}</Cell>
              <Cell region="product">{value.product}</Cell>
              <Cell region="sku">{value.sku}</Cell>
              <Cell region="total">{value.total}</Cell>
              <Cell region="count">{value.count}</Cell>
            </Row>
          );
        })}
      </Table>
    </Card>
  );
}
