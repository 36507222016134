import usePage from "../../../../usePage/usePage";
import Card from "../../../../components/Card";
import Text from "../../../../components/Text";
import Spinner from "../../../../icons/Spinner";
import is_overflown from "../../../../utils/is_overflown";
import Table, {
  Row,
  Cell,
  DynamicCell,
  SubTable,
} from "../../../../components/Table";
import TextButton from "../../../../components/TextButton";
import Button from "../../../../components/Button";
import DropDown from "../../../../components/DropDown";
import SearchBar from "../../../../components/SearchBar";
import NumberInputBorderless from "../../../../components/NumberInputBorderless";
import TextInput from "../../../../components/TextInput";
import React, { useRef, useEffect } from "react";
import useServer from "../../../../hooks/useServer";

export default function SkuTable() {
  const {
    state,
    toggle,
    load,
    add,
    set,
    remove,
    func,
    errorInit,
    errorCleanup,
    loaded,
  } = usePage();
  const {
    address_customerId,
    product_id,
    product_type,
    product_skus,
    product_search,
    products,
    materials,
  } = state;
  const { post } = useServer();
  useEffect(() => {
    for (let i = 0; i < product_skus.length; i++) {
      errorInit({
        [`product_skus.${i}.sku`]: {
          minLength: [1, "sku is required"],
        },
      });
    }
    return () => {
      for (let i = 0; i < product_skus.length; i++) {
        errorCleanup({
          [`product_skus.${i}.sku`]: {
            minLength: [1, "sku is required"],
          },
        });
      }
    };
  }, [product_skus.length]);

  const ref = useRef(null);

  useEffect(() => {
    let direction = "bottom";
    if (product_skus.length <= 0) {
      direction = "top";
    }

    is_overflown(ref.current, direction);
  }, [product_skus.length]);

  const filter_sku = (sku) => {
    if (!product_search) return true;
    if (sku.toLowerCase().includes(product_search.toLowerCase())) {
      return true;
    }
    return false;
  };

  const toggle_all = () => {
    let payload = [];
    if (product_skus.some((sku) => sku.show === true)) {
      payload = [...product_skus].map((sku) => {
        return {
          ...sku,
          show: false,
        };
      });
    } else {
      payload = [...product_skus].map((sku) => {
        return {
          ...sku,
          show: !sku.show,
        };
      });
    }
    set("product_skus", payload);
  };

  return (
    <Card
      ref={ref}
      region="skus"
      regions={`'info info' 'search button' 'table table' 'button2 button2'`}
      style={`box-shadow: var(--shadow); width: 100%;`}
    >
      <Text
        region="info"
        title={product_type === "product" ? "Sku List" : "Sub-Product Sku"}
        element="h3"
        style={`border-bottom: solid 1px var(--secondary); `}
      />
      {product_type === "product" && (
        <>
          <SearchBar
            region="search"
            value={product_search}
            change={(e) => {
              set("product_search", e.target.value);
            }}
            btnName="Clear"
            click={() => {
              set("product_search", "");
            }}
          />
          <Button
            region="button"
            title="+ Sku"
            style={`justify-self: end; margin-bottom: var(--margin);`}
            onClick={() => {
              add("product_skus", {
                edit: true,
                show: true,
                sku: "",
                customerId: address_customerId,
                requirements: [],
              });
            }}
          />
        </>
      )}
      <Table
        region="table"
        header={`'sku hide delete'`}
        style={`margin-top: var(--margin);`}
      >
        <Row>
          <Cell region="sku" title="Sku" element="h4" last={true} />
          <Cell retgion="hide" />
          <Cell
            region="delete"
            style={`justify-self: end; padding-right: var(--pad);`}
            last={true}
          >
            <TextButton title="Toggle All" onClick={toggle_all} />
          </Cell>
        </Row>

        {!loaded("product_skus") ? (
          <Spinner />
        ) : (
          product_skus?.map((sku, index) => {
            return (
              filter_sku(sku.sku) && (
                <React.Fragment key={`sku${index}`}>
                  <Row
                    style={
                      product_skus[index].show
                        ? `background-color: var(--tertiary)`
                        : ""
                    }
                  >
                    <Cell
                      region="sku"
                      last={true}
                      hover={true}
                      style={`display: flex; align-items: center; padding: 0 var(--pad);`}
                    >
                      {sku.edit && sku.show ? (
                        <TextInput
                          value={sku.sku}
                          placeholder="sample_sku_1..."
                          error={state.error[`product_skus.${index}.sku`]}
                          onChange={(e) => {
                            set(`product_skus.${index}.sku`, e.target.value);
                          }}
                          style={`margin-top: var(--margin);`}
                        />
                      ) : (
                        <Text
                          title={sku.sku}
                          element={sku.show ? "h2" : "p"}
                          onClick={() => {
                            toggle(`product_skus.${index}.show`);
                          }}
                        />
                      )}
                    </Cell>
                    <Cell region="hide">
                      {sku.edit && sku.show && (
                        <TextButton
                          title="Hide"
                          onClick={() => {
                            toggle(`product_skus.${index}.show`);
                          }}
                        />
                      )}
                    </Cell>
                    <Cell
                      region="delete"
                      last={true}
                      style={`display: flex; align-items: center;`}
                    >
                      <Text
                        title={`Req # ${sku.requirements.length}`}
                        style={`padding-right: var(--pad);`}
                      />
                    </Cell>
                  </Row>
                  {sku.show && (
                    <SubTable header={`'req req req req usage delete'`}>
                      <Row>
                        <Cell region="req" title="Requirements" element="h4" />
                        <Cell region="usage" title="Usage" element="h4" />
                        <Cell
                          region="delete"
                          element="h4"
                          style={`display: flex; justify-content: flex-end; padding: 0 var(--pad);`}
                        >
                          {product_type === "product" && (
                            <TextButton
                              title="Delete Sku"
                              onClick={async () => {
                                //first check if this sku was just created, it won't have id if it was
                                if (sku._id) {
                                  const check = await post(
                                    "/sku/delete/check",
                                    {
                                      sku: sku.sku,
                                      customerId: address_customerId,
                                    }
                                  );
                                  if (check.err) {
                                    set("alert_open", true);
                                    set("alert_title", "Cannot Delete Sku");
                                    set("alert_notify", true);
                                    set("alert_msg", check.msg);
                                    return;
                                  }
                                  set("alert_open", true);
                                  set("alert_title", "Delete Sku");
                                  set("alert_notify", false);
                                  set(
                                    "alert_msg",
                                    `Are you sure you want to delete "${sku.sku}"? This will remove all inventory associated with this sku after saving the product.`
                                  );
                                  func("alert_accept", () => {
                                    set("alert_open", false);
                                    add("product_skus_delete", sku._id);
                                    remove(`product_skus`, index);
                                  });
                                  return;
                                }
                                remove(`product_skus`, index);
                              }}
                            />
                          )}
                        </Cell>
                      </Row>
                      {sku.requirements.map((req, reqIndex) => {
                        return (
                          <Row key={`req${index}${reqIndex}`}>
                            <DynamicCell
                              region="req"
                              style={`flex-direction: row;`}
                            >
                              <DropDown title={req.type}>
                                <DropDown.Options
                                  title="Material"
                                  onClick={() => {
                                    set(
                                      `product_skus.${index}.requirements.${reqIndex}.type`,
                                      "Material"
                                    );
                                  }}
                                />
                                {product_type === "product" && (
                                  <DropDown.Options
                                    title="Sub-Product"
                                    onClick={() => {
                                      set(
                                        `product_skus.${index}.requirements.${reqIndex}.type`,
                                        "Sub-Product"
                                      );
                                    }}
                                  />
                                )}
                              </DropDown>
                              {req.type === "Material" && (
                                <DropDown
                                  title={
                                    req.name === ""
                                      ? "Select Requirement"
                                      : req.name
                                  }
                                >
                                  {materials.map((material, i) => {
                                    return (
                                      <DropDown.Options
                                        key={`product_skus.${index}.requirements.${reqIndex}.mat.${i}`}
                                        onClick={() => {
                                          set(
                                            `product_skus.${index}.requirements.${reqIndex}.name`,
                                            material.name
                                          );
                                          set(
                                            `product_skus.${index}.requirements.${reqIndex}.units`,
                                            material.units
                                          );
                                          set(
                                            `product_skus.${index}.requirements.${reqIndex}.typeId`,
                                            material._id
                                          );
                                          set(
                                            `product_skus.${index}.requirements.${reqIndex}.usage`,
                                            0
                                          );
                                        }}
                                        value={material.name}
                                      >
                                        {material.name}
                                      </DropDown.Options>
                                    );
                                  })}
                                </DropDown>
                              )}
                              {req.type === "Sub-Product" && (
                                <>
                                  <DropDown
                                    title={
                                      req.name === ""
                                        ? "Select Requirement"
                                        : req.name
                                    }
                                  >
                                    {products.map((product, j) => {
                                      if (
                                        product._id !== product_id &&
                                        product.type !== "product"
                                      ) {
                                        return (
                                          <DropDown.Options
                                            key={`product_skus.${index}.requirements.${reqIndex}.prod.${j}`}
                                            onClick={async () => {
                                              set(
                                                `product_skus.${index}.requirements.${reqIndex}.name`,
                                                product.name
                                              );
                                              set(
                                                `product_skus.${index}.requirements.${reqIndex}.units`,
                                                "ea"
                                              );
                                              set(
                                                `product_skus.${index}.requirements.${reqIndex}.typeId`,
                                                product._id
                                              );
                                              set(
                                                `product_skus.${index}.requirements.${reqIndex}.usage`,
                                                0
                                              );
                                            }}
                                            value={product.name}
                                          >
                                            {product.name}
                                          </DropDown.Options>
                                        );
                                      }
                                    })}
                                  </DropDown>
                                </>
                              )}
                            </DynamicCell>
                            <Cell
                              region="usage"
                              style={`display: flex; align-items: center;`}
                            >
                              <NumberInputBorderless
                                lock={req.name === ""}
                                value={req.usage}
                                onChange={(e) => {
                                  set(
                                    `product_skus.${index}.requirements.${reqIndex}.usage`,
                                    e.target.value
                                  );
                                }}
                                style={`width: 5rem;`}
                                textAlign="right"
                              />
                              <Text title={req.units} element="h5" />
                            </Cell>
                            <Cell
                              region="delete"
                              style={`display: flex; align-items: center; justify-content: flex-end; padding: 0 var(--pad);`}
                            >
                              <TextButton
                                title="Remove"
                                onClick={() => {
                                  remove(
                                    `product_skus.${index}.requirements`,
                                    reqIndex
                                  );
                                }}
                              />
                            </Cell>
                          </Row>
                        );
                      })}
                      <Row>
                        <Cell region="req">
                          <Button
                            title="+ Req"
                            onClick={() => {
                              add(`product_skus.${index}.requirements`, {
                                type: "Material",
                                typeId: "",
                                productId: "",
                                name: "",
                                usage: 0,
                                units: "",
                                price: 0,
                                skus: [],
                              });
                            }}
                          />
                          {product_type === "product" && (
                            <Button
                              title="Copy Requirements?"
                              onClick={() => {
                                set("product_modal_open", true);
                                set("product_modal_from", {
                                  ...sku,
                                  index: index,
                                });
                              }}
                            />
                          )}
                        </Cell>
                        <Cell region="usage" />
                        <Cell region="delete"></Cell>
                      </Row>
                    </SubTable>
                  )}
                </React.Fragment>
              )
            );
          })
        )}
      </Table>
      {product_type === "product" && (
        <Button
          region="button2"
          title="+ Sku"
          style={`justify-self: end; margin-top: var(--margin);`}
          onClick={() => {
            add("product_skus", {
              edit: true,
              show: true,
              sku: "",
              customerId: address_customerId,
              requirements: [],
            });
          }}
        />
      )}
    </Card>
  );
}
