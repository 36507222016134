import DropDown from "../../../components/DropDown";
import { useState, useEffect } from "react";
import usePage from "../../../usePage/usePage";

const errors = {
  "modal.currentCustomer.provider.data.selectedStore": {
    minLength: [1, "Choose Store"],
  },
};

function ShipstationStore() {
  const { state, set } = usePage({}, errors);
  const { modal } = state;

  const findName = (id) => {
    const store = modal.currentCustomer.provider.data.shipstationStoreIds.find(
      (store) => store.storeId === id
    );
    return store ? store.storeName : "Shipstation Store";
  };

  const [name, setName] = useState(
    findName(modal.currentCustomer.provider.data?.selectedStore)
  );

  useEffect(() => {
    setName(findName(modal.currentCustomer.provider.data?.selectedStore));
  }, [modal.currentCustomer.provider.data?.selectedStore]);

  return (
    <DropDown
      region="dropdown2"
      title={name}
      value={modal.currentCustomer.provider.data?.selectedStore}
      lock={modal.orderInformation.lock}
      error={state.error["modal.currentCustomer.provider.data.selectedStore"]}
    >
      {modal.currentCustomer.provider.data.shipstationStoreIds.map((store) => {
        return (
          <DropDown.Options
            className="dropdown-option"
            key={store.storeId}
            value={store.storeId}
            onClick={() => {
              set(
                "modal.currentCustomer.provider.data.selectedStore",
                store.storeId
              );
            }}
          >
            {store.storeName}
          </DropDown.Options>
        );
      })}
    </DropDown>
  );
}
export default ShipstationStore;
