import DropDown from "../../../components/DropDown";
import ShipstationStore from "./ShipstationStore";
import usePage from "../../../usePage/usePage";
import Card from "../../../components/Card";
import useServer from "../../../hooks/useServer";
function CustomerSelect() {
  const { state, set, reset, load } = usePage();
  const { post } = useServer();
  const { modal, customers } = state;

  const on_select = async (customer) => {
    set("modal.currentCustomer", customer);
    set("modal.currentCustomer.provider.data.selectedStore", "");
    reset("modal.orderItems.lines");
    load("products", () => post("/customer/products", customer._id), {
      type: "set",
      then: (res) => {
        return res.data.filter((prod) => {
          if (prod.type === "product") {
            return prod;
          }
        });
      },
    });
  };

  return (
    <Card
      region="customer"
      regions={`
      'dropdown1 dropdown2'
      `}
      style={`margin: 0; padding: 0;`}
    >
      <DropDown
        region="dropdown1"
        title={modal?.currentCustomer?.profileName || "Select Customer"}
        lock={modal.orderInformation.lock}
      >
        {customers.map((customer) => {
          return (
            <DropDown.Options
              key={customer._id}
              onClick={() => {
                on_select(customer);
              }}
            >
              {customer.profileName}
            </DropDown.Options>
          );
        })}
      </DropDown>

      {modal.currentCustomer?.provider.source === "shipstation" && (
        <ShipstationStore currentCustomer={modal.currentCustomer} />
      )}
    </Card>
  );
}
export default CustomerSelect;
