import { useState, useEffect } from "react";
import styled from "styled-components";
import ErrorBox from "./ErrorBox";

const Container = styled.div`
  grid-area: ${(props) => props.$region};
  position: relative;
  width: 100%;
  ${(props) => props.$more}
`;

const Required = styled.p`
  display: ${(props) => (props.$lock ? "none" : "block")};
  color: ${(props) => (props.$error ? "var(--error)" : "var(--primary)")};
  position: absolute;
  left: -10px;
  top: 0;
`;

const Input = styled.input`
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 11pt;
  padding: 10px;
  width: 100%;
  color: var(--text);
  border: none;
  text-align: ${(props) => props.$textAlign};
  outline: ${(props) => props.$outline};
`;

function NumberInput(props) {
  const {
    value,
    onChange,
    onKeyDown = () => {},
    placeholder,
    required,
    lock,
    error = false,
    region,
    textAlign = "left",
    style,
  } = props;

  const [hasFocus, setHasFocus] = useState(false);

  const outline = () => {
    if (error && hasFocus) {
      return "2px solid var(--error)";
    }
    if (hasFocus) {
      return "none";
    }
  };

  return (
    <Container $region={region} $more={style}>
      {required && (
        <Required $lock={lock} $error={error}>
          *
        </Required>
      )}
      <ErrorBox error={error} lock={lock}>
        <Input
          $outline={outline()}
          $textAlign={textAlign}
          type="text"
          value={value}
          disabled={lock}
          onChange={(e) => {
            const regex = /^[0-9.]*$/;
            const isNumeric = regex.test(e.target.value);
            if (isNumeric) {
              onChange(e);
            }
          }}
          onKeyDown={(e) => {
            onKeyDown(e);
          }}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          placeholder={lock === true ? "" : placeholder}
        />
      </ErrorBox>
    </Container>
  );
}
export default NumberInput;
