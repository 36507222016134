import React from "react";
import styled from "styled-components";

/*We write out all the text elements so 
that we can initialize outside of the render method*/

const style = `
  margin: 0 0 var(--mar) 0;
  padding: 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const h1 = styled.h1`
  ${style}
  ${(props) => (props.$region ? `grid-area: ${props.$region};` : "")}
  ${(props) => props.$more}
`;
const h2 = styled.h2`
  ${(props) => (props.$region ? `grid-area: ${props.$region};` : "")}
  ${style}
  ${(props) => props.$more}
`;
const h3 = styled.h3`
  ${(props) => (props.$region ? `grid-area: ${props.$region};` : "")}
  ${style}
  ${(props) => props.$more}
`;
const h4 = styled.h4`
  ${(props) => (props.$region ? `grid-area: ${props.$region};` : "")}
  ${style}
  ${(props) => props.$more}
`;
const h5 = styled.h5`
  ${(props) => (props.$region ? `grid-area: ${props.$region};` : "")}
  ${style}
  ${(props) => props.$more}
`;
const h6 = styled.h6`
  ${(props) => (props.$region ? `grid-area: ${props.$region};` : "")}
  ${style}
  ${(props) => props.$more}
`;
const b = styled.b`
  ${(props) => (props.$region ? `grid-area: ${props.$region};` : "")}
  ${style}
  ${(props) => props.$more}
`;
const p = styled.p`
  ${(props) => (props.$region ? `grid-area: ${props.$region};` : "")}
  ${style}
  ${(props) => props.$more}
`;

const choose = (element) => {
  switch (element) {
    case "h1":
      return h1;
    case "h2":
      return h2;
    case "h3":
      return h3;
    case "h4":
      return h4;
    case "h5":
      return h5;
    case "h6":
      return h6;
    case "b":
      return b;
    default:
      return p;
  }
};

export default function Text({
  title,
  element = "p",
  style,
  region,
  onClick = () => {},
  onDoubleClick = () => {},
}) {
  const StyledElement = choose(element);

  return (
    <StyledElement
      $region={region}
      $more={style}
      onDoubleClick={onDoubleClick}
      onClick={onClick}
    >
      {title}
    </StyledElement>
  );
}
