import Items from "./Items";
import Materials from "./Materials";
import usePage from "../../usePage/usePage";
import Card from "../../components/Card";
import Text from "../../components/Text";

function Body() {
  const { state } = usePage();
  const { selectedType } = state;

  return (
    <Card regions={`'title' 'body'`} style={{ boxShadow: "var(--shadow)" }}>
      <Text
        region="title"
        element="h1"
        title={selectedType === "items" ? "Customer Items" : "Materials"}
      />

      {selectedType === "items" ? <Items /> : <Materials />}
    </Card>
  );
}
export default Body;
