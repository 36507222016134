import DropDown from "../../../components/DropDown";
import ShipstationStores from "./ShipstationStores";
import usePage from "../../../usePage/usePage";
import Card from "../../../components/Card";
import Text from "../../../components/Text";
import Ellipse from "../../../icons/Ellipse";

function Provider() {
  const { state, set, loaded } = usePage();
  const { modal_provider_source, modal_provider_data, shipstation } = state;

  const on_change = (source) => {
    set("modal_provider_source", source);
    if (source === "generic") {
      set("modal_provider_data", {
        name: source,
        data: {},
      });
      return;
    } else if (source === "shipstation") {
      set("modal_provider_data", {
        ...modal_provider_data,
        name: source,
      });
      if (loaded("shipstation")) {
        set("modal_provider_data", {
          ...modal_provider_data,
          shipstationStoreIds: [
            {
              storeId: shipstation[0].storeId,
              storeName: shipstation[0].storeName,
            },
          ],
        });
      }
    }
  };
  return (
    <Card
      regions={`
        '. title title tile .'
        '. desc desc desc .'
    '. dropdown dropdown dropdown .'
    '. shipstation shipstation shipstation .'
    `}
    >
      <Text title="Provider" region="title" element="h3" />
      <Text
        region="desc"
        title="Choose if the customer orders will link from an outside source"
        element="h6"
      />

      <DropDown region="dropdown" title={modal_provider_source}>
        <DropDown.Options
          className="dropdown-option"
          onClick={() => {
            on_change("generic");
          }}
        >
          Generic
        </DropDown.Options>
        <DropDown.Options
          className="dropdown-option"
          onClick={() => {
            on_change("shipstation");
          }}
        >
          Shipstation
        </DropDown.Options>
      </DropDown>

      {modal_provider_source === "shipstation" ? (
        loaded("shipstation") ? (
          <ShipstationStores />
        ) : (
          <Ellipse region="shipstation" />
        )
      ) : (
        <></>
      )}
    </Card>
  );
}
export default Provider;
