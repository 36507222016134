import styled from "styled-components";
import { useState } from "react";
import ErrorBox from "./ErrorBox";

const Container = styled.div`
  grid-area: ${(props) => props.$region};
  position: relative;
  width: 100%;
  ${(props) => props.$more}
`;

const Required = styled.p`
  display: ${(props) => (props.$lock ? "none" : "block")};
  color: ${(props) => (props.$error ? "var(--error)" : "var(--primary)")};
  position: absolute;
  left: -10px;
  top: 0;
`;

const Input = styled.input`
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 11pt;
  padding: 10px;
  width: 100%;
  color: var(--text);
  border: 1px solid #ccc;
  outline: ${(props) => props.$outline};
`;

export default function NumberInput(props) {
  const {
    value,
    onChange,
    onKeyDown = () => {},
    placeholder,
    required,
    lock,
    error = false,
    region,
    style,
    type = "float",
  } = props;

  const [hasFocus, setHasFocus] = useState(false);

  const outline = () => {
    if (error && hasFocus) {
      return "2px solid var(--error)";
    }
    if (hasFocus) {
      return "2px solid black";
    }
  };

  const lockStatus = () => {
    if (lock === undefined) {
      return "";
    } else if (lock === false) {
      return "input-unlock";
    } else if (lock === true) {
      return "input-lock";
    }
  };

  return (
    <Container className={`${lockStatus()}`} $region={region} $more={style}>
      {required && (
        <Required $error={error} $lock={lock}>
          *
        </Required>
      )}
      <ErrorBox error={error} lock={lock}>
        <Input
          $outline={outline()}
          type="text"
          value={value}
          disabled={lock}
          onChange={(e) => {
            if (type === "int") {
              const int_regex = /^[0-9]*$/;
              const isNumeric = int_regex.test(e.target.value);
              if (isNumeric) {
                onChange(e);
              }
            }
            if (type === "float") {
              const float_regex = /^[0-9.]*$/;
              const isNumeric = float_regex.test(e.target.value);
              if (isNumeric) {
                onChange(e);
              }
            }
          }}
          onKeyDown={(e) => {
            onKeyDown(e);
          }}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          placeholder={lock === true ? "" : placeholder}
        />
      </ErrorBox>
    </Container>
  );
}
