import { useState, useEffect } from "react";
import CustomerMenu from "../features/customers/CustomerMenu";
import CustomerBody from "../features/customers/CustomerBody";
import useCustomers from "../features/customers/useCustomers";
import ModalBase from "../components/ModalBase";
import CustomerNew from "../features/customers/CustomerNew";
import Spinner from "../icons/Spinner";
import Alert from "../components/Alert";
import usePage from "../usePage/usePage";
import useServer from "../hooks/useServer";
import Container from "../components/Container";

const initial = {
  shipstation: [],
  process: [],
  sub_process: [],
  customers: [],
  products: [],
  materials: [],

  alert_title: "",
  alert_open: false,
  alert_msg: "",
  alert_type: "",
  alert_notify: false,
  alert_accept: () => {},

  modal_open: false,
  modal_profileName: "",
  modal_customerId: "",
  modal_lock: false,
  modal_address_id: "",
  modal_company: "",
  modal_firstName: "",
  modal_lastName: "",
  modal_website: "",
  modal_email: "",
  modal_phone: "",
  modal_ship_lock: false,
  modal_ship_address1: "",
  modal_ship_address2: "",
  modal_ship_address3: "",
  modal_ship_city: "",
  modal_ship_state: "",
  modal_ship_country: "US",
  modal_ship_postalCode: "",
  modal_bill_lock: false,
  modal_bill_address1: "",
  modal_bill_address2: "",
  modal_bill_address3: "",
  modal_bill_city: "",
  modal_bill_state: "",
  modal_bill_country: "US",
  modal_bill_postalCode: "",
  modal_provider_source: "generic",
  modal_provider_data: {},

  selected: "",
  tab: "address",
  mode: "idle",
  lock: false, //doulbe check this one

  address_lock: false,
  address_profileName: "",
  address_customerId: "",
  address_company: "",
  address_firstName: "",
  address_lastName: "",
  address_website: "",
  address_email: "",
  address_phone: "",
  address_book: [],
  address_id: "",

  address_ship_lock: false,
  address_ship_address1: "",
  address_ship_address2: "",
  address_ship_address3: "",
  address_ship_city: "",
  address_ship_state: "",
  address_ship_country: "US",
  address_ship_postalCode: "",
  address_bill_lock: false,
  address_bill_address1: "",
  address_bill_address2: "",
  address_bill_address3: "",
  address_bill_city: "",
  address_bill_state: "",
  address_bill_country: "US",
  address_bill_postalCode: "",

  process_id: "",
  process_name: "",
  process_type: "main",
  process_desc: "",
  process_steps: [
    {
      desc: "",
      name: "",
      type: "step",
      subProcessId: "",
    },
  ],

  product_modal_open: false,
  product_modal_from: {},
  product_modal_to: {},
  product_search: "",
  product_skus_delete: [],
  product_skus: [],
  product_lock: false,
  product_id: "",
  product_name: "",
  product_desc: "",
  product_type: "product",
  product_process_id: "",
  product_process_name: "",
  product_sub_process: [],
  product_parts: [{ name: "" }],

  orders: {},
  invoices: {},
};

function Customers() {
  const [alerts, setAlert] = useState([]);
  const { get } = useServer();
  const { state, set, load, loaded, reset } = usePage(initial);
  const {
    mode,
    alert_title,
    alert_open,
    alert_msg,
    alert_accept,
    alert_notify,
    modal_open,
  } = state;

  //LOAD CUSTOMERS
  useEffect(() => {
    async function effect() {
      load("customers", () => get("/customer/list"), { type: "init" });
    }
    effect();
  }, [load, get]);

  //LOAD MATERIALS
  useEffect(() => {
    async function effect() {
      load("materials", () => get("/materials"), { type: "init" });
    }
    effect();
  }, [load, get]);

  return (
    <Container style={`flex-direction: column`}>
      <Alert
        title={alert_title}
        close={() => {
          set("alert_notify", false);
          set("alert_open", false);
        }}
        open={alert_open}
        msg={alert_msg}
        decline={() => {
          set("alert_notify", false);
          set("alert_open", false);
        }}
        accept={alert_accept}
        notifyOnly={alert_notify}
      />
      <ModalBase
        title={mode === "new" ? "New Customer" : "Edit Customer"}
        open={modal_open}
        close={() => {
          reset("modal_open");
          reset("modal_profileName");
          reset("modal_customerId");
          reset("modal_lock");
          reset("modal_address_id");
          reset("modal_company");
          reset("modal_firstName");
          reset("modal_lastName");
          reset("modal_website");
          reset("modal_email");
          reset("modal_phone");
          reset("modal_ship_lock");
          reset("modal_ship_address1");
          reset("modal_ship_address2");
          reset("modal_ship_address3");
          reset("modal_ship_city");
          reset("modal_ship_state");
          reset("modal_ship_country");
          reset("modal_ship_postalCode");
          reset("modal_bill_lock");
          reset("modal_bill_address1");
          reset("modal_bill_address2");
          reset("modal_bill_address3");
          reset("modal_bill_city");
          reset("modal_bill_state");
          reset("modal_bill_country");
          reset("modal_bill_postalCode");
          reset("modal_provider_source");
          reset("modal_provider_data");
        }}
      >
        <CustomerNew alerts={alerts} setAlert={setAlert} />
      </ModalBase>
      <CustomerMenu />
      {!loaded("customers") ? (
        <Spinner />
      ) : (
        <CustomerBody alerts={alerts} setAlert={setAlert} />
      )}
    </Container>
  );
}
export default Customers;
