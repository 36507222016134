import Card from "../../../../components/Card";
import ErrorBox from "../../../../components/ErrorBox";
import TextInput from "../../../../components/TextInput";
import CloseIcon from "../../../../icons/Close";
import usePage from "../../../../usePage/usePage";
import Text from "../../../../components/Text";
import Button from "../../../../components/Button";
import Content from "../../../../components/Content";
import ButtonIcon from "../../../../components/ButtonIcon";
import { useEffect } from "react";

const errors = {
  product_parts: { minLength: [1, "At least one part is required"] },
};

function ProductParts() {
  const { state, set, add, remove, errorInit, errorCleanup } = usePage(
    {},
    errors
  );
  const { product_parts } = state;

  useEffect(() => {
    for (let i = 0; i < product_parts.length; i++) {
      errorInit({
        [`product_parts.${i}.name`]: {
          minLength: [1, "Part name is required"],
        },
      });
    }
    return () => {
      for (let i = 0; i < product_parts.length; i++) {
        errorCleanup({
          [`product_parts.${i}.name`]: {
            minLength: [1, "Part name is required"],
          },
        });
      }
    };
  }, [product_parts.length]);

  return (
    <Card
      region="parts"
      regions={`
    'title title title'
    'button button button'
    'parts parts parts'
    `}
      style={`box-shadow: var(--shadow); width: 100%;`}
    >
      <Text
        region="title"
        title="Product Parts"
        element="h3"
        style={`border-bottom: solid 1px var(--secondary)`}
      />
      <Button
        region="button"
        title="+ Part"
        onClick={() => {
          add("product_parts", { name: "" });
        }}
        style={`justify-self: end;`}
      />
      <ErrorBox
        region="parts"
        error={state.error["product_parts"]}
        style={`display: flex; flex-direction: column; align-items: center;`}
      >
        {product_parts.map((part, index) => {
          return (
            <Content
              key={`part${index}`}
              style={`justify-content: center; width: 50%;`}
            >
              <TextInput
                placeholder="Part Name..."
                value={part.name}
                error={state.error[`product_parts.${index}.name`]}
                onChange={(e) => {
                  set(`product_parts.${index}.name`, e.target.value);
                }}
              />
              <ButtonIcon
                tabIndex={-1}
                onClick={() => {
                  remove("product_parts", index);
                }}
              >
                <CloseIcon />
              </ButtonIcon>
            </Content>
          );
        })}
      </ErrorBox>
    </Card>
  );
}

export default ProductParts;
