import Card from "../../../../components/Card";
import useGlobalAlert from "../../../../context/GlobalAlert/useGlobalAlert";
import DropDown from "../../../../components/DropDown";
import Text from "../../../../components/Text";
import usePage from "../../../../usePage/usePage";
import ButtonPrimary from "../../../../components/ButtonPrimary";

export default function ProductModal() {
  const { state, set, reset } = usePage();
  const { product_modal_from, product_skus, product_modal_to } = state;
  const { setAlert } = useGlobalAlert();

  const copy_requiremnts = () => {
    let payload = [];
    if (product_modal_to.sku === "All") {
      payload = [...product_skus].map((sku) => {
        return {
          ...sku,
          requirements: product_modal_from.requirements,
        };
      });
    }
    if (product_modal_to.sku !== "All") {
      payload = [...product_skus].map((sku, index) => {
        if (index === product_modal_to.index) {
          return {
            ...sku,
            requirements: product_modal_from.requirements,
          };
        }
        return sku;
      });
    }

    set("product_skus", payload);
    setAlert({
      type: "success",
      msg: "Requirements copied to all SKUs. Save Product to keep changes",
    });
    set("product_modal_open", false);
    reset("product_modal_from");
    reset("product_modal_to");
    window.scrollTo(0, 0);
  };

  return (
    <Card regions={`'text text text' 'dropdown button button'`}>
      <Text
        region="text"
        title={`Copy all requirements from "${
          product_modal_from.sku === "" ? "Unamed Sku" : product_modal_from.sku
        }" to:`}
      />
      <DropDown region="dropdown" title={product_modal_to.sku || "Choose Sku"}>
        <DropDown.Options
          onClick={() => {
            set("product_modal_to", { sku: "All" });
          }}
        >
          All
        </DropDown.Options>
        {product_skus.map((sku, index) => {
          return (
            product_modal_from.index !== index && (
              <DropDown.Options
                key={`sku-${index}`}
                onClick={() => {
                  set("product_modal_to", {
                    ...sku,
                    sku: sku.sku || "Unamed Sku",
                    index: index,
                  });
                }}
              >
                {sku.sku || "Unamed Sku"}
              </DropDown.Options>
            )
          );
        })}
      </DropDown>
      <ButtonPrimary region="button" title="Copy" onClick={copy_requiremnts} />
    </Card>
  );
}
