import styled from "styled-components";
import { useState, useEffect, useRef, useContext } from "react";
import React from "react";
import ErrorBox from "./ErrorBox";
import { DynamicCellContext } from "./Table";

const Container = styled.div`
  grid-area: ${(props) => props.$region};
  position: relative;
  width: 100%;
  ${(props) => props.$more}
  ${(props) => (props.$lock ? "pointer-events: none;" : "")}
`;

const Required = styled.p`
  display: ${(props) => (props.$lock ? "none" : "block")};
  color: ${(props) => (props.$error ? "var(--error)" : "var(--primary)")};
  position: absolute;
  left: -10px;
  top: 0;
`;

const DropDownContainer = styled.div`
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: ${(props) => props.$outline};
  background-color: ${(props) =>
    props.$lock ? "#F9F9F9;" : "var(--background);"}
  color: var(--text);
`;

const Button = styled.div`
  position: relative;
  box-sizing: content-box;
  background-color: ${(props) =>
    props.$lock ? "transparent" : "var(--background);"}
  border-radius: var(--border-radius);
  color: var(--text);
  font-size: 11pt;
  height: 30px;
  width: 200px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
`;

const Text = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const OptionContainer = styled.div`
  position: absolute;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: var(--background);
  z-index: 5;
  font-size: 11pt;
  color: var(--text);
  width: 100%;
  min-width: max-content;
  overflow-y: scroll;
  max-height: 200px;
  box-shadow: var(--shadow);
`;

function CountryCodeDropDown(props) {
  const {
    value,
    lock = false,
    current,
    onOpen = () => {},
    error = false,
    required,
    region,
    style,
    onClick = () => {},
  } = props;
  const self = useRef();
  const selfButton = useRef();
  const selfChildren = useRef();
  const optionsRef = useRef();
  const [optionsIndex, setOptionIndex] = useState(-1);
  const [activeElement, setActiveElement] = useState();
  const [show, setShow] = useState(false);
  const [choosing, setChoosing] = useState(false);
  const { setAllowOverflow, allowOverflow } = useContext(DynamicCellContext);

  const choosingRef = useRef(choosing);

  useEffect(() => {
    choosingRef.current = choosing;
  }, [choosing]);

  useEffect(() => {
    const checkMouseOver = (e) => {
      try {
        let x = e.clientX;
        let y = e.clientY;
        let parent = self.current.getBoundingClientRect();
        let children = selfChildren.current.getBoundingClientRect();
        let bounds = {
          left: parent.left,
          right: children.right,
          top: parent.top,
          bottom: children.bottom,
        };
        if (
          x < bounds.left ||
          x > bounds.right ||
          y < bounds.top ||
          y > bounds.bottom
        ) {
          {
            setChoosing(false);
            setShow(false);
            setAllowOverflow(false);
          }
        }
      } catch (error) {
        setChoosing(false);
        setShow(false);
        setAllowOverflow(false);
      }
    };

    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        setShow(false);
        setAllowOverflow(false);
      }
    });
    document.addEventListener("mousemove", (e) => {
      if (choosingRef.current === true) {
        checkMouseOver(e);
      }
    });
    //Clean up
    return () => {
      document.removeEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          setShow(false);
          setAllowOverflow(false);
        }
      });
      document.removeEventListener("mousemove", (e) => {
        if (choosingRef.current === true) {
          checkMouseOver(e);
        }
      });
    };
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setShow(true);
      if (selfButton.current === activeElement && e.key === "ArrowDown") {
        setShow(true);
      }
      if (show) {
        const option = selfChildren.current.children;
        if (optionsIndex + 1 > option.length - 1) return;
        let i = optionsIndex + 1;
        optionsRef.current = option[i];
        setOptionIndex(i);
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (optionsIndex === 0 && e.key === "ArrowUp") {
        setShow(false);
        return;
      }
      if (show) {
        const option = selfChildren.current.children;
        let i = optionsIndex - 1;
        optionsRef.current = option[i];
        setOptionIndex(i);
      }
      setShow(true);
    } else if (e.key === "Enter") {
      e.preventDefault();
      setChoosing(false);
      setShow(false);
      setOptionIndex(-1);
    } else if (e.key === "Escape") {
      e.preventDefault();
      setShow(false);
      setAllowOverflow(false);
    }
  };

  useEffect(() => {
    setActiveElement(document.activeElement);
  }, [document.activeElement]);

  useEffect(() => {
    if (optionsRef.current) {
      optionsRef.current.focus();
    }
  }, [optionsRef.current]);

  const outline = () => {
    if (error && choosing) {
      return "2px solid var(--error)";
    }
    if (choosing) {
      return "2px solid black";
    }
  };

  const lockStatus = () => {
    if (lock === undefined) {
      return "";
    } else if (lock === false) {
      return "input-unlock";
    } else if (lock === true) {
      return "input-lock";
    }
  };

  const arrowDisplay = () => {
    if (lock === true) {
      return "";
    } else if (lock === false && show === false) {
      return "▼";
    } else if (lock === false && show === true) {
      return "▲";
    }
  };

  const countryListAlpha2 = {
    US: "United States of America",
    AF: "Afghanistan",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia (Plurinational State of)",
    BQ: "Bonaire, Sint Eustatius and Saba",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    CV: "Cabo Verde",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CD: "Congo (the Democratic Republic of the)",
    CG: "Congo",
    CK: "Cook Islands",
    CR: "Costa Rica",
    HR: "Croatia",
    CU: "Cuba",
    CW: "Curaçao",
    CY: "Cyprus",
    CZ: "Czechia",
    CI: "Côte d'Ivoire",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    SZ: "Eswatini",
    ET: "Ethiopia",
    FK: "Falkland Islands  [Malvinas]",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and McDonald Islands",
    VA: "Holy See",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran (Islamic Republic of)",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Korea (the Democratic People's Republic of)",
    KR: "Korea (the Republic of)",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia (Federated States of)",
    MD: "Moldova (the Republic of)",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestine, State of",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    MK: "Republic of North Macedonia",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    RE: "Réunion",
    BL: "Saint Barthélemy",
    SH: "Saint Helena, Ascension and Tristan da Cunha",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin (French part)",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SX: "Sint Maarten (Dutch part)",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania, United Republic of",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom of Great Britain and Northern Ireland",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela (Bolivarian Republic of)",
    VN: "Viet Nam",
    VG: "Virgin Islands (British)",
    VI: "Virgin Islands (U.S.)",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
    AX: "Åland Islands",
  };

  return (
    <Container
      className={`${lockStatus()}`}
      $region={region}
      $more={style}
      $lock={lock}
    >
      {required && (
        <Required $error={error} $lock={lock}>
          *
        </Required>
      )}
      <ErrorBox error={error}>
        <DropDownContainer $lock={lock} $outline={outline()} ref={self}>
          <Button
            $lock={lock}
            ref={selfButton}
            tabIndex={0}
            onClick={() => {
              if (lock) return;
              show === false ? setShow(true) : setShow(false);
              setChoosing(!choosing);
              onOpen();
              setAllowOverflow(!allowOverflow);
            }}
            onKeyDown={handleKeyDown}
            disabled={choosing}
          >
            <Text>{`${arrowDisplay()} ${countryListAlpha2[value]}`}</Text>
          </Button>
          {show && (
            <OptionContainer
              ref={selfChildren}
              onClick={(e) => {
                setChoosing(false);
                setShow(false);
                setAllowOverflow(false);
              }}
            >
              {Object.keys(countryListAlpha2).map((key, index) => {
                return (
                  <Options
                    key={key}
                    value={key}
                    myIndex={index}
                    selectedIndex={optionsIndex}
                    onClick={onClick}
                  >
                    {countryListAlpha2[key]}
                  </Options>
                );
              })}
            </OptionContainer>
          )}
        </DropDownContainer>
      </ErrorBox>
    </Container>
  );
}

const Option = styled.div`
  padding: 10px;
  cursor: pointer;
  overflow: hidden;
  white-space: normal;
  text-overflow: clip;
  &:hover {
    background-color: var(--tertiary);
  }
`;

export const Options = ({
  value,
  children,
  title,
  onClick,
  myIndex,
  selectedIndex,
}) => {
  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Enter" && myIndex === selectedIndex) {
        e.preventDefault();
        onClick(value);
      }
    });
  }, [myIndex, selectedIndex]);

  return (
    <Option
      value={value}
      style={
        myIndex === selectedIndex
          ? { backgroundColor: "var(--tertiary)" }
          : null
      }
      onClick={() => onClick(value)}
    >
      {title}
      {children}
    </Option>
  );
};

export default CountryCodeDropDown;
