import { createContext, useMemo } from "react";
import usePageSetup from "./usePageSetup";

export const PageContext = createContext({ page: {} });

//While its convient to just call usePage at the top of every component,
//to optimize anything expensive, just call it one component up and memoize
//the children component and pass the state down as props.
export const PageProvider = ({ children }) => {
  const page = usePageSetup();

  return <PageContext.Provider value={page}>{children}</PageContext.Provider>;
};
