function BarcodeSVG(props) {
  const { img, item, prevIndex } = props;
  console.log(item);
  return (
    <div className="pagebreak">
      <div className="barcode">
        {img.missing === true ? (
          <>
            <svg
              className="barcode-img"
              height={200}
              width={200}
              viewBox={`0 0 20 20`}
            ></svg>
            <div className="barcode-text">
              <div className="item ">
                <b>Tracking Unavailiable: </b>
              </div>
              <div className="item ">
                <b>Batch: </b>
                {item.batchName}
              </div>
              <div className="item ">
                <b>Order: </b>
                <b>{item.orderNumber}</b>
              </div>
              <div className="item ellipsis item">
                <b>Sku: </b>
                {item.sku}
              </div>
              <div className="item ellipsis item">
                <b>Name: </b>
                {item.name}
              </div>
            </div>
          </>
        ) : (
          <>
            <svg
              className="barcode-img"
              height={img.height}
              width={img.width}
              viewBox={`${img.viewBox[0]} ${img.viewBox[1]} ${img.viewBox[2]} ${img.viewBox[3]}`}
            >
              <g>
                {img.rect.map((dot, index) => {
                  return (
                    <rect
                      key={`${prevIndex}_${index}`}
                      x={dot.x}
                      y={dot.y}
                      width={dot.width}
                      height={dot.height}
                      style={{ fill: dot.color }}
                    />
                  );
                })}
              </g>
            </svg>
            <div className="barcode-text">
              <div className="item ">
                <b>B: </b>
                {item.batchName}
              </div>
              <div className="item ">
                <b>O: </b>
                <b>{item.orderNumber}</b>
              </div>
              <div className="item ellipsis item">
                <b>S: </b>
                {item.sku}
              </div>
              <div className="item ellipsis item">
                <b>N: </b>
                {item.name}
              </div>
              <div className="item ellipsis item">
                <b>P: </b>
                {item.product}
              </div>
              <div className="item ellipsis item">
                <b>P: </b>
                {`${item.partNumber}/${item.totalParts} ${item.partName}`}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default BarcodeSVG;
