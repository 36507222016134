import OrderMenu from "../features/orders/OrderMenu";
import Spinner from "../icons/Spinner";
import CueModal from "../features/orders/body/CueModal";
import ModalBase from "../components/ModalBase";
import OrderNew from "../features/orders/OrderNew";
import Body from "../features/orders/body/Body";
import useGlobalAlert from "../context/GlobalAlert/useGlobalAlert";
import usePage from "../usePage/usePage";
import useServer from "../hooks/useServer";
import { useEffect } from "react";
import Container from "../components/Container";

const initial = {
  filters: [],
  search: "",
  mode: "create",
  select: {},
  clear: false,

  modal: {
    open: false,
    msg: "",
    currentCustomer: {
      lock: false,
      _id: "",
      addressBook: [],
      provider: {},
    },
    addressId: "",
    orderInformation: {
      lock: false,
      _id: "",
      orderNumber: "",
      batches: [],
      status: "",
    },
    general: {
      hidden: false,
      lock: false,
      firstName: "",
      lastName: "",
      company: "",
      website: "",
      phone: "",
      email: "",
    },
    shippingAddress: {
      hidden: false,
      lock: false,
      address1: "",
      address2: "",
      address3: "",
      city: "",
      state: "",
      postalCode: "",
      country: "US",
      residential: true,
    },
    billingAddress: {
      hidden: false,
      lock: false,
      address1: "",
      address2: "",
      address3: "",
      city: "",
      state: "",
      postalCode: "",
      country: "US",
    },
    orderItems_loading: true,
    orderItems: {
      lock: false,
      lines: [
        {
          providerItemId: "",
          productId: "",
          product: "",
          name: "",
          sku: "",
          skus: [],
          quantity: "",
          unitPrice: "",
        },
      ],
    },
  },
  menu: {
    count: 0,
    dateFilter: "Oldest",
    customerFilter: "All",
    customerFilterDispaly: "All",
  },
  cueSidebar: {
    open: false,
    count: 0,
    batchName: "",
    batchDate: "",
    orderItems: [],
  },
  cueTable_loading: true,
  cueTable: {
    open: false,
    _id: "",
    orderNumber: "",
    customerName: "",
    order: {},
  },
  cueModal: {
    open: false,
    subProducts: [],
  },

  tableInfo: {
    localName: "",
    header: [],
    rows: [],
    columns: [],
  },
  orders: [],
  inventory: [],
  customers: [],
  products: [],
};

function Orders() {
  const { get, post } = useServer();
  const { state, set, reset, load, loaded, init } = usePage(initial, {});
  const { modal, cueModal, mode } = state;
  const { setAlert } = useGlobalAlert();

  // LOAD ORDERS
  useEffect(() => {
    async function effect() {
      load("orders", () => get("/orders/with/batches/and/skus"), {
        type: "none",
        then: (res) => {
          init({ orders: res.orders });
          // init({ inventory: res.skus }); //currently disabled in backend
        },
      });
    }
    effect();
  }, [get, init, load]);

  //LOAD CUSTOMER
  useEffect(() => {
    async function effect() {
      load("customers", () => get("/customer/list"), { type: "set" });
    }
    effect();
  }, [get, set, load]);

  let name = `${mode?.charAt(0).toUpperCase() + mode?.slice(1)} Order`;
  //COMPONENT

  return (
    <>
      <Container>
        <ModalBase
          title={name}
          open={modal?.open}
          msg={modal?.msg}
          close={() => {
            reset("modal");
            reset("products");
            reset("mode");
            setAlert({ msg: "", type: "none" });
          }}
          checkOverflow={true}
          overflowReason={modal?.orderItems?.lines?.length}
        >
          <OrderNew />
        </ModalBase>
        <ModalBase
          title="Add Sub-Products"
          className="modal-flyin"
          open={cueModal?.open}
          close={() => {
            reset("cueModal");
          }}
        >
          <CueModal />
        </ModalBase>
        <OrderMenu />
        {!loaded("orders") ? <Spinner /> : <Body />}
      </Container>
    </>
  );
}

export default Orders;
