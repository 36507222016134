import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
import SearchBar from "../../components/SearchBar";
import usePage from "../../usePage/usePage";
import Text from "../../components/Text";
import Button from "../../components/Button";
import Card from "../../components/Card";

function Menu() {
  const { state, set } = usePage();
  const { setAlert } = useGlobalAlert();
  const { selectedType, search } = state;

  //COMPONENT
  return (
    <Card
      regions={`'title title title' 'button search button2'`}
      style={{ boxShadow: "var(--shadow)" }}
    >
      <Text region="title" title="Inventory" element="h1" />
      <Button
        region="button"
        onClick={() => {
          setAlert({ type: "none", msg: "" });
          if (selectedType === "items") {
            set("selectedType", "materials");
          } else {
            set("selectedType", "items");
          }
        }}
        title={selectedType === "items" ? "Material View" : "Item View"}
      />
      <SearchBar
        region="search"
        value={search}
        change={(e) => {
          e.preventDefault();
          set("search", e.target.value);
        }}
      />
      <Button
        region="button2"
        onClick={() => {
          set("modal.open", true);
          set("modal.msg", "Scan Barcode");
        }}
        title="Scan Barcode"
        style={`justify-self: end;`}
      />
    </Card>
  );
}
export default Menu;
