import styled from "styled-components";

const StyleContainer = styled.div`
    display: "flex",
    width: "100%",
    height: "100%",
    ${(props) => props.$more}
`;

export default function Container({ children, style }) {
  return <StyleContainer $more={style}>{children}</StyleContainer>;
}
