import DropDown from "../../components/DropDown";
import useServer from "../../hooks/useServer";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
import usePage from "../../usePage/usePage";
import Card from "../../components/Card";
import Text from "../../components/Text";
import ButtonPrimary from "../../components/ButtonPrimary";
import { useEffect, useState } from "react";

function CustomerMenu() {
  const { state, set, reset, load, loaded, init } = usePage();
  const [dropdown, setDropdown] = useState("Load Customer");
  const { customers, selected } = state;
  const { get, post } = useServer();
  const { setAlert } = useGlobalAlert();

  const handleOnNew = async () => {
    set("modal_open", true);
    set("mode", "new");
    await load("shipstation", () => get("shipstation/stores"), {
      type: "init",
    });
  };

  const format_address = (customer) => {
    let clone = { ...customer };
    return {
      address_lock: false,
      address_customerId: clone._id,
      address_profileName: clone.profileName,
      address_company: clone.addressBook[0].company,
      address_firstName: clone.addressBook[0].firstName,
      address_lastName: clone.addressBook[0].lastName,
      address_website: clone.addressBook[0].website,
      address_email: clone.addressBook[0].email,
      address_phone: clone.addressBook[0].phone,
      address_book: clone.addressBook,
      address_id: clone.addressBook[0]._id,
      address_ship_address1: clone.addressBook[0].shippingAddress.address1,
      address_ship_address2: clone.addressBook[0].shippingAddress.address2,
      address_ship_address3: clone.addressBook[0].shippingAddress.address3,
      address_ship_city: clone.addressBook[0].shippingAddress.city,
      address_ship_state: clone.addressBook[0].shippingAddress.state,
      address_ship_country: clone.addressBook[0].shippingAddress.country,
      address_ship_postalCode: clone.addressBook[0].shippingAddress.postalCode,
      address_bill_address1: clone.addressBook[0].billingAddress.address1,
      address_bill_address2: clone.addressBook[0].billingAddress.address2,
      address_bill_address3: clone.addressBook[0].billingAddress.address3,
      address_bill_city: clone.addressBook[0].billingAddress.city,
      address_bill_state: clone.addressBook[0].billingAddress.state,
      address_bill_country: clone.addressBook[0].billingAddress.country,
      address_bill_postalCode: clone.addressBook[0].billingAddress.postalCode,
    };
  };

  const on_load = async (customer) => {
    let clone = { ...customer };
    clone = {
      ...clone,
      addressBook: clone.addressBook.map((address) => {
        return {
          ...address,
          customerId: clone._id,
          lock: true,
        };
      }),
    };
    // reset();
    set("mode", "idle");
    set("selected", clone.profileName);
    reset("product_id");
    Object.entries(format_address(clone)).forEach(([key, value]) => {
      set(key, value);
    });

    await load("process", () => post("/customer/processes", clone._id), {
      type: "init",
      then: (data) => {
        let sub = [];
        let main = [];
        data.data.forEach((process) => {
          if (process.type === "sub") {
            sub.push(process);
          } else {
            main.push(process);
          }
        });
        init({ sub_process: sub });
        (async () => {
          await load("products", () => post("/customer/products", clone._id), {
            type: "init",
            then: (data) => {
              return data.data;
            },
          });
        })(); //Appologies future me for this but we dont' want to change how load works just to suite this one case
        return main;
      },
    });
  };

  useEffect(() => {
    if (!loaded("customer")) {
      setDropdown("Loading...");
    }

    if (selected === "") {
      setDropdown("Load Customer");
    } else {
      setDropdown(selected);
    }
  }, [selected]);

  return (
    <Card
      regions={`'title title' 'dropdown button'`}
      style={`box-shadow: var(--shadow); width: 100%;`}
    >
      <Text region="title" title="Customers" element="h1" />
      <DropDown region="dropdown" value={selected} title={dropdown}>
        {loaded("customers") &&
          customers?.map((customer) => {
            return (
              <DropDown.Options
                key={customer._id}
                onClick={() => {
                  on_load(customer);
                  setAlert({
                    type: "clear",
                    msg: "",
                  });
                }}
                value={customer._id}
              >
                {customer.profileName}
              </DropDown.Options>
            );
          })}
      </DropDown>
      <ButtonPrimary
        title="New Customer"
        region="button"
        onClick={handleOnNew}
        style={`justify-self: end;`}
      />
    </Card>
  );
}
export default CustomerMenu;
