import { useEffect, useRef } from "react";
import styled from "styled-components";

const BarcodeReader = styled.form`
  position: absolute;
  top: -100px;
`;

// Parent should have state for what is scanner
function HiddenBarcodeReader(props) {
  const { onSubmit, onChange, scan } = props;
  const ref = useRef(null);

  const autoFocus = () => {
    ref.current.focus();
  };

  useEffect(() => {
    ref.current.focus();
  }, []);

  return (
    <BarcodeReader
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(e);
      }}
    >
      <input
        type="text"
        value={scan}
        ref={ref}
        onBlur={() => {
          autoFocus();
        }}
        onChange={onChange}
      />
    </BarcodeReader>
  );
}
export default HiddenBarcodeReader;
