import React from "react";
import Menu from "../features/inventory/Menu.jsx";
import Body from "../features/inventory/Body.jsx";
import ModalBase from "../components/ModalBase.jsx";
import NewMaterial from "../features/inventory/NewMaterial.jsx";
import Spinner from "../icons/Spinner.jsx";
import usePage from "../usePage/usePage.js";
import { useEffect } from "react";
import useServer from "../hooks/useServer.js";
import Cotainer from "../components/Container.jsx";
import AddInventory from "../features/inventory/AddInventory.jsx";

const initial = {
  open: false,
  mode: "new",
  _id: "",
  name: "",
  nickName: "",
  description: "",
  amount: 0,
  type: {
    options: ["Linear", "Weight", "Quantity", "Area"],
    selected: "Linear",
  },
  units: {
    options: ["in", "ft", "yd", "mi", "mm", "cm", "m", "km"],
    selected: "in",
  },
  modal: {
    open: false,
    scan: "",
    inventory: {},
    msg: "",
    total: 0,
    count: 0,
  },
  cost: 0,
  supplier: "",
  website: "",
  search: "",
  selectedType: "items",
  changes: [],
  customers: [],
  materials: [],
};

function Inventory() {
  const { state, set, reset, load, reload, loaded } = usePage(initial);
  const { get } = useServer();

  //LOAD CUSTOMER PRODUCTS
  useEffect(() => {
    async function effect() {
      await load("customers", () => get("products/from/customers"), {
        type: "init",
        then: (data) => {
          data.forEach((customer) => {
            customer.products.forEach((product) => {
              product.show = false;
              product.skus.forEach((sku) => {
                sku.show = false;
                sku.amount = sku.quantity;
              });
            });
          });
          return data;
        },
      });
    }
    effect();
  }, [load, get]);

  //LOAD MATERIALS
  useEffect(() => {
    async function effect() {
      await load("materials", () => get("materials"), { type: "init" });
    }
    effect();
  }, [load, get]);

  return (
    <Cotainer style={{ flexDirection: "column" }}>
      <ModalBase
        title={state?.mode === "edit" ? "Edit Material" : "New Material"}
        close={() => {
          set("open", false);
          reset("name");
          reset("nickName");
          reset("description");
          reset("amount");
          reset("type.selected");
          reset("units.selected");
          reset("cost");
          reset("supplier");
          reset("website");
          reset("mode");
        }}
        open={state.open}
      >
        <NewMaterial />
      </ModalBase>
      <ModalBase
        open={state?.modal?.open}
        title="Add Inventory"
        close={() => {
          reset("modal");
          if (state?.modal?.total > 0) reload("customers");
        }}
      >
        <AddInventory />
      </ModalBase>
      <Menu />
      {loaded("customers") && loaded("materials") ? <Body /> : <Spinner />}
    </Cotainer>
  );
}
export default Inventory;
