import styled from "styled-components";
import { useEffect, useState } from "react";
import ErrorBox from "./ErrorBox";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Required = styled.p`
  display: ${(props) => (props.$lock ? "none" : "block")};
  color: ${(props) => (props.$error ? "var(--error)" : "var(--primary)")};
  position: absolute;
  left: -10px;
  top: 0;
`;

const Input = styled.input`
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 11pt;
  padding: 10px;
  width: 100%;
  color: var(--text);
  border: 1px solid #ccc;
  outline: ${(props) => props.$outline};
`;

export default function TextInput(props) {
  const {
    value,
    onChange = () => {},
    placeholder,
    required,
    lock,
    error = false,
    region,
    style,
  } = props;

  const [hasFocus, setHasFocus] = useState(false);

  const outline = () => {
    if (error && hasFocus) {
      return "2px solid var(--error)";
    }
    if (hasFocus) {
      return "2px solid black";
    }
  };

  const lockStyle = {
    backgroundColor: "var(--background)",
    color: "var(--text)",
    border: "1px solid #fff",
    padding: "0.125rem",
  };

  const lockStatus = () => {
    if (lock === undefined) {
      return "";
    } else if (lock === false) {
      return "input-unlock";
    } else if (lock === true) {
      return "input-lock";
    }
  };

  const gridStyle = {
    gridArea: region,
    ...style,
  };

  useEffect(() => {}, [error]);

  return (
    <Container className={`${lockStatus()}`} style={gridStyle}>
      {required && (
        <Required $error={error} $lock={lock}>
          *
        </Required>
      )}
      <ErrorBox error={error} lock={lock}>
        <Input
          $outline={outline()}
          type="password"
          value={value}
          disabled={lock}
          onChange={(e) => {
            onChange(e);
          }}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          placeholder={lock === true ? "" : placeholder}
          // style={lock === true ? lockStyle : {}}
        />
      </ErrorBox>
    </Container>
  );
}
