import NumberInput from "../../../components/NumberInput";
import CloseIcon from "../../../icons/Close";
import DragDropIcon from "../../../icons/DragDrop";
import { useEffect } from "react";
import usePage from "../../../usePage/usePage";
import DragList from "../../../components/DragList";
import Card from "../../../components/Card";
import ButtonIcon from "../../../components/ButtonIcon";
import Text from "../../../components/Text";
import Content from "../../../components/Content";
import ErrorBox from "../../../components/ErrorBox";
import WarningBox from "../../../components/WarningBox";
function CueSidebarItem(props) {
  const { state, set, toggle, errorInit, errorCleanup } = usePage();
  const { item, index } = props;

  //ERROR
  useEffect(() => {
    errorInit({
      [`cueSidebar.orderItems.${index}.batchQuantity`]: {
        minValue: [1, "Quantity must be greater than 0"],
      },
    });
    return () => {
      errorCleanup({
        [`cueSidebar.orderItems.${index}.batchQuantity`]: {
          minValue: [1, "Quantity must be greater than 0"],
        },
      });
    };
  }, []);

  //FUNCTIONS
  const on_close = (target) => {
    const updateOrders = [...state.orders].map((order) => {
      if (order._id === target.orderId) {
        return {
          ...order,
          orderItems: order.orderItems.map((item) => {
            if (item._id === target._id) {
              return {
                ...item,
                pending: 0,
                batch: item.batch.filter((each) => each._id !== "temp"),
              };
            }
            return item;
          }),
        };
      }
      return order;
    });

    let payload = [];
    state.cueSidebar.orderItems.forEach((item) => {
      if (item._id !== target._id) {
        payload.push(item);
      }
    });

    set("orders", updateOrders);
    set("cueSidebar.orderItems", payload);
  };

  const on_change_qty = (e, target, path) => {
    const updateOrders = [...state.orders].map((order) => {
      if (order._id === target.orderId) {
        return {
          ...order,
          orderItems: order.orderItems.map((item) => {
            if (item._id === target._id) {
              return {
                ...item,
                batch: item.batch.map((b) => {
                  if (b._id === "temp") {
                    let num =
                      e.target.value === "" ? 0 : parseInt(e.target.value);
                    return { ...b, quantity: num };
                  }
                  return b;
                }),
              };
            }
            return item;
          }),
        };
      }
      return order;
    });

    set(path, e.target.value);
    set("orders", updateOrders);
  };

  const on_enter = (e, index) => {
    if (e.key === "Enter") {
      toggle(`cueSidebar.orderItems.${index}.edit`);
    }
  };

  //COMPONENT
  return (
    <ErrorBox
      error={state.error[`cueSidebar.orderItems.${index}.batchQuantity`]}
      style={`margin: var(--mar);`}
    >
      <WarningBox isError={item.productId === "" || item.skuId === ""}>
        <Card
          regions={`'close drag' 'content content' 'qty qty'`}
          style={`border: solid 1px var(--text); align-items: center; margin: 0;`}
        >
          <ButtonIcon
            region="close"
            onClick={() => {
              on_close(item);
            }}
            style={`justify-self: start;`}
          >
            <CloseIcon />
          </ButtonIcon>
          <Content region="drag" style={`justify-content: flex-end;`}>
            <DragList.Handle>
              <DragDropIcon />
            </DragList.Handle>
          </Content>
          <Content region="content" style={`flex-direction: column;`}>
            <Content
              style={`gap: var(--margin); align-items: center; & > * {margin: 0;}`}
            >
              <Text element="p" title="Name:" />
              <Text element="h5" title={item.name} />
            </Content>
            <Content
              style={`gap: var(--margin); align-items: center; & > * {margin: 0;}`}
            >
              <Text element="p" title="Order:" />
              <Text element="h5" title={item.orderNumber} />
            </Content>
            <Content
              style={`gap: var(--margin); align-items: center; & > * {margin: 0;}`}
            >
              <Text element="p" title="Sku:" />
              <Text element="h5" title={item.sku} />
            </Content>
            <Content
              style={`gap: var(--margin); align-items: center; & > * {margin: 0;}`}
            >
              <Text element="p" title="Product:" />
              <Text element="h5" title={item.product} />
            </Content>
          </Content>
          {item.edit === true ? (
            <NumberInput
              value={item.batchQuantity}
              type="int"
              onChange={(e) => {
                on_change_qty(
                  e,
                  item,
                  `cueSidebar.orderItems.${index}.batchQuantity`
                );
              }}
              onKeyDown={(e) => {
                on_enter(e, index);
              }}
              onBlur={(e) => {
                toggle(`cueSidebar.orderItems.${index}.edit`);
              }}
            />
          ) : (
            <Text
              region="qty"
              title={`x${item.batchQuantity}`}
              element="b"
              onDoubleClick={(e) => {
                console.log("dblclick");
                toggle(`cueSidebar.orderItems.${index}.edit`);
              }}
            />
          )}
        </Card>
      </WarningBox>
    </ErrorBox>
  );
}

export default CueSidebarItem;
