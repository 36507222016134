import Card from "../../components/Card";
import Text from "../../components/Text";
import usePage from "../../usePage/usePage";
import Content from "../../components/Content";
import TextInput from "../../components/TextInput";
import ButtonPrimary from "../../components/ButtonPrimary";
import useServer from "../../hooks/useServer";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";

const timeRegex = new RegExp(
  "^(?:(?:[01]\\d|2[0-3]):[0-5]\\d|(?:0?[1-9]|1[0-2]):[0-5]\\d(?:\\s?[AaPp][Mm]))$"
);

const errors = {
  "modal.end": {
    minLength: [1, "Clock-In is required"],
    permittedChars: [
      timeRegex,
      "Time must be in 24 hour time or 12 hour with AM or PM",
    ],
  },
  "modal.end": {
    minLength: [1, "Clock-Out is required"],
    permittedChars: [
      timeRegex,
      "Time must be in 24 hour time or 12 hour with AM or PM",
    ],
  },
};

export default function SetEndTime() {
  const { state, set, errorCheck, reset, reload } = usePage({}, errors);
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();
  const { modal } = state;

  //FUNCTIONS
  const is12Hour = (timeStr) => {
    const is12HourFormat = /(am|pm)/i.test(timeStr);

    const time24 = is12HourFormat ? convertTo24Hour(timeStr) : timeStr;
    const dateTimeString = `${modal.date}T${time24}`;
    return new Date(dateTimeString);
  };

  const convertTo24Hour = (timeStr) => {
    const time = timeStr.substring(0, timeStr.length - 2).trim();
    const modifier = timeStr.substring(timeStr.length - 2);
    let [hours, minutes] = time.split(":");

    if (modifier === "PM" || modifier === "pm") {
      hours = parseInt(hours, 10) + 12;
    }
    if (parseInt(hours, 10) < 10) {
      hours = `0${hours}`;
    }
    if (hours === "12") {
      hours = "00";
    }
    return `${hours}:${minutes}`;
  };

  const editEndTime = async () => {
    if (errorCheck()) return;

    let dateCheck = is12Hour(modal.end);
    console.log(dateCheck);
    const dateISO = dateCheck.toISOString();

    const data = await post("process/log/end", {
      _id: modal.processLogId,
      stop: dateISO,
    });
    if (data) {
      setAlert({ msg: data.msg, type: data.type });
      reset("modal");
      reload("processLogs");
    }
  };

  //COMPONENT
  return (
    <Card regions={`'title' 'end'`}>
      <Text region="title" title={modal.msg} />
      <Content region="end" style={`gap: var(--mar); align-items: center;`}>
        <Text title="End Time" element="h4" />
        <TextInput
          value={modal.end}
          error={state.error["modal.end"]}
          onChange={(e) => {
            set("modal.end", e.target.value);
          }}
        />
        <ButtonPrimary title="Set" onClick={editEndTime} />
      </Content>
    </Card>
  );
}
