import Spinner from "../../../../icons/Spinner";
import usePage from "../../../../usePage/usePage";
import Table, { Row, Cell } from "../../../../components/Table";
import ButtonPrimary from "../../../../components/ButtonPrimary";
import Content from "../../../../components/Content";

function AddressTable() {
  const { state, set, reset, loaded } = usePage();
  const { address_customerId, address_profileName, address_book } = state;

  const format = [
    "address1",
    "address2",
    "address3",
    "city",
    "state",
    "postalCode",
    "country",
  ];

  const render_shipping_address = (shippingAddress) => {
    let address = "";
    for (let [key, value] of Object.entries(shippingAddress)) {
      if (format.includes(key)) {
        if (key === "state") {
          address += `${value}, `;
        } else {
          address += `${value} `;
        }
      }
    }
    return address;
  };

  const new_address = () => {
    set("mode", "edit");
    set("modal_open", true);
    set("modal_profileName", address_profileName);
    set("modal_customerId", address_customerId);
    reset("modal_address_id");
    reset("modal_company");
    reset("modal_firstName");
    reset("modal_lastName");
    reset("modal_website");
    reset("modal_email");
    reset("modal_phone");
    reset("modal_ship_address1");
    reset("modal_ship_address2");
    reset("modal_ship_address3");
    reset("modal_ship_city");
    reset("modal_ship_state");
    reset("modal_ship_country");
    reset("modal_ship_postalCode");
    reset("modal_bill_address1");
    reset("modal_bill_address2");
    reset("modal_bill_address3");
    reset("modal_bill_city");
    reset("modal_bill_state");
    reset("modal_bill_country");
    reset("modal_bill_postalCode");
  };

  const on_load = (address) => {
    set("address_firstName", address.firstName);
    set("address_lastName", address.lastName);
    set("address_company", address.company);
    set("address_website", address.website);
    set("address_email", address.email);
    set("address_phone", address.phone);
    set("address_ship_address1", address.shippingAddress.address1);
    set("address_ship_address2", address.shippingAddress.address2);
    set("address_ship_address3", address.shippingAddress.address3);
    set("address_ship_city", address.shippingAddress.city);
    set("address_ship_state", address.shippingAddress.state);
    set("address_ship_country", address.shippingAddress.country);
    set("address_ship_postalCode", address.shippingAddress.postalCode);
  };

  return (
    <Content region="table" style="flex-direction: column;">
      {loaded("customers") ? (
        <>
          <ButtonPrimary
            onClick={new_address}
            title="New Address"
            style="align-items: end;"
          />
          <Table header={`'company contact shipping'`}>
            <Row style="background-color: var(--tertiary);">
              <Cell region="company" title="Company" />
              <Cell region="contact" title="Contact" />
              <Cell region="shipping" title="Shipping" last={true} />
            </Row>
            {address_book?.map((address, index) => {
              return (
                <Row
                  key={address._id}
                  onClick={() => {
                    on_load(address);
                  }}
                  last={address_book.length === index + 1}
                  hover={true}
                >
                  <Cell region="company" title={address.company} />
                  <Cell
                    region="contact"
                    title={`${address.firstName} ${address.lastName}`}
                  />
                  <Cell
                    region="shipping"
                    last={true}
                    title={render_shipping_address(address.shippingAddress)}
                  />
                </Row>
              );
            })}
          </Table>
        </>
      ) : (
        <Spinner />
      )}
    </Content>
  );
}
export default AddressTable;
