import { forwardRef } from "react";
import styled from "styled-components";

const Container = styled.div`
  grid-area: ${(props) => props.$region};
  display: flex;
  width: 100%;
  height: 100%;
  ${(props) => props.$more}
`;

const Content = forwardRef((props, ref) => {
  const { children, region, style, className, onClick = () => {} } = props;
  return (
    <Container
      ref={ref}
      $more={style}
      $region={region}
      onClick={onClick}
      className={className}
    >
      {children}
    </Container>
  );
});

export default Content;
