import AddressBook from "./customerBody/address/AddressBook";
import TabSelect from "./customerBody/TabSelect";
import Process from "./customerBody/process/Process";
import Product from "./customerBody/product/Product";
import usePage from "../../usePage/usePage";
function CustomerBody() {
  const { state } = usePage();
  const { tab } = state;
  return (
    <div
      className="column shadow smooth"
      style={{ minHeight: "calc(100vh - 136px" }}
    >
      <TabSelect />
      {tab === "address" ? <AddressBook /> : <></>}
      {tab === "process" ? <Process /> : <></>}
      {tab === "products" ? <Product /> : <></>}
    </div>
  );
}
export default CustomerBody;
