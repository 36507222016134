import { useState } from "react";
import Spinner from "../icons/Spinner";
import BatchList from "../features/batches/BatchList";
import BatchDetailedView from "../features/batches/BatchDetailedView";
import BatchBarcodeModal from "../features/batches/BatchBarcodeModal";
import usePage from "../usePage/usePage";
import Container from "../components/Container";
import { useEffect } from "react";
import useServer from "../hooks/useServer";
import Card from "../components/Card";
import Alert from "../components/Alert";

const initial = {
  modal: {
    open: false,
    master: {},
    barcodes: [],
  },
  alert_accept: () => {},
  alert: {
    open: false,
    msg: "",
    title: "",
  },
  barcodeModal: {
    open: false,
    master: {},
    barcodes: [],
  },
  batchList: {
    alert: "",
    search: "",
    list: [],
  },
  detailedView: {
    update: false,
    redirect: false,
    batchName: "",
    searched: false,
    group: "",
    station: "",
    orders: [],
  },
  // async state
  clients: [],
  batches: [],
  stations: [],
};

function Batches() {
  const { set, reset, load, loaded, state } = usePage(initial);
  const { alert, alert_accept } = state;
  const { get } = useServer();

  //BATCHES
  useEffect(() => {
    async function effect() {
      load("batches", () => get("/batches"), { type: "init" });
    }
    effect();
  }, [load]);

  return (
    <Container>
      <BatchBarcodeModal />
      <Alert
        title={alert?.title}
        open={alert?.open}
        close={() => {
          set("alert", { open: false });
        }}
        msg={alert?.msg}
        accept={alert_accept}
        decline={() => {
          set("alert", { open: false });
        }}
      />
      {loaded("batches") ? (
        <Card
          regions={`'list list list details details details details details'`}
          style={`margin: 0; padding: 0; @media print { display: none; }`}
        >
          <BatchList />
          <BatchDetailedView />
        </Card>
      ) : (
        <Spinner />
      )}
    </Container>
  );
}
export default Batches;
