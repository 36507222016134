import NewEmployee from "../features/employee/NewEmployee";
import Menu from "../features/employee/Menu";
import ModalBase from "../components/ModalBase";
import EmployeeTable from "../features/employee/EmployeeTable";
import AddJob from "../features/employee/AddJob";
import Spinner from "../icons/Spinner";
import usePage from "../usePage/usePage";
import useServer from "../hooks/useServer";
import { useEffect } from "react";
import Container from "../components/Container";
import Card from "../components/Card";
import EditHours from "../features/employee/EditHours";

const initial = {
  m1_id: "",
  m1_open: false,
  m1_alert: { msg: "", type: "" },
  m1_firstName: "",
  m1_lastName: "",
  m1_email: "",
  m1_password: "",
  m1_confirmPassword: "",
  m1_phone: "",
  m1_pay: "",
  m1_role: "",

  m2_id: "",
  m2_alert: { msg: "", type: "" },
  m2_open: false,
  m2_firstName: "",
  m2_lastName: "",
  m2_batchId: "",
  m2_batchName: "",
  m2_customerId: "",
  m2_batch: {},
  m2_items: [],
  m2_products: [],

  m3_open: false,
  m3_edit: false,
  m3_title: "",
  m3_alert: { msg: "", type: "" },
  m3_employee: "",
  m3_date: "",
  m3_logs: {},
  m3_clockin_id: "",
  m3_clockin: "",
  m3_clockout_id: "",
  m3_clockout: "",

  menu_alert: { msg: "", type: "" },
  body: {},
  calendar: {},
  employee_loading: true,
  employees: [],
  batches: [],
  selectedMonth: new Date(),
  selectedEmployee: "",
};

const resetM1 = (reset) => {
  reset("m1_id");
  reset("m1_firstName");
  reset("m1_lastName");
  reset("m1_email");
  reset("m1_password");
  reset("m1_confirmPassword");
  reset("m1_phone");
  reset("m1_pay");
  reset("m1_role");
};

const resetM2 = (reset) => {
  reset("m2_id");
  reset("m2_firstName");
  reset("m2_lastName");
  reset("m2_batchId");
  reset("m2_batchName");
  reset("m2_customerId");
  reset("m2_batch");
  reset("m2_items");
  reset("m2_products");
};

const resetM3 = (reset) => {
  reset("m3_open");
  reset("m3_edit");
  reset("m3_title");
  reset("m3_alert");
  reset("m3_employee");
  reset("m3_date");
  reset("m3_logs");
  reset("m3_clockin_id");
  reset("m3_clockin");
  reset("m3_clockout_id");
  reset("m3_clockout");
};

export default function Employee() {
  const { state, set, load, loaded, init, reset } = usePage(initial);
  const { get } = useServer();

  //LOAD EMPLOYEES
  useEffect(() => {
    async function effect() {
      await load("employees", () => get("/employees/jobs"), {
        type: "init",
        then: (res) => {
          set("employee_loading", false);
          res.forEach((employee) => {
            employee.info = false;
          });
          return res;
        },
      });
    }
    effect();
  }, [load, get]);

  //LOAD BATCHES
  useEffect(() => {
    async function effect() {
      await load("batches", () => get("/batches"), { type: "init" });
    }
    effect();
  }, [load, get]);

  return (
    <Container style={{ flexDirection: "column" }}>
      <ModalBase
        open={state.m1_open}
        alert={state.m1_alert}
        title={"New Employee"}
        close={() => {
          set("m1_open", false);
          resetM1(reset);
        }}
      >
        <NewEmployee />
      </ModalBase>
      <ModalBase
        title={"Add Jobs"}
        alert={state.m2_alert}
        open={state.m2_open}
        close={() => {
          set("m2_open", false);
          resetM2(reset);
        }}
      >
        <AddJob />
      </ModalBase>
      <ModalBase
        title={state.m3_title ? state.m3_title : "Edit Hours"}
        alert={state.m3_alert}
        open={state.m3_open}
        close={() => {
          set("m3_open", false);
          resetM3(reset);
        }}
      >
        <EditHours />
      </ModalBase>
      <Menu />
      <Card
        regions={`'body'`}
        style={{
          width: "100%",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.08)",
        }}
      >
        {!loaded("employees") ? <Spinner /> : <EmployeeTable />}
      </Card>
    </Container>
  );
}
