import { useEffect } from "react";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
import usePage from "../../usePage/usePage";
import Card from "../../components/Card";
import Text from "../../components/Text";
import Button from "../../components/Button";
import ButtonPrimary from "../../components/ButtonPrimary";
import Content from "../../components/Content";
import DropDown, { Options } from "../../components/DropDown";
import SearchBar from "../../components/SearchBar";

function OrderMenu() {
  const { state, set, reset, toggle } = usePage();
  const { search, filters, mode, customers, menu } = state;
  const { setAlert } = useGlobalAlert();
  console.log("OrderMenu.jsx");

  useEffect(() => {
    if (search === "") {
      filter_by_search();
    }
  }, [search]);

  const orderFilter = (value, type) => {
    let payload = [];
    if (value === "all") {
      payload = [...filters].filter((filter) => filter.type !== type);
      set("filters", payload);
      return;
    }
    if (!filters.find((filter) => filter.type === type)) {
      payload = [...filters, { type: type, id: value }];
    } else {
      payload = [...filters].map((filter) => {
        if (filter.type === type) {
          return {
            ...filter,
            id: value,
          };
        }
        return filter;
      });
    }
    set("filters", payload);
    let button = value.charAt(0).toUpperCase() + value.slice(1);
    set(`menu.${type}Filter`, button);
  };

  const filter_by_search = () => {
    if (!filters) return;
    const id = search;
    let payload = [];
    if (id === "") {
      payload = [...filters].filter((filter) => filter.type !== "search");
      set("filters", payload);
      return;
    }
    if (!filters.find((filter) => filter.type === "search")) {
      payload = [...filters, { type: "search", id: id }];
    } else {
      payload = [...filters].map((filter) => {
        if (filter.type === "search") {
          return {
            ...filter,
            id: id,
          };
        }
        return filter;
      });
    }
    set("filters", payload);
  };

  const on_toggle_batch = () => {
    setAlert({ type: "clear", msg: "" });
    if (mode === "queue") set("mode", "create");
    if (mode === "create") set("mode", "queue");
    if (mode === "review") set("mode", "create");

    toggle("cueSidebar.open");
    set("cueTable.open", false);
  };

  const handleOnNew = () => {
    set("modal.open", true);
    set("modal.orderItems_loading", false);
  };

  //COMPOENT
  return (
    <Card
      regions={`
        'title title title title title title title title'
        'toggle date date store store search count new'
        `}
      style={`box-shadow: var(--shadow);`}
    >
      <Text region="title" title="Orders" element="h1" />
      <Button region="toggle" title="Toggle Batch" onClick={on_toggle_batch} />
      <Content
        region="date"
        style={`justify-content: center; align-items:center; gap: 0.5rem;`}
      >
        <Text title="Filter By Date:" element="b" />
        <DropDown title={menu?.dateFilter}>
          <Options
            title="Oldest"
            onClick={() => {
              orderFilter("oldest", "date");
            }}
          />
          <Options
            title="Newest"
            onClick={() => {
              orderFilter("newest", "date");
            }}
          />
        </DropDown>
      </Content>
      <Content
        region="store"
        style={`justify-content: center; align-items:center; gap: 0.5rem;`}
      >
        <Text title="Filter By Customer:" element="b" />
        <DropDown title={menu?.customerFilterDisplay ?? "All"}>
          <Options
            title="All"
            onClick={() => {
              orderFilter("all", "customer");
              set("menu.customerFilterDisplay", "All");
            }}
          />
          {customers?.map((customer) => {
            return (
              <Options
                key={customer._id}
                onClick={() => {
                  orderFilter(customer._id, "customer");
                  set("menu.customerFilterDisplay", customer.profileName);
                }}
              >
                {customer.profileName}
              </Options>
            );
          })}
        </DropDown>
      </Content>
      <SearchBar
        region="search"
        value={search}
        change={(e) => {
          set("search", e.target.value);
          filter_by_search();
        }}
      />
      <Text
        title={`Showing ${menu?.count ?? 0} orders`}
        element="h4"
        style={`justify-self: center; align-self:center;`}
      />
      {mode === "create" && (
        <ButtonPrimary
          region="new"
          title="New Order"
          onClick={handleOnNew}
          style={`justify-self: end;`}
        />
      )}
    </Card>
  );
}
export default OrderMenu;
