import useServer from "../../hooks/useServer";
import trunc_str from "../../utils/trunc_str";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
import usePage from "../../usePage/usePage";
import Table, { Cell, Row, SubTable } from "../../components/Table";
import TextButton from "../../components/TextButton";
import React from "react";
import EmployeeCalendar from "./EmployeeCalendary";
import Card from "../../components/Card";

function EmployeeTable() {
  // const { page } = props;
  const { state, set, reload, toggle, load } = usePage();
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();
  const employees = state.employees;

  //FUNCTIONS
  const on_edit = (employee) => {
    set("m1_open", true);
    set("m1_id", employee._id);
    set("m1_firstName", employee.firstName);
    set("m1_lastName", employee.lastName);
    set("m1_email", employee.email);
    set("m1_phone", employee.phone);
    set("m1_pay", employee.pay);
    set("m1_role", employee.role);
    set("m1_jobs", employee.jobs);
  };

  const add_job = (employee) => {
    setAlert({ msg: "", type: "clear" });
    set("m2_open", true);
    set("m2_id", employee._id);
    set("m2_firstName", employee.firstName);
    set("m2_lastName", employee.lastName);
  };

  const remove_job = async (jobId, empId) => {
    const query = post("/employees/remove/job", {
      jobId: jobId,
      empId: empId,
    });
    const data = await load("employees", () => query);
    if (data) {
      setAlert({ msg: data.msg, type: data.type });
      reload("employees");
    }
  };

  //COMPONENT
  return (
    <Table header={`'name button1 button2'`}>
      {employees.map((employee, index) => {
        return (
          <React.Fragment key={employee._id}>
            <Row key={employee._id} last={index + 1 === employees.length}>
              <Cell
                region="name"
                title={`${employee.firstName} ${employee.lastName}`}
              />
              <Cell region="button1">
                {/* <TextButton
                  title="Add Job"
                  onClick={() => {
                    add_job(employee);
                  }}
                /> */}
              </Cell>
              <Cell region="button2">
                <TextButton
                  title={employee.info === true ? "Hide" : "Show Details"}
                  onClick={() =>
                    employees.forEach((employee, i) => {
                      if (index === i) {
                        toggle(`employees.${index}.info`);

                        set("selectedEmployee", employee);
                      } else {
                        set(`employees.${i}.info`, false);
                      }
                    })
                  }
                />
              </Cell>
            </Row>
            {employee.info && (
              <Card className="expand">
                <EmployeeCalendar employee={employee} />
                <SubTable
                  region={`'name button1 button2'`}
                  header={`'edit role email phone pay'`}
                >
                  <Row>
                    <Cell region="edit">
                      <TextButton
                        title="Edit"
                        onClick={() => on_edit(employee)}
                      />
                    </Cell>
                    <Cell
                      region="role"
                      title={`Role:${
                        employee.role.charAt(0).toUpperCase() +
                        employee.role.slice(1)
                      }`}
                    />
                    <Cell region="email" title={`Email: ${employee.email}`} />
                    <Cell region="phone" title={`Phone: ${employee.phone}`} />
                    <Cell region="pay" title={`Pay: $${employee.pay}`} />
                  </Row>
                </SubTable>

                <SubTable
                  region={`'name button1 button2'`}
                  header={`'batch products sku step done need unassign'`}
                >
                  <Row style={{ backgroundColor: "var(--tertiary)" }}>
                    <Cell
                      region={`'name-start button2-end'`}
                      title="Assigned Jobs"
                      style={{
                        fontSize: "14pt",
                        fontWeight: "bold",
                        color: "var(--text)",
                      }}
                    />
                  </Row>
                  <Row style={{ backgroundColor: "var(--tertiary)" }}>
                    <Cell region="batch" title="Batch" />
                    <Cell region="products" title="Products" />
                    <Cell region="sku" title="Sku" />
                    <Cell region="step" title="Step" />
                    <Cell region="done" title="Done" />
                    <Cell region="need" title="Need" />
                    <Cell region="unassign" title="Unassign" />
                  </Row>
                  {employee.jobs?.map((job, index) => {
                    return (
                      <Row
                        key={job._id}
                        last={employee.jobs.length === index + 1}
                      >
                        <Cell region="batch" title={job.batch} />
                        <Cell region="products" title={job.product} />
                        <Cell region="sku" title={job.sku} />
                        <Cell region="step" title={trunc_str(job.step)} />
                        <Cell region="done" title={job.count} />
                        <Cell region="need" title={job.quantity} />
                        <Cell region="unassign">
                          <TextButton
                            title="Unassign"
                            onClick={() => {
                              remove_job(job._id, employee._id);
                            }}
                          />
                        </Cell>
                      </Row>
                    );
                  })}
                </SubTable>
              </Card>
            )}
          </React.Fragment>
        );
      })}
    </Table>
  );
}

export default EmployeeTable;
