function format_save_address(state) {
  let clone = { ...state };
  const {
    modal_profileName,
    modal_company,
    modal_firstName,
    modal_lastName,
    modal_website,
    modal_email,
    modal_phone,

    modal_ship_address1,
    modal_ship_address2,
    modal_ship_address3,
    modal_ship_city,
    modal_ship_state,
    modal_ship_country,
    modal_ship_postalCode,

    modal_bill_address1,
    modal_bill_address2,
    modal_bill_address3,
    modal_bill_city,
    modal_bill_state,
    modal_bill_country,
    modal_bill_postalCode,
  } = clone;

  return {
    company: modal_company,
    firstName: modal_firstName,
    lastName: modal_lastName,
    website: modal_website,
    email: modal_email,
    phone: modal_phone,
    shippingAddress: {
      address1: modal_ship_address1,
      address2: modal_ship_address2,
      address3: modal_ship_address3,
      city: modal_ship_city,
      state: modal_ship_state,
      country: modal_ship_country,
      postalCode: modal_ship_postalCode,
    },
    billingAddress: {
      address1: modal_bill_address1,
      address2: modal_bill_address2,
      address3: modal_bill_address3,
      city: modal_bill_city,
      state: modal_bill_state,
      country: modal_bill_country,
      postalCode: modal_bill_postalCode,
    },
  };
}
export default format_save_address;
