import DropDown from "../../../../components/DropDown";
import usePage from "../../../../usePage/usePage";
import Card from "../../../../components/Card";
import Text from "../../../../components/Text";
import ButtonPrimary from "../../../../components/ButtonPrimary";
function ProcessHeader() {
  const { state, set, reset } = usePage();
  const { process, sub_process, process_id, process_name } = state;
  return (
    <Card
      regions={`
    '. title title title .'
    '. button dropdown dropdown .'
    `}
      style={`width: 75vw;`}
    >
      <Text
        region="title"
        title="Process Information"
        element="h3"
        style={`background-color: var(--tertiary); border-radius: var(--border-radius); padding: 0.5rem;`}
      />
      <ButtonPrimary
        region="button"
        title="New"
        onClick={() => {
          set("mode", "new");
          // reset();
        }}
      />
      <DropDown
        region="dropdown"
        title={process_name === "" ? "Load Process" : process_name}
        value={process_id ?? ""}
      >
        {process.length > 0 &&
          [...process, ...sub_process].map((store) => {
            return (
              <DropDown.Options
                onClick={() => {
                  set("mode", "edit");
                  set("process_id", store._id);
                  set("process_name", store.name);
                  set("process_desc", store.desc);
                  set("process_type", store.type);
                  set("process_steps", store.steps);
                }}
                key={store._id}
                value={store._id}
                data-name={store.name}
              >
                {store.name}
              </DropDown.Options>
            );
          })}
      </DropDown>
    </Card>
  );
}
export default ProcessHeader;
