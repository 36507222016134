import usePage from "../../usePage/usePage";
import Card from "../../components/Card";
import ButtonPrimary from "../../components/ButtonPrimary";
function Menu() {
  const { set } = usePage();

  return (
    <Card
      regions={`'title button'`}
      style={{
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.08)",
      }}
    >
      <h1>Employees</h1>
      <ButtonPrimary
        style={{ gridArea: "button", justifySelf: "end" }}
        title="New Employee"
        onClick={() => {
          set("m1_open", true);
        }}
      />
    </Card>
  );
}
export default Menu;
