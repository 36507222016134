import useServer from "../../hooks/useServer";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
import usePage from "../../usePage/usePage";
import Card from "../../components/Card";
import NumberInput from "../../components/NumberInput";
import ButtonPrimary from "../../components/ButtonPrimary";
import Table, { Row, Cell } from "../../components/Table";
import React from "react";
import TextButton from "../../components/TextButton";

function Items() {
  const { state, reset, reload, set, remove, add, toggle } = usePage();
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();
  const { changes, customers, search } = state;

  //FUNCTIONS
  const save_changes = async () => {
    const payload = changes;
    const response = await post("/inventory/update", payload);
    if (response.type === "success") {
      setAlert({ type: "success", msg: "Changes Saved" });
      reload("customers");
    }
  };

  const add_remove_change = (sku, e, type) => {
    let c = changes.indexOf(changes.find((change) => change._id === sku._id));
    let clone = {
      ...sku,
      quantity: parseInt(e.target.value),
    };
    remove("changes", c);
    if (type === "add") {
      add("changes", clone);
    }
  };

  const change_numbers = (index, ind, i, e, sku) => {
    set(
      `customers.${index}.products.${ind}.skus.${i}.quantity`,
      e.target.value
    );
    if (parseInt(e.target.value) > sku.amount) {
      add_remove_change(sku, e, "add");
      set(
        `customers.${index}.products.${ind}.skus.${i}.color`,
        "var(--primary)"
      );
    } else if (parseInt(e.target.value) < sku.amount) {
      add_remove_change(sku, e, "add");
      set(
        `customers.${index}.products.${ind}.skus.${i}.color`,
        "var(--warning)"
      );
    } else {
      add_remove_change(sku, e, "remove");
      set(`customers.${index}.products.${ind}.skus.${i}.color`, "var(--text)");
    }
  };

  const filter_search = (customers) => {
    if (!search) return customers;
    const reg = new RegExp(search, "i");
    return customers.map((customer) => {
      return {
        ...customer,
        products: customer.products.filter((product) => {
          if (reg.test(product.name)) {
            return product;
          }
        }),
      };
    });
  };

  //COMPONENT
  return (
    <Card region="body" regions={`'button' 'table'`} style={{ width: "100%" }}>
      <ButtonPrimary
        region="button"
        title="Save Changes"
        onClick={(e) => {
          e.preventDefault();
          save_changes();
        }}
      />
      <Table
        region="table"
        header={`'customer customer product product skus skus qty'`}
      >
        <Row style={{ backgroundColor: "var(--tertiary)" }}>
          <Cell
            region="customer"
            title="Customer"
            style={{ fontWeight: "bold" }}
          />
          <Cell
            region="product"
            title="Product"
            style={{ fontWeight: "bold" }}
          />
          <Cell region="skus" title="Skus" style={{ fontWeight: "bold" }} />
          <Cell region="qty" />
        </Row>
        {filter_search(customers).map((customer, index) => {
          const color = index % 2 ? "var(--tertiary)" : "";
          return (
            <React.Fragment key={customer._id}>
              <Row style={{ backgroundColor: color }}>
                <Cell
                  region="customer"
                  title={customer.profileName}
                  style={{ fontWeight: "bold" }}
                />
                <Cell
                  region="product"
                  title="Product"
                  style={{ fontWeight: "bold" }}
                />
                <Cell
                  region="skus"
                  title="Skus"
                  style={{ fontWeight: "bold" }}
                />
                <Cell region="qty" />
              </Row>
              {customer.products?.map((product, ind) => {
                return (
                  <React.Fragment key={product._id}>
                    <Row
                      last={
                        customers.length === index + 1 && product.show === false
                      }
                    >
                      <Cell region="customer" />
                      <Cell
                        region="product"
                        title={`${product.name} ${
                          product.archived === true ? "'Archived'" : ""
                        }`}
                        style={
                          product.archived === true
                            ? { color: "var(--warning)" }
                            : {}
                        }
                      />
                      <Cell region="skus">
                        <TextButton
                          title={product.show === false ? "▼" : "▲"}
                          onClick={() => {
                            toggle(`customers.${index}.products.${ind}.show`);
                          }}
                          style={
                            product.show === false
                              ? { color: "var(--text)" }
                              : { color: "var(--primary)" }
                          }
                        />
                      </Cell>
                      <Cell
                        region="qty"
                        title={product.show ? "Quantity" : ""}
                        style={{ fontWeight: "bold" }}
                      />
                    </Row>
                    <>
                      {product.show &&
                        product.skus.map((sku, i) => {
                          return (
                            <Row
                              key={sku._id}
                              last={
                                customers.length === index + 1 &&
                                product.skus.length === i + 1
                              }
                            >
                              <Cell region="customer" />
                              <Cell region="product" />
                              <Cell region="skus" title={sku.sku} />
                              {sku.show === false ? (
                                <Cell
                                  region="qty"
                                  title={sku.quantity}
                                  onClick={() => {
                                    console.log("click");
                                    toggle(
                                      `customers.${index}.products.${ind}.skus.${i}.show`
                                    );
                                  }}
                                  style={{ color: sku.color, display: "block" }}
                                />
                              ) : (
                                <Cell region="qty">
                                  <NumberInput
                                    value={sku.quantity}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        toggle(
                                          `customers.${index}.products.${ind}.skus.${i}.show`
                                        );
                                      }
                                    }}
                                    onChange={(e) => {
                                      change_numbers(index, ind, i, e, sku);
                                    }}
                                  />
                                </Cell>
                              )}
                            </Row>
                          );
                        })}
                    </>
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          );
        })}
      </Table>
    </Card>
  );
}
export default Items;
