import Close from "../icons/Close";
function Alert(props) {
  const { title, close, open, msg, accept, decline, notifyOnly } = props;
  return open === true ? (
    <div className="modal-page" style={{ display: "block" }}>
      <div className="modal-container shadow modal-flyin">
        <div className="modal-header">
          <div className="bar split">
            <h1>{title}</h1>
            <button
              className="btn-icon"
              onClick={(e) => {
                e.preventDefault();
                close(e);
              }}
            >
              <Close />
            </button>
          </div>
        </div>
        <div
          className="card soft
        "
        >
          <p>{msg}</p>
          {!notifyOnly && (
            <div className="grid-bar">
              <button
                className="span-1 s-1 btn-default"
                onClick={() => {
                  decline();
                }}
              >
                Cancel
              </button>
              <button
                className="span-1 s-12 btn-primary"
                onClick={() => {
                  accept();
                }}
              >
                Accept
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default Alert;
