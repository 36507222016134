import Content from "../../components/Content";
import usePage from "../../usePage/usePage";
import Card from "../../components/Card";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
import Button from "../../components/Button";
import Table, { StickyRow, Row, Cell, SubTable } from "../../components/Table";
import TextButton from "../../components/TextButton";
import React, { useEffect } from "react";
import useServer from "../../hooks/useServer";

function BatchDetailedView() {
  const { state, set, toggle, reset, reload, func } = usePage();
  const { post } = useServer();
  const { modal, detailedView, batchList } = state;
  const { setAlert } = useGlobalAlert();

  // //BARCODE MODAL
  useEffect(() => {
    if (state.modal?.open) {
      window.print();
      window.onafterprint = () => {
        reset("modal");
      };
    }
  }, [modal, set]);

  const on_print_all = (orders) => {
    if (orders.length === 0) return;
    let barcodes = [];
    orders.forEach((order) => {
      order.orderItems.forEach((orderItem) => {
        orderItem.items.forEach((item) => {
          item.parts.forEach((part, index) => {
            let clone = { ...item };
            clone.batchName = detailedView.batchName;
            clone.orderNumber = order.orderNumber;
            clone.name = orderItem.name;
            clone.sku = orderItem.sku.sku;
            clone.product = orderItem.product.name;
            clone.partName = part.name;
            clone.partNumber = index + 1;
            clone.totalParts = item.parts.length;
            barcodes = [...barcodes, clone];
          });
        });
      });
    });

    set("modal", {
      open: true,
      barcodes: barcodes,
    });
  };

  const on_print_order = (order) => {
    let barcodes = [];
    order.orderItems.forEach((orderItem) => {
      orderItem.items.forEach((item) => {
        item.parts.forEach((part, index) => {
          let clone = { ...item };
          clone.batchName = detailedView.batchName;
          clone.orderNumber = order.orderNumber;
          clone.name = orderItem.name;
          clone.sku = orderItem.sku.sku;
          clone.product = orderItem.product.name;
          clone.partName = part.name;
          clone.partNumber = index + 1;
          clone.totalParts = item.parts.length;
          barcodes = [...barcodes, clone];
        });
      });
    });
    set("modal", {
      open: true,
      barcodes: barcodes,
    });
  };

  const on_print_one = (item, orderItem) => {
    let barcodes = [];
    let payload = {};

    item.parts.forEach((part, index) => {
      let clone = { ...item };
      clone.batchName = detailedView.batchName;
      clone.orderNumber = orderItem.orderNumber;
      clone.name = orderItem.name;
      clone.sku = orderItem.sku.sku;
      clone.product = orderItem.product.name;
      clone.partName = part.name;
      clone.partNumber = index + 1;
      clone.totalParts = item.parts.length;
      barcodes = [...barcodes, clone];
    });

    payload = {
      ...modal,
      open: true,
      barcodes: barcodes,
    };

    set("modal", payload);
  };

  const formatCustomer = (customer) => {
    if ((customer.company === "") | (customer.company === null))
      return customer.firstName + " " + customer.lastName;
    return customer.company;
  };

  const get_item_totals = (orderItems) => {
    let total = 0;
    orderItems.forEach((orderItem) => {
      total += orderItem.items.length;
    });
    return total;
  };

  const formatItemLocation = (item) => {
    if (item.checkpoints.length === 0)
      return { formatted: "Not Scanned", date: "" };
    let location = item.checkpoints?.slice(item.checkpoints.length - 1)[0].name;
    let date = item.checkpoints
      ?.slice(item.checkpoints.length - 1)[0]
      .date.split("T")[0];
    let formatted = location?.slice(0, 1)[0].toUpperCase() + location.slice(1);
    return { formatted, date };
  };

  //COMPONENT
  return (
    <Card
      region="details"
      regions={`'title' 'table'`}
      style={`box-shadow: var(--shadow);`}
    >
      <Content region="title">
        <Button
          title="Archive"
          onClick={() => {
            if (!detailedView.batchId) return;
            set("alert.open", true);
            set("alert.title", "Archive Batch");
            set(
              "alert.msg",
              `Are you sure you want to archive batch ${detailedView.batchName}?`
            );

            func("alert_accept", async () => {
              const data = await post("/batch/archive", {
                _id: detailedView.batchId,
              });
              if (data) {
                setAlert("Batch Archived");
                reload("batches");
                reset("detailedView");
                reset("alert");
              }
            });
          }}
        />
        <Button
          title="Print All Orders In Batch"
          onClick={() => {
            on_print_all(detailedView?.orders);
          }}
          disabled={detailedView?.orders?.length === 0}
          style={`align-self: flex-end; margin-left: auto;`}
        />
      </Content>
      <Table
        region="table"
        header={`'customer order order date qty total view print'`}
        style={`
          border: solid 1px var(--secondary); 
          margin: 0; 
          padding: 0;    
          height: calc(100vh - 182px); 
          overflow-y: scroll; 
          flex-direction: column;`}
      >
        <StickyRow style={`background-color: var(--tertiary);`}>
          <Cell region="customer" title="Customer" element="b" />
          <Cell region="order" title="Order" element="b" />
          <Cell region="date" title="Date" element="b" />
          <Cell region="qty" title="Product Types" element="b" />
          <Cell region="total" title="Total Qty" element="b" />
          <Cell region="view" />
          <Cell region="print" />
        </StickyRow>

        {detailedView?.orders?.map((order, index) => {
          return (
            <React.Fragment key={order._id}>
              <Row first={index === 0}>
                <Cell region="order" title={order.orderNumber} />
                <Cell
                  region="customer"
                  title={formatCustomer(order.customer)}
                />
                <Cell region="date" title={order.createdAt.split("T")[0]} />
                <Cell region="qty" title={order.orderItems.length} />
                <Cell
                  region="total"
                  title={get_item_totals(order.orderItems)}
                />
                <Cell region="view">
                  <TextButton
                    title={
                      detailedView.orders[index].show
                        ? "Hide Products"
                        : "View Products"
                    }
                    onClick={() => {
                      toggle(`detailedView.orders.${index}.show`);
                    }}
                  />
                </Cell>
                <Cell region="print">
                  <TextButton
                    title="Print"
                    onClick={() => {
                      on_print_order(order);
                    }}
                  />
                </Cell>
              </Row>
              <Row>
                {detailedView.orders[index].show && (
                  <SubTable
                    header={`'num name product sku qty items'`}
                    className="expand"
                  >
                    <StickyRow style={`background-color: var(--tertiary);`}>
                      <Cell
                        region="num"
                        title={order.orderNumber}
                        element="b"
                      />
                      <Cell region="name" title="Name" element="b" />
                      <Cell region="product" title="Product" element="b" />
                      <Cell region="sku" title="SKU" element="b" />
                      <Cell region="qty" title="Qty" element="b" />
                      <Cell region="items" title="Items" element="b" />
                    </StickyRow>
                    {order?.orderItems?.map((orderItem, i) => {
                      return (
                        <React.Fragment key={orderItem.orderItemId}>
                          <Row
                            first={i === 0}
                            last={order.orderItems.length - 1 === i}
                          >
                            <Cell />
                            <Cell region="name" title={orderItem.name} />
                            <Cell
                              region="product"
                              title={orderItem.product.name}
                            />
                            <Cell region="sku" title={orderItem.sku.sku} />
                            <Cell region="qty" title={orderItem.items.length} />
                            <Cell region="items">
                              <TextButton
                                title={
                                  detailedView.orders[index].orderItems[i].show
                                    ? "Hide Items"
                                    : "View Items"
                                }
                                onClick={() => {
                                  toggle(
                                    `detailedView.orders.${index}.orderItems.${i}.show`
                                  );
                                }}
                              />
                            </Cell>
                          </Row>
                          {detailedView.orders[index].orderItems[i].show && (
                            <Row>
                              <SubTable
                                header={`'. id loc print'`}
                                className="expand"
                              >
                                <Row first={true}>
                                  <Cell />
                                  <Cell
                                    region="id"
                                    title="Item ID"
                                    element="b"
                                  />
                                  <Cell
                                    region="loc"
                                    title="Location"
                                    element="b"
                                  />
                                  <Cell region="print" />
                                </Row>
                                {orderItem.items?.map((item, j) => {
                                  return (
                                    <Row
                                      last={
                                        j === orderItem.items.length - 1 &&
                                        order.orderItems.length - 1 === i
                                      }
                                      key={item._id}
                                    >
                                      <Cell />
                                      <Cell region="id" title={item._id} />
                                      <Cell
                                        region="loc"
                                        title={
                                          formatItemLocation(item).formatted
                                        }
                                      />
                                      <Cell region="print">
                                        <TextButton
                                          title="Print"
                                          onClick={() => {
                                            on_print_one(item, orderItem);
                                          }}
                                        />
                                      </Cell>
                                    </Row>
                                  );
                                })}
                              </SubTable>
                            </Row>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </SubTable>
                )}
              </Row>
            </React.Fragment>
          );
        })}
      </Table>
    </Card>
  );
}
export default BatchDetailedView;
