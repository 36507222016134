import useServer from "../../hooks/useServer";
import DropDown from "../../components/DropDown";
import AddTask from "./AddTask";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
import usePage from "../../usePage/usePage";
import Container from "../../components/Container";
import Card from "../../components/Card";
import Content from "../../components/Content";
import Text from "../../components/Text";
import Button from "../../components/Button";
import ButtonPrimary from "../../components/ButtonPrimary";

export default function AddJob() {
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();
  const { state, set, reset, checkErrors, reload, add, load } = usePage();
  const {
    m2_id,
    m2_alert,
    m2_open,
    m2_firstName,
    m2_lastName,
    m2_batchId,
    m2_batchName,
    m2_customerId,
    m2_batch,
    m2_items,
    m2_products,
    batches,
  } = state;

  const submit_jobs = async () => {
    if (checkErrors()) return;
    const payload = {
      batch: m2_batchName,
      batchId: m2_batchId,
      empId: m2_id,
      customerId: m2_customerId,
      jobs: m2_products,
      date: new Date(),
    };

    const data = await post("/employees/assign/job", payload);
    if (data) {
      setAlert({ msg: data.msg, type: data.type });
      reset();
      reload("employees");
    }
  };

  const submit_all_jobs = async () => {
    if (m2_items.length === 0) {
      set("m2_alert", { type: "error", msg: "Select batch" });
      return;
    }
    const all = [];
    m2_items.forEach((item) => {
      item.steps.forEach((step) => {
        if (step.constant === 0) {
          all.push({
            product: item.name,
            productId: item.productId,
            sku: item.sku,
            skuId: item.skuId,
            quantity: item.quantity,
            outOf: item.quantity,
            stepId: step._id,
            step: step.name,
            desc: step.desc,
          });
        }
      });
    });

    const payload = {
      batch: m2_batchName,
      batchId: m2_batchId,
      empId: m2_id,
      customerId: m2_customerId,
      jobs: all,
      date: new Date(),
    };
    const query = post("/employees/assign/job", payload);
    const data = await load("employees", () => query);
    if (data) {
      setAlert({ msg: data.msg, type: data.type });
      reset();
      reload("employees");
    }
  };

  const group_byItems = (batch) => {
    const items = {};
    batch.map((order) => {
      order.orderItems.forEach((item) => {
        let id = item.skuId;
        if (items[id] === undefined) {
          items[id] = {
            productId: item.itemProductId,
            product: item.productName,
            name: item.name,
            sku: item.sku,
            skuId: item.skuId,
            quantity: item.quantity,
            outOf: item.quantity,
            steps: item.steps,
            step: "",
            desc: "",
          };
        } else {
          items[id].quantity += item.quantity;
        }
      });
    });

    const itemsArray = Object.keys(items).map((key) => ({
      _id: key,
      ...items[key],
    }));

    return itemsArray;
  };

  const choose_assignment_type = () => {
    let text = `Assign all production from batch - ${m2_batchName} - to ${m2_firstName} ${m2_lastName}?`;
    let func = submit_all_jobs;
    if (m2_products.length > 0) {
      text = `Assign the below ${m2_products.length} jobs to ${m2_firstName} ${m2_lastName}?`;
      func = submit_jobs;
    }
    return { text, func };
  };

  //COMPONENT
  return (
    <Card
      regions={`
        'title title title'
        'region1 region2 region2'
        'job job job'
        `}
    >
      <Text
        element="h4"
        region="title"
        title={`Assign Jobs to ${m2_firstName} ${m2_lastName}`}
      />
      <Content region="region1">
        <DropDown
          data={batches}
          title={m2_batchName === "" ? "Batch" : m2_batchName}
        >
          {batches.map((batch, index) => {
            return (
              <DropDown.Options
                title={batch.batchName}
                key={`${batch._id}${index}`}
                onClick={async () => {
                  set("m2_batchName", batch.batchName);
                  set("m2_batchId", batch._id);
                  const data = await post("/batches/job", {
                    batchId: batch._id,
                  });
                  if (data) {
                    set("m2_batch", data);
                    set("m2_customerId", data[0].customerId);
                    set("m2_items", group_byItems(data));
                  }
                }}
              />
            );
          })}
        </DropDown>
        <Button
          title="Add Job"
          style={{ margin: "var(--margin" }}
          onClick={() => {
            add("m2_products", {
              sku: "",
              quantity: 0,
              outOf: 0,
              steps: [],
              step: "",
              desc: "",
              product: "",
              productId: "",
              skuId: "",
            });
          }}
        />
      </Content>

      <Content region={"region2"} style={{ justifyContent: "end" }}>
        <Text
          style={{ margin: "var(--margin)" }}
          title={choose_assignment_type().text}
        />
        <ButtonPrimary
          title="Confirm"
          onClick={() => {
            choose_assignment_type().func();
          }}
        />
      </Content>
      <Content region="job">
        <AddTask />
      </Content>
    </Card>
  );
}
