const errorTypes = {
  permittedChars: (value, reason, msg) => {
    if (reason instanceof RegExp || typeof reason === "string") {
      if (typeof reason === "string") reason = new RegExp(reason);
      if (!reason.test(value)) {
        return msg;
      }
      return false;
    } else {
      console.error("permittedChars must be a regular expression or string");
      return;
    }
  },

  restrictChars: (value, reason, msg) => {
    if (reason instanceof RegExp || typeof reason === "string") {
      if (typeof reason === "string") reason = new RegExp(reason);
      if (value.match(new RegExp(`[${reason}]`))) {
        return msg;
      }
      return false;
    } else {
      console.error("restrictChars must be a regular expression or string");
      return;
    }
  },

  requireChars: (value, reason, msg) => {
    if (reason instanceof RegExp || typeof reason === "string") {
      if (typeof reason === "string") reason = new RegExp(reason);
      if (!value.match(new RegExp(`[${reason}]`))) {
        return msg;
      }
      return false;
    } else {
      console.error("requireChars must be a regular expression or string");
      return;
    }
  },

  minLength: (value, reason, msg) => {
    if (typeof reason !== "number") {
      console.error("minLength reason must be a number");
      return;
    }
    if (
      value === "" ||
      value === undefined ||
      value === null ||
      value.length < reason
    ) {
      return msg;
    }
    return false;
  },

  maxLength: (value, reason, msg) => {
    if (typeof reason !== "number") {
      console.error("maxLength reason must be a number");
      return;
    }
    if (value.length > reason) {
      return msg;
    }
    return false;
  },

  minValue: (value, reason, msg) => {
    if (typeof reason !== "number") {
      console.error("minValue must be a number");
      return;
    }
    if (value === "") value = 0;
    if (value < reason) {
      return msg;
    }
    return false;
  },

  maxValue: (value, reason, msg) => {
    if (typeof reason !== "number") {
      console.error("maxValue must be a number");
      return;
    }
    if (value > reason) {
      return msg;
    }
    return false;
  },

  compareStrict: (value, reason, msg) => {
    if (value === reason) {
      return msg;
    }
    return false;
  },
};

/*
errors should be passed to the usePage hook as the second argument and should follow
this format const errors ={
    targetState: {
        errorType: [reason, msg]
        errorType: [reason, msg]
        }
}
*/
const setErrors = (errors) => {
  let errorFunctions = [];
  Object.entries(errors).forEach(([errKey, errRule]) => {
    errRule[1] = errRule[1] || "error";
    errorFunctions = [
      ...errorFunctions,
      {
        reason: errRule[0],
        msg: errRule[1],
        func: (val, reason, msg) => {
          return errorTypes[errKey](val, reason, msg);
        },
      },
    ];
  });
  return errorFunctions;
};

const getErrors = (errors, value) => {
  if (errors === undefined) return false;
  for (let i = 0; i < errors.length; i++) {
    let isError = errors[i].func(value, errors[i].reason, errors[i].msg);
    if (isError) {
      return isError;
    }
  }
  return false;
};

export { errorTypes, setErrors, getErrors };
