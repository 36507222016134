import styled from "styled-components";

const Button = styled.div`
  grid-area: ${(props) => props.$region};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  ${(props) => props.$more}
`;

export default function ButtonIcon({
  children,
  style,
  title,
  onClick,
  region,
}) {
  return (
    <Button
      $more={style}
      $region={region}
      onClick={() => {
        onClick();
      }}
    >
      {title}
      {children}
    </Button>
  );
}
