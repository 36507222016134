import DropDown from "../../components/DropDown";
import NumberInput from "../../components/NumberInput";
import TextInput from "../../components/TextInput";
import useServer from "../../hooks/useServer";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
import usePage from "../../usePage/usePage";
import Card from "../../components/Card";
import Text from "../../components/Text";
import TextArea from "../../components/TextArea";
import { useEffect } from "react";
import ButtonPrimary from "../../components/ButtonPrimary";

const errors = {
  name: { minLength: [1, "Name is required"] },
  cost: { minLength: [1, "Cost is required"] },
  supplier: { minLength: [1, "Supplier is required"] },
  website: { minLength: [1, "Website is required"] },
};
const unitTypes = {
  linear: ["in", "ft", "yd", "mi", "mm", "cm", "m", "km"],
  weight: ["oz", "lb", "mg", "g", "kg", "t"],
  quantity: [
    "ea",
    "doz",
    "dz",
    "ct",
    "bdl",
    "pk",
    "pt",
    "qt",
    "gal",
    "ml",
    "l",
    "hl",
  ],
  area: ["in2", "ft2", "yd2", "mi2", "mm2", "cm2", "m2", "km2"],
};

function NewMaterial() {
  const { state, set, reload, reset, errorCheck, errorForce } = usePage(
    {},
    errors
  );
  const {
    _id,
    name,
    nickName,
    description,
    amount,
    type,
    units,
    cost,
    supplier,
    website,
  } = state;
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();

  //UPDATE MATERIAL UNITS
  useEffect(() => {
    async function effect() {
      if (state === undefined) return;
      if (state.type.selected === "Linear") {
        set("units.options", unitTypes.linear);
        set("units.selected", "in");
      }
      if (state.type.selected === "Weight") {
        set("units.options", unitTypes.weight);
        set("units.selected", "oz");
      }
      if (state.type.selected === "Quantity") {
        set("units.options", unitTypes.quantity);
        set("units.selected", "ea");
      }
      if (state.type.selected === "Area") {
        set("units.options", unitTypes.area);
        set("units.selected", "in2");
      }
    }
    effect();
  }, [state.type.selected, set, unitTypes]);

  const on_submit = async (e) => {
    e.preventDefault();
    if (errorCheck()) return;
    const payload = {
      _id: _id,
      name: name,
      nickName: nickName,
      description: description,
      amount: parseFloat(amount),
      type: type.selected,
      units: units.selected,
      cost: parseFloat(cost),
      supplier: supplier,
      website: website,
    };
    let data = await post("upsert/material", payload);
    if (!data.pass) {
      errorForce(data.path, data.msg);
      return;
    }
    if (data.pass) {
      setAlert({ type: "success", msg: data.msg });
      reset("open");
      reset("mode");
      reset("_id");
      reset("name");
      reset("nickName");
      reset("description");
      reset("amount");
      reset("type.selected");
      reset("units.selected");
      reset("cost");
      reset("supplier");
      reset("website");
      reset("selectedType");
      reload("materials");
    }
  };

  return (
    <Card
      regions={`
      'name i1 i1 i1 i1 . .'
      'nickname i2 i2 i2 i2 . .'
      'desc i3 i3 i3 i3 . . '
      'inv i4 unit i5 i6 cost i7'
      'sup i8 . web i9 . .'
      '. . . . . . button'
      `}
    >
      <Text region="name" title="Material Name" element="h4" />
      <TextInput
        region="i1"
        value={name}
        required={true}
        onChange={(e) => {
          set("name", e.target.value);
        }}
        error={state.error["name"]}
      />

      <Text region="nickname" title="Nickname" element="h4" />
      <TextInput
        region="i2"
        value={nickName}
        onChange={(e) => set("nickName", e.target.value)}
      />

      <Text region="desc" title="Description" element="h4" />
      <TextArea region="i3" />

      <Text region="inv" title="Inventory" element="h4" />
      <NumberInput
        region="i4"
        value={amount}
        onChange={(e) => set("amount", e.target.value)}
      />
      <Text region="unit" title="Unit" element="h4" />
      <DropDown
        lock={state.mode === "edit"}
        region="i5"
        value={type.selected}
        title={type.selected}
        error={state.error["units"]}
      >
        {state.type.options.map((type) => {
          return (
            <DropDown.Options
              key={type}
              className="dropdown-option"
              onClick={() => {
                set("type.selected", type);
              }}
            >
              {type}
            </DropDown.Options>
          );
        })}
      </DropDown>
      <DropDown
        region="i6"
        lock={state.mode === "edit"}
        value={units.selected}
        title={units.selected}
      >
        {state.units.options.map((unit) => {
          return (
            <DropDown.Options
              key={unit}
              className="dropdown-option"
              onClick={() => {
                set("units.selected", unit);
              }}
            >
              {unit}
            </DropDown.Options>
          );
        })}
      </DropDown>
      <Text region="cost" title="Cost per Unit" element="h4" />
      <NumberInput
        region="i7"
        value={cost}
        required={true}
        onChange={(e) => {
          set("cost", e.target.value);
        }}
        error={state.error["cost"]}
      />

      <Text region="sup" title="Supplier" element="h4" />
      <TextInput
        region="i8"
        value={supplier}
        required={true}
        onChange={(e) => {
          set("supplier", e.target.value);
        }}
        error={state.error["supplier"]}
      />
      <Text region="web" title="Website" element="h4" />
      <TextInput
        region="i9"
        value={website}
        required={true}
        onChange={(e) => {
          set("website", e.target.value);
        }}
        error={state.error["website"]}
      />

      <ButtonPrimary
        region="button"
        title={state.mode === "new" ? "Create" : "Save Edit"}
        onClick={on_submit}
        style={{ justifySelf: "end" }}
      />
    </Card>
  );
}
export default NewMaterial;
