import styled from "styled-components";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import Error from "../icons/Error";

const Container = styled.div`
  grid-area: ${(props) => props.$region};
  position: relative;
  border-radius: var(--border-radius);
  border: ${(props) => (props.$isError ? "1px solid var(--error)" : "none")};
  ${(props) => props.$more}
`;

const Icon = styled.div`
  position: absolute;
  right: -6px;
  top: -6px;
  padding: 0px;
  font-size: small;
  z-index: 111;
  &:hover {
    cursor: pointer;
  }
  &:hover + .error-bubble {
    display: block;
  }
`;

const Bubble = styled.div`
  position: absolute;
  border-radius: var(--border-radius);
  display: none;
  padding: var(--pad);
  font-size: small;
  background-color: var(--error);
  color: white;
  z-index: 10;
`;

export default function ErrorBox(props) {
  const { children, error, style, region } = props;
  const [isError, setError] = useState(false);
  const change = useRef(error);
  const touch = useRef(false);
  const computedWidth = useRef(0);
  const bubble = useRef(null);

  useEffect(() => {
    if (change.current !== error) touch.current = true;
  }, [error]);

  useEffect(() => {
    if (error !== false && touch.current === true) {
      setError(true);
    } else {
      setError(false);
    }
  }, [error]);

  useLayoutEffect(() => {
    computedWidth.current = bubble.current.offsetWidth;
  }, [isError]);

  return (
    <Container
      className="error-box"
      $more={style}
      $isError={isError}
      $region={region}
    >
      {isError && (
        <Icon
          onClick={() => {
            bubble.current.style.display === "none"
              ? (bubble.current.style.display = "block")
              : (bubble.current.style.display = "none");
          }}
        >
          <Error fill="var(--error)" />
        </Icon>
      )}
      <Bubble
        className="error-bubble"
        ref={bubble}
        style={{ right: `-${computedWidth.current}px` }}
      >
        {error}
      </Bubble>
      {children}
    </Container>
  );
}
