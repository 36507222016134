import styled from "styled-components";
import React from "react";
import ErrorBox from "./ErrorBox";
import { useState } from "react";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Required = styled.p`
  display: ${(props) => (props.$lock ? "none" : "block")};
  color: ${(props) => (props.$error ? "var(--error)" : "var(--primary)")};
  position: absolute;
  left: -10px;
  top: 0;
`;

const Area = styled.textarea`
  width: 100%;
  display: block;
  border: 1px solid var(--secondary);
  border-radius: var(--radius);
  margin-bottom: var(--margin);
  padding: 1rem 1rem;
  min-height: 5em;
  outline: ${(props) => props.$outline};
  resize: vertical;
`;

export default function TextArea({
  placeholder,
  style,
  region,
  onChange,
  required,
  lock,
  value,
  error = false,
}) {
  const [hasFocus, setHasFocus] = useState(false);
  const mergedStyle = {
    gridArea: region,
    ...style,
  };

  const outline = () => {
    if (error && hasFocus) {
      return "2px solid var(--error)";
    }
    if (hasFocus) {
      return "2px solid black";
    }
  };

  const lockStatus = () => {
    if (lock === undefined) {
      return "";
    } else if (lock === false) {
      return "input-unlock";
    } else if (lock === true) {
      return "input-lock";
    }
  };

  const gridStyle = {
    gridArea: region,
    ...style,
  };

  return (
    <Container className={`${lockStatus()}`} style={gridStyle}>
      {required && (
        <Required $error={error} $lock={lock}>
          *
        </Required>
      )}
      <ErrorBox error={error} lock={lock}>
        <Area
          value={value}
          $outline={outline()}
          disabled={lock}
          style={mergedStyle}
          onChange={onChange}
          placeholder={placeholder}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
        />
      </ErrorBox>
    </Container>
  );
}
