import Card from "../../../../components/Card";
import usePage from "../../../../usePage/usePage";
import { useState, useCallback } from "react";
import styled from "styled-components";
import Upload from "../../../../icons/Upload";
import Text from "../../../../components/Text";
import useServer from "../../../../hooks/useServer";
import Button from "../../../../components/Button";
import ButtonPrimary from "../../../../components/ButtonPrimary";
import Content from "../../../../components/Content";

const UploadContainer = styled.div`
  grid-area: ${({ $region }) => $region};
  margin: 0 auto;
  padding: 1.5rem;
`;

const DropZone = styled.div`
  border: 2px dashed
    ${(props) =>
      props.$error ? "#EF4444" : props.$isDragging ? "#3B82F6" : "#D1D5DB"};
  border-radius: 0.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  background-color: ${(props) =>
    props.$isDragging ? "#EFF6FF" : "transparent"};
`;

const UploadIcon = styled(Upload)`
  width: 48px;
  height: 48px;
  margin-bottom: 1rem;
  color: ${(props) => (props.$isDragging ? "#3B82F6" : "#9CA3AF")};
`;

const Instructions = styled.p`
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #4b5563;
`;

const BrowseButton = styled.label`
  cursor: pointer;
  background-color: var(-background);
  color: var(--text);
  padding: 0.5rem 1rem;
  border: solid 1px var(--text);
  border-radius: 0.5rem;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--primary);
    color: var(--background);
  }
`;

const FileInput = styled.input`
  display: none;
`;

const FileName = styled.p`
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #059669;
`;

const ErrorMessage = styled.p`
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #ef4444;
`;

export default function CSVUploader() {
  const { state, set, add } = usePage();
  const { product_id, address_customerId } = state;
  const { file } = useServer();
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const uploadFile = async () => {
    if (!selectedFile) return;

    try {
      setIsUploading(true);

      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("productId", product_id);
      formData.append("customerId", address_customerId);

      const response = await file("/customer/uploadcsv", formData);
      if (response) {
        console.log("Server response:", response);
        response.data.forEach((data) => {
          add("product_skus", data);
        });
        setSelectedFile(null);
        setError("");
        setSuccess("File Uploaded Successfully");
      }
    } catch (err) {
      setError("Failed to upload file: " + err.message);
    } finally {
      setIsUploading(false);
    }
  };

  const handleFile = async (file) => {
    console.log(file);
    if (file.type !== "text/csv") {
      setError("Please upload a CSV file");
      setSelectedFile(null);
      return;
    }
    setSelectedFile(file);
  };

  const onCancelSelect = () => {
    setSelectedFile(null);
    setError("");
  };

  const onDragOver = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const onDragLeave = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const onDrop = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) handleFile(file);
  }, []);

  const onFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) handleFile(file);
  };

  return (
    <Card
      region="csv"
      regions={`'title' 'upload' 'buttons'`}
      style={`box-shadow: var(--shadow); width:100%;`}
    >
      <Text
        title="CSV Sku Upload"
        element="h3"
        style={`border-bottom: solid 1px var(--secondary);`}
      />
      <UploadContainer $region="upload">
        <DropZone
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          $error={error}
          $isDragging={isDragging}
        >
          <UploadIcon $isDragging={isDragging} />

          <Instructions>Drag and drop your CSV file here, or</Instructions>

          <BrowseButton>
            Browse Files
            <FileInput type="file" accept=".csv" onChange={onFileSelect} />
          </BrowseButton>

          {selectedFile && (
            <FileName>Selected file: {selectedFile.name}</FileName>
          )}

          {error && <ErrorMessage>{error}</ErrorMessage>}
          {success && <Text title={success} />}
        </DropZone>
        {selectedFile && (
          <Content
            region="buttons"
            style={`gap: var(--margin); justify-content: flex-end; margin-top: var(--margin);`}
          >
            <ButtonPrimary
              onClick={uploadFile}
              disabled={isUploading}
              title={isUploading ? "Uploading..." : "Upload File"}
            />

            <Button
              onClick={onCancelSelect}
              disabled={isUploading}
              title={"Cancel"}
            />
          </Content>
        )}
      </UploadContainer>
    </Card>
  );
}
