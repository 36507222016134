import CustomerSelect from "./modal/CustomerSelect";
import DropDown from "../../components/DropDown";
import OrderItems from "./modal/OrderItems";
import TextInput from "../../components/TextInput";
import Ellipse from "../../icons/Ellipse";
import order_create_payload from "./order_create_payload";
import useServer from "../../hooks/useServer";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
import { useState } from "react";
import usePage from "../../usePage/usePage";
import Card from "../../components/Card";
import Content from "../../components/Content";
import TextButton from "../../components/TextButton";
import Button from "../../components/Button";
import Text from "../../components/Text";
import ButtonPrimary from "../../components/ButtonPrimary";
import ButtonDelete from "../../components/ButtonDelete";
import AddressBlock from "../../components/AddressBlock";
import ButtonIcon from "../../components/ButtonIcon";
import Lock from "../../icons/Lock";
import Unlock from "../../icons/Unlock";
import Spinner from "../../icons/Spinner";

const errors = {
  "modal.orderInformation.orderNumber": {
    minLength: [1, "Order Number is required"],
  },

  "modal.general.firstName": { minLength: [1, "First Name is required"] },
  "modal.general.firstName": { minLength: [1, "Last Name is required"] },
  "modal.shippingAddress.address1": {
    minLength: [1, "Address Line 1 is required"],
  },
  "modal.shippingAddress.city": { minLength: [1, "City is required"] },
  "modal.shippingAddress.state": { minLength: [1, "State is required"] },
  "modal.shippingAddress.country": { minLength: [1, "Country is required"] },
  "modal.shippingAddress.postalCode": {
    minLength: [1, "Postal Code is required"],
  },
  "modal.billingAddress.address1": {
    minLength: [1, "Address Line 1 is required"],
  },
  "modal.billingAddress.city": { minLength: [1, "City is required"] },
  "modal.billingAddress.state": { minLength: [1, "State is required"] },
  "modal.billingAddress.country": { minLength: [1, "Country is required"] },
  "modal.billingAddress.postalCode": {
    minLength: [1, "Postal Code is required"],
  },
};

function OrderNew() {
  const { state, set, toggle, reset, reload, errorCheck } = usePage({}, errors);
  const { modal, mode } = state;
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();
  const [preventDup, setPreventDup] = useState(false);
  const [deleteOrder, setDeleteOrder] = useState(false);

  const copy_shipping = () => {
    set("modal.billingAddress.address1", modal.shippingAddress.address1);
    set("modal.billingAddress.address2", modal.shippingAddress.address2);
    set("modal.billingAddress.address3", modal.shippingAddress.address3);
    set("modal.billingAddress.city", modal.shippingAddress.city);
    set("modal.billingAddress.state", modal.shippingAddress.state);
    set("modal.billingAddress.country", modal.shippingAddress.country);
    set("modal.billingAddress.postalCode", modal.shippingAddress.postalCode);
  };

  const buttonName = () => {
    let name = "";
    return (name = mode === "create" ? "Create" : "Update");
  };

  const on_fulfill = async () => {
    const data = await post("/order/fulfill", modal.orderInformation._id);
    if (data) {
      setAlert({ msg: data.msg, type: "success" });
      set("mode", "create");
      reset("errors");
      reset("modal");
      reset("stores.products");
      reset("mode");
      reload("stores.orders");
    }
  };

  const on_submit = async () => {
    if (preventDup) return;
    if (errorCheck()) {
      set("general.hidden", false);
      set("shippingAddress.hidden", false);
      set("billingAddress.hidden", false);
      return;
    }
    const payload = order_create_payload(state);
    setPreventDup(true);
    const response = await post("/order", payload);
    if (response) {
      set("modal.msg", `"${response.msg}"`);
      set("modal.orderInformation._id", response.data._id);
      set("modal.general.lock", true);
      set("modal.shippingAddress.lock", true);
      set("modal.billingAddress.lock", true);
      set("modal.orderItems.lock", true);
      set("modal.orderInformation.lock", true);
      set("mode", "edit");
      reload("orders");
      setPreventDup(false);
    }
  };

  const on_delete = async () => {
    setPreventDup(true);
    const response = await post("/order/delete", modal.orderInformation._id);
    if (response) {
      setPreventDup(false);
      setAlert({ msg: response.msg, type: response.type });
      set("mode", "create");
      reset("errors");
      reset("modal");
      reset("products");
      reset("mode");
      reload("orders");
    }
  };

  const on_fill = (address) => {
    set("modal.general.hidden", false);
    set("modal.shippingAddress.hidden", false);
    set("modal.billingAddress.hidden", false);
    set("modal.addressId", address.id);

    set("modal.general.firstName", address.firstName);
    set("modal.general.lastName", address.lastName);
    set("modal.general.company", address.company);
    set("modal.general.website", address.website);
    set("modal.general.email", address.email);
    set("modal.general.phone", address.phone);

    set("modal.shippingAddress.address1", address.shippingAddress.address1);
    set("modal.shippingAddress.address2", address.shippingAddress.address2);
    set("modal.shippingAddress.address3", address.shippingAddress.address3);
    set("modal.shippingAddress.city", address.shippingAddress.city);
    set("modal.shippingAddress.state", address.shippingAddress.state);
    set("modal.shippingAddress.country", address.shippingAddress.country);
    set("modal.shippingAddress.postalCode", address.shippingAddress.postalCode);

    set("modal.billingAddress.address1", address.billingAddress.address1);
    set("modal.billingAddress.address2", address.billingAddress.address2);
    set("modal.billingAddress.address3", address.billingAddress.address3);
    set("modal.billingAddress.city", address.billingAddress.city);
    set("modal.billingAddress.state", address.billingAddress.state);
    set("modal.billingAddress.country", address.billingAddress.country);
    set("modal.billingAddress.postalCode", address.billingAddress.postalCode);
  };

  const delete_block = () => {
    if (mode === "edit") {
      return (
        deleteOrder && (
          <Content
            region="delete"
            style={`align-items: center; gap: var(--mar);`}
          >
            <Text
              style={`color:var(--error);`}
              title="WARNING! This cannot be undone. All items assoicated with this
              order will be permantly deleted. This does not delete the order
              from external originating sources."
            />
            <ButtonDelete
              title="Delete Permantly"
              onClick={on_delete}
              disabled={preventDup}
            />
          </Content>
        )
      );
    }
  };

  const customerBlock = () => {
    return (
      <Content
        region="customer"
        style={`align-content: center; align-items: center; gap: var(--mar);`}
      >
        <CustomerSelect />
        <ButtonPrimary
          disabled={preventDup}
          region="submit"
          title={buttonName()}
          onClick={on_submit}
          style={`justify-self: end;`}
        />
        {mode === "edit" && (
          <Button
            region="fulfill"
            title="Fulfill"
            onClick={on_fulfill}
            className="btn-success"
          />
        )}

        {preventDup && (
          <>
            <Text
              title="Submitting"
              style={`margin: 0; text-align: center;`}
              element="h3"
            />
            <Ellipse />
          </>
        )}
      </Content>
    );
  };

  //COMPONENT
  return (
    <>
      <Card
        regions={`
          '. customer customer customer customer .'
          '. delete delete delete delete .'
        `}
        style={`row-gap: var(--mar);`}
      >
        {delete_block()}
        {customerBlock()}
      </Card>
      <Card
        regions={`
        '. ordername ordername ordername delete'
        '. hideaddress . lockall .'
        '. title title title .'
        '. profileName profileName profileName .'
        '. quickfill quickfill quickfill .'
        '. general general lock .'
        '. company input1 input1 .'
        '. name input2 input3 .'
        '. website input4 input4 .'
        '. email input5 input5 .'
        '. phone input6 input6 .'
        `}
      >
        <Content
          region="ordername"
          style={`white-space: nowrap; gap: var(--mar);
            align-items: center;`}
        >
          <Text title="Order Number:" element="h3" />
          <TextInput
            required={true}
            value={modal.orderInformation.orderNumber}
            lock={modal.orderInformation.lock}
            error={state.error["modal.orderInformation.orderNumber"]}
            onChange={(e) => {
              set("modal.orderInformation.orderNumber", e.target.value);
            }}
          />
        </Content>
        {mode === "edit" && (
          <TextButton
            region="delete"
            title="Delete Order"
            onClick={() => {
              setDeleteOrder(!deleteOrder);
            }}
          />
        )}
        <TextButton
          region="hideaddress"
          title={modal.general.hidden ? "Show Address" : "Hide Address"}
          onClick={() => {
            toggle("modal.general.hidden");
            toggle("modal.shippingAddress.hidden");
            toggle("modal.billingAddress.hidden");
          }}
          style={`justify-self: start; margin-top: var(--mar);`}
        />
        <TextButton
          region="lockall"
          title={modal.orderInformation.lock ? "Unlock All" : "Lock All"}
          onClick={() => {}}
          style={`justify-self: end; margin-top: var(--mar);`}
        />
        {!modal.general.hidden && (
          <>
            <DropDown
              region="quickfill"
              title="Quick Fill"
              style={`margin: var(--mar) 0; `}
            >
              {modal.currentCustomer?.addressBook?.map((address) => {
                return (
                  <DropDown.Options
                    key={address._id}
                    onClick={() => {
                      on_fill(address);
                    }}
                  >
                    <Text
                      element="h6"
                      title={`${address.firstName} ${address.lastName}`}
                    />
                    <Text
                      element="p"
                      title={`${address.shippingAddress.address1}
              ${address.shippingAddress.city}
              ${address.shippingAddress.state}
              ${address.shippingAddress.postalCode}`}
                    />
                  </DropDown.Options>
                );
              })}
            </DropDown>
            <Text region="general" title="General" element="h3" />
            <ButtonIcon
              region="lock"
              onClick={() => {
                toggle("modal.general.lock");
              }}
              style={`justify-self: end;`}
            >
              {!modal.general.lock ? <Unlock /> : <Lock fill="var(--text)" />}
            </ButtonIcon>
            <Text region="company" title="Company" element="h5" />
            <TextInput
              region="input1"
              value={modal.general.company}
              onChange={(e) => {
                set("modal.general.company", e.target.value);
              }}
              lock={modal.general.lock}
            />
            <Text region="name" title="Name" element="h5" />
            <TextInput
              required={true}
              region="input2"
              value={modal.general.firstName}
              onChange={(e) => {
                set("modal.general.firstName", e.target.value);
              }}
              lock={modal.general.lock}
              error={state.error["modal.general.firstName"]}
              placeholder="First Name"
            />
            <TextInput
              region="input3"
              value={modal.general.lastName}
              onChange={(e) => {
                set("modal.general.lastName", e.target.value);
              }}
              lock={modal.general.lock}
              error={state.error["modal.general.lastName"]}
              placeholder="Last Name"
            />
            <Text region="website" title="Website" element="h5" />
            <TextInput
              region="input4"
              value={modal.general.website}
              onChange={(e) => {
                set("modal.general.website", e.target.value);
              }}
              lock={modal.general.lock}
            />
            <Text region="email" title="Email" element="h5" />
            <TextInput
              region="input5"
              value={modal.general.email}
              onChange={(e) => {
                set("modal.general.email", e.target.value);
              }}
              lock={modal.general.lock}
            />
            <Text region="phone" title="Phone" element="h5" />
            <TextInput
              region="input6"
              value={modal.general.phone}
              onChange={(e) => {
                set("modal.general.phone", e.target.value);
              }}
              lock={modal.general.lock}
            />
          </>
        )}
      </Card>
      {!modal.shippingAddress.hidden && (
        <>
          <AddressBlock
            title="Shipping Address"
            lock={modal.shippingAddress.lock}
            onLock={() => {
              toggle("modal.shippingAddress.lock");
            }}
            copyButton={{
              text: "Same as Primary?",
              onClick: () => {},
              hidden: true,
            }}
            address1={{
              value: modal.shippingAddress.address1,
              onChange: (e) => {
                set("modal.shippingAddress.address1", e.target.value);
              },
              error: state.error["modal.shippingAddress.address1"],
              placeholder: "Address Line 1",
              required: true,
            }}
            address2={{
              value: modal.shippingAddress.address2,
              onChange: (e) => {
                set("modal.shippingAddress.address2", e.target.value);
              },
              placeholder: "Address Line 2",
              required: false,
            }}
            address3={{
              value: modal.shippingAddress.address3,
              onChange: (e) => {
                set("modal.shippingAddress.address3", e.target.value);
              },
              placeholder: "Address Line 3",
              required: false,
            }}
            city={{
              value: modal.shippingAddress.city,
              onChange: (e) => {
                set("modal.shippingAddress.city", e.target.value);
              },
              error: state.error["modal.shippingAddress.city,"],
              placeholder: "City",
              required: true,
            }}
            state={{
              value: modal.shippingAddress.state,
              onChange: (e) => {
                set("modal.shippingAddress.state", e.target.value);
              },
              error: state.error["modal.shippingAddress.state"],
              placeholder: "State",
            }}
            country={{
              value: modal.shippingAddress.country,
              onClick: (value) => {
                set("modal.shippingAddress.country", value);
              },
              placeholder: "country",
              required: true,
            }}
            postalCode={{
              value: modal.shippingAddress.postalCode,

              onChange: (e) => {
                set("modal.shippingAddress.postalCode", e.target.value);
              },
              error: state.error["modal.shippingAddress.postalCode"],
              placeholder: "Postal Code",
              required: true,
            }}
          />
        </>
      )}
      {!modal.billingAddress.hidden && (
        <>
          <AddressBlock
            title="Billing Address"
            lock={modal.billingAddress.lock}
            onLock={() => {
              toggle("modal.billingAddress.lock");
            }}
            copyButton={{
              text: "Same as Primary?",
              onClick: () => {
                copy_shipping();
              },
              hidden: false,
            }}
            address1={{
              value: modal.billingAddress.address1,
              onChange: (e) => {
                set("modal.billingAddress.address1", e.target.value);
              },
              error: state.error["modal.billingAddress.address1"],
              placeholder: "Address Line 1",
              required: true,
            }}
            address2={{
              value: modal.billingAddress.address2,
              onChange: (e) => {
                set("modal.billingAddress.address2", e.target.value);
              },
              placeholder: "Address Line 2",
              required: false,
            }}
            address3={{
              value: modal.billingAddress.address3,
              onChange: (e) => {
                set("modal.billingAddress.address3", e.target.value);
              },
              placeholder: "Address Line 3",
              required: false,
            }}
            city={{
              value: modal.billingAddress.city,
              onChange: (e) => {
                set("modal.billingAddress.city", e.target.value);
              },
              error: state.error["modal.billingAddress.city"],
              placeholder: "City",
              required: true,
            }}
            state={{
              value: modal.billingAddress.state,
              onChange: (e) => {
                set("modal.billingAddress.state", e.target.value);
              },
              error: state.error["modal.billingAddress.state"],
              placeholder: "State",
            }}
            country={{
              value: modal.billingAddress.country,
              onClick: (value) => {
                set("modal.billingAddress.country", value);
              },
              placeholder: "country",
              required: true,
            }}
            postalCode={{
              value: modal.billingAddress.postalCode,

              onChange: (e) => {
                set("modal.billingAddress.postalCode", e.target.value);
              },
              error: state.error["modal.billingAddress.postalCode"],
              placeholder: "Postal Code",
              required: true,
            }}
          />
        </>
      )}
      {modal.orderItems_loading ? (
        <Spinner style={`padding-bottom: 50px;`} />
      ) : (
        <OrderItems />
      )}
    </>
  );
}
export default OrderNew;
