import { useContext, useEffect } from "react";
import { PageContext } from "./usePageProvider";

const usePage = (initial, errors) => {
  const page = useContext(PageContext);
  const { init, errorCleanup } = page;

  useEffect(() => {
    if (!initial) return;
    if (!init) return;
    init(initial, errors);

    return () => {
      if (errors) errorCleanup(errors);
    };
  }, [init]);

  return page;
};

export default usePage;
