import styled from "styled-components";

const StyledButton = styled.button`
  grid-area: ${({ $region }) => $region};
  margin-bottom: var(--margin);
  width: fit-content;
  height: min-content;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 11pt;
  padding: 10px 20px;
  line-height: 30px;
  height: min-content;
  width: fit-content;
  color: var(--text);
  ${(props) =>
    props.$lock
      ? `background-color: var(--lock);`
      : `background-color: var(--background);`}
  ${(props) =>
    !props.$lock &&
    `
    &:hover {
    cursor: pointer;
    color: var(--primary);
    background: var(--tertiary);
    border-color: var(--primary);
  }`}
  ${({ $more }) => $more}
`;

export default function Button({
  children,
  style,
  title,
  onClick,
  region,
  className,
  lock = false,
}) {
  return (
    <StyledButton
      $region={region}
      $more={style}
      $lock={lock}
      className={className}
      onClick={() => {
        if (lock) return;
        onClick();
      }}
      disabled={lock}
    >
      {title}
      {children}
    </StyledButton>
  );
}
