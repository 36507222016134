import NumberInput from "../../../../components/NumberInput";
import TextInput from "../../../../components/TextInput";
import CloseIcon from "../../../../icons/Close";
import DragDropIcon from "../../../../icons/DragDrop";
import usePage from "../../../../usePage/usePage";
import Card from "../../../../components/Card";
import Text from "../../../../components/Text";
import ButtonIcon from "../../../../components/ButtonIcon";
import Button from "../../../../components/Button";
import Content from "../../../../components/Content";
import TextArea from "../../../../components/TextArea";
import CheckBox from "../../../../components/CheckBox";

import { useEffect, useRef } from "react";
import DragList from "../../../../components/DragList";
import DropDown from "../../../../components/DropDown";

function ProcessStep(props) {
  const { index, step, lock, process_type } = props;

  const { state, set, add, errorCleanup, errorInit, remove, init } = usePage();
  const handle = useRef(null);

  useEffect(() => {
    errorInit({
      [`process_steps.${index}.name`]: {
        minLength: [1, "Step Name is required"],
      },
    });
    return () => {
      errorCleanup({
        [`process_steps.${index}.name`]: {
          minLength: [1, "Step Name is required"],
        },
      });
    };
  }, [errorInit, index]);

  const add_step = () => {
    add("process_steps", {
      desc: "",
      name: "",
      type: "step",
    });
  };

  return (
    <Card
      regions={`
        'close . . . drag'
        'name name name check check'
        'desc desc desc desc desc'
        'step . . . . '
        `}
      style={`box-shadow: var(--shadow); background-color: var(--background);`}
    >
      {!lock && (
        <ButtonIcon
          region="close"
          tabIndex={-1}
          onClick={() => {
            remove("process_steps", index);
          }}
          style={`justify-self: start;`}
        >
          <CloseIcon />
        </ButtonIcon>
      )}
      <Content region="drag" style={`justify-content: flex-end;`}>
        <DragList.Handle>
          <DragDropIcon />
        </DragList.Handle>
      </Content>
      <Content region="name" style={`align-items:center; `}>
        <Text title={`Step. ${index + 1} Name`} element="h4" />
        {step.type === "sub" ? (
          <DropDown title={step.name === "" ? "Select Process" : step.name}>
            {state.sub_process.map((sub, i) => {
              return (
                <DropDown.Options
                  key={`sub${index}${i}`}
                  onClick={() => {
                    set(`process_steps.${index}.name`, sub.name);
                    set(`process_steps.${index}.desc`, sub.steps[0].desc);
                    set(`process_steps.${index}.subProcessId`, sub._id);
                  }}
                >
                  {sub.name}
                </DropDown.Options>
              );
            })}
          </DropDown>
        ) : (
          <TextInput
            value={step.name}
            onChange={(e) => {
              set(`process_steps.${index}.name`, e.target.value);
            }}
            error={state.error[`process_steps.${index}.name`]}
            placeholder="Step Name"
            required={true}
          />
        )}
      </Content>
      {state.process_type === "main" && (
        <Content
          region="check"
          style={`align-items:center; justify-content:end; gap: var(--mar);`}
        >
          <Text title="Sub-Process" element="h4" />
          <CheckBox
            value={step.type === "sub"}
            onClick={() => {
              if (step.type === "sub")
                set(`process_steps.${index}.type`, "step");
              if (step.type === "step")
                set(`process_steps.${index}.type`, "sub");
              set(`process_steps.${index}.subProcessId`, "");
              set(`process_steps.${index}.name`, "");
              set(`process_steps.${index}.desc`, "");
            }}
          />
        </Content>
      )}

      <TextArea
        region="desc"
        lock={step.type === "sub"}
        value={step.desc}
        onChange={(e) => {
          set(`process_steps.${index}.desc`, e.target.value);
        }}
      />
      {index === state.process_steps.length - 1 && process_type === "main" && (
        <Button region="step" title="+ Step" onClick={add_step} />
      )}
    </Card>
  );
}

export default ProcessStep;
