import styled from "styled-components";

const Button = styled.button`
  grid-area: ${(props) => props.$region};
  margin-bottom: var(--margin);
  width: fit-content;
  height: min-content;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 11pt;
  padding: 10px 20px;
  line-height: 30px;
  color: var(--background);
  background-color: var(--primary);
  &:hover {
    cursor: pointer;
    background: var(--background);
    color: var(--primary);
    border: 1px solid var(--primary);
  }
  ${(props) => props.$more}
`;

export default function ButtonPrimary({
  children,
  style,
  title,
  onClick,
  region,
}) {
  return (
    <Button
      $more={style}
      $region={region}
      onClick={(e) => {
        onClick(e);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClick(e);
        }
      }}
    >
      {title}
      {children}
    </Button>
  );
}
