import date_trim from "../../utils/date_trim";
import useServer from "../../hooks/useServer";
import usePage from "../../usePage/usePage";
import Table, { Row, Cell } from "../../components/Table";
import Text from "../../components/Text";
import Card from "../../components/Card";
import Content from "../../components/Content";

function BatchList() {
  const { state, set } = usePage();
  const { post } = useServer();
  const { batchList, batches } = state;

  const handleOnSelect = async (batch) => {
    const batchId = batch._id;
    const name = batch.batchName;
    let data = await post("/find/batch/items", { batchId: batchId });
    if (data) {
      set("detailedView", {
        orders: [
          ...data.map((order) => {
            return {
              ...order,
              show: false,
              orderItems: order.orderItems.map((orderItem) => {
                return {
                  ...orderItem,
                  show: false,
                };
              }),
            };
          }),
        ],
        batchId: batchId,
        batchName: name,
        searched: false,
        update: false,
        group: "",
        station: "",
        redirect: false,
      });
    }
  };

  const get_item_totals = (orderItems) => {
    return orderItems.reduce((acc, item) => acc + item.quantity, 0);
  };

  //COMPONENT
  return (
    <Card
      region="list"
      regions={`'title' 'warning' 'table'`}
      style={`box-shadow: var(--shadow);`}
    >
      <Text
        region="warning"
        title={batchList?.alert}
        element="h4"
        style={{ textAlign: "center", color: "var(--warning)" }}
      />
      <Text region="title" title="Batches" element="h1" />
      <Content
        region="table"
        style={`border: solid 1px var(--secondary); 
          height: calc(100vh - 182px); overflow-y: scroll;
          flex-direction: column;
          `} //recalculate height if var(--mar) changes
      >
        {batches?.map((batch, index) => {
          return (
            <Card
              regions={`'titledate titledate total'`}
              key={batch._id}
              style={` 
              ${
                index === batches.length - 1
                  ? "border-bottom: none;"
                  : "border-bottom: solid 1px var(--secondary);"
              }  
              border-right: solid 1px var(--secondary); 
              margin: 0; padding: var(--pad); 
              border-radius: 0;
              }`}
            >
              <Content
                region="titledate"
                style={`
                  flex-direction: column;  
                  &:hover {
                  cursor: pointer;
                  & > * {
                    color: var(--primary);
                  }
                }`}
                onClick={(e) => {
                  handleOnSelect(batch);
                }}
              >
                <Text title={`${batch.batchName}`} element="h3" />
                <Text title={`${date_trim(batch.batchDate)}`} />
              </Content>

              <Text
                region="total"
                title={`x${get_item_totals(batch.orderItems)}`}
                element="b"
                style={`justify-self: end;`}
              />
            </Card>
          );
        })}
      </Content>
    </Card>
  );
}

export default BatchList;
