import Card from "../../components/Card";
import Text from "../../components/Text";

function Menu() {
  return (
    <Card regions={`'title'`} style={{ boxShadow: "var(--shadow)" }}>
      <Text region="title" title="Metrics" element="h1" />
    </Card>
  );
}

export default Menu;
