import BarcodeSVG from "./BarcodeSVG";
import Barcode from "../../utils/barcode";
import CloseIcon from "../../icons/Close";
import usePage from "../../usePage/usePage";

function BatchBarcodeModal() {
  const { state, reset } = usePage();
  const { modal } = state;
  return (
    <>
      {modal?.open && (
        <div
          className="barcode-modal-container"
          onClick={(e) => {
            reset("barcodeModal");
          }}
        >
          <div className="barcode-modal">
            <div className="bar split barcode-nav">
              <h4>{modal.barcodes?.[0]?.batchName}</h4>
              <button
                className="btn-icon"
                onClick={(e) => {
                  reset("modal");
                }}
              >
                <CloseIcon />
              </button>
            </div>

            <div className="barcodes-container">
              {modal.barcodes?.map((barcode, index) => {
                let img;
                if (barcode.skuId === "") {
                  img = {
                    missing: true,
                  };
                } else {
                  img = new Barcode(barcode._id).draw();
                }

                return (
                  <BarcodeSVG
                    img={img}
                    item={barcode}
                    key={`${index}`}
                    prevIndex={index}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default BatchBarcodeModal;
