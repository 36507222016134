import General from "../../components/General";
import AddressBlock from "../../components/AddressBlock";
import Provider from "./customerNew/Provider";
import useServer from "../../hooks/useServer";
import format_new_customer_payload from "./format_new_customer_payload";
import format_save_address from "./format_save_address";
import TextInput from "../../components/TextInput";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
import usePage from "../../usePage/usePage";
import Card from "../../components/Card";
import Text from "../../components/Text";
import ButtonIcon from "../../components/ButtonIcon";
import Lock from "../../icons/Lock";
import Unlock from "../../icons/Unlock";
import ButtonPrimary from "../../components/ButtonPrimary";
import Content from "../../components/Content";

const errors = {
  modal_profileName: { minLength: [1, "Profile Name is required"] },
  modal_firstName: { minLength: [1, "First Name is required"] },
  modal_lastName: { minLength: [1, "Last Name is required"] },
  modal_ship_address1: { minLength: [1, "Address Line 1 is required"] },
  modal_ship_city: { minLength: [1, "City is required"] },
  modal_ship_state: { minLength: [1, "State is required"] },
  modal_ship_country: { minLength: [1, "Country is required"] },
  modal_ship_postalCode: { minLength: [1, "Postal Code is required"] },
  modal_bill_address1: { minLength: [1, "Address Line 1 is required"] },
  modal_bill_city: { minLength: [1, "City is required"] },
  modal_bill_state: { minLength: [1, "State is required"] },
  modal_bill_country: { minLength: [1, "Country is required"] },
  modal_bill_postalCode: { minLength: [1, "Postal Code is required"] },
};

function CustomerNew() {
  const { state, set, toggle, errorCheck, reload, reset } = usePage({}, errors);
  const {
    lock,
    mode,
    modal_customerId,
    modal_address_id,
    modal_profileName,
    modal_company,
    modal_firstName,
    modal_lastName,
    modal_website,
    modal_email,
    modal_phone,
    modal_ship_lock,
    modal_ship_address1,
    modal_ship_address2,
    modal_ship_address3,
    modal_ship_city,
    modal_ship_state,
    modal_ship_country,
    modal_ship_postalCode,
    modal_bill_lock,
    modal_bill_address1,
    modal_bill_address2,
    modal_bill_address3,
    modal_bill_city,
    modal_bill_state,
    modal_bill_country,
    modal_bill_postalCode,
  } = state;
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();

  const modalReset = () => {
    reset("modal_open");
    reset("modal_profileName");
    reset("modal_customerId");
    reset("modal_lock");
    reset("modal_address_id");
    reset("modal_company");
    reset("modal_firstName");
    reset("modal_lastName");
    reset("modal_website");
    reset("modal_email");
    reset("modal_phone");
    reset("modal_ship_lock");
    reset("modal_ship_address1");
    reset("modal_ship_address2");
    reset("modal_ship_address3");
    reset("modal_ship_city");
    reset("modal_ship_state");
    reset("modal_ship_country");
    reset("modal_ship_postalCode");
    reset("modal_bill_lock");
    reset("modal_bill_address1");
    reset("modal_bill_address2");
    reset("modal_bill_address3");
    reset("modal_bill_city");
    reset("modal_bill_state");
    reset("modal_bill_country");
    reset("modal_bill_postalCode");
    reset("modal_provider_source");
    reset("modal_provider_data");
  };

  const create = async () => {
    if (errorCheck()) return;
    const payload = format_new_customer_payload(state);
    const response = await post("/customer/create", payload);
    if (response) {
      modalReset();
      setAlert({ type: "success", msg: "Customer Created" });
      reload("customers");
    }
  };

  const edit = async () => {
    if (errorCheck()) return;
    const address = format_save_address(state);
    const payload = {
      address: address,
      addressId: modal_address_id,
      customerId: modal_customerId,
    };
    const response = await post("/update/address", payload);
    if (response) {
      setAlert({ type: "success", msg: "Address Updated" });
      reload("customers");
    }
  };

  const copy_shipping = () => {
    set("modal_bill_address1", modal_ship_address1);
    set("modal_bill_address2", modal_ship_address2);
    set("modal_bill_address3", modal_ship_address3);
    set("modal_bill_city", modal_ship_city);
    set("modal_bill_state", modal_ship_state);
    set("modal_bill_country", modal_ship_country);
    set("modal_bill_postalCode", modal_ship_postalCode);
  };

  return (
    <>
      <Card
        regions={`
        '. title title title .'
        '. profileName profileName profileName .'
        '. general general lock .'
        '. company input1 input1 .'
        '. name input2 input3 .'
        '. website input4 input4 .'
        '. email input5 input5 .'
        '. phone input6 input6 .'
        `}
      >
        <Text region="title" title="Customer Profile Name" element="h2" />
        <TextInput
          region="profileName"
          required={true}
          value={modal_profileName}
          lock={mode === "edit"}
          onChange={(e) => set("modal_profileName", e.target.value)}
          error={state.error["modal_profileName"]}
        />
        <Text region="general" title="General" element="h3" />
        <ButtonIcon
          region="lock"
          onClick={() => {
            toggle("lock");
          }}
          style={`justify-self: end;`}
        >
          {!lock ? <Unlock /> : <Lock fill="var(--text)" />}
        </ButtonIcon>
        <Text region="company" title="Company" element="h5" />
        <TextInput
          region="input1"
          value={modal_company}
          onChange={(e) => {
            set("modal_company", e.target.value);
          }}
          lock={lock}
        />
        <Text region="name" title="Name" element="h5" />
        <TextInput
          required={true}
          region="input2"
          value={modal_firstName}
          onChange={(e) => {
            set("modal_firstName", e.target.value);
          }}
          lock={lock}
          error={state.error["modal_firstName"]}
          placeholder="First Name"
        />
        <TextInput
          region="input3"
          value={modal_lastName}
          onChange={(e) => {
            set("modal_lastName", e.target.value);
          }}
          lock={lock}
          error={state.error["modal_lastName"]}
          placeholder="Last Name"
        />
        <Text region="website" title="Website" element="h5" />
        <TextInput
          region="input4"
          value={modal_website}
          onChange={(e) => {
            set("modal_website", e.target.value);
          }}
          lock={lock}
        />
        <Text region="email" title="Email" element="h5" />
        <TextInput
          region="input5"
          value={modal_email}
          onChange={(e) => {
            set("modal_email", e.target.value);
          }}
          lock={lock}
        />
        <Text region="phone" title="Phone" element="h5" />
        <TextInput
          region="input6"
          value={modal_phone}
          onChange={(e) => {
            set("modal_phone", e.target.value);
          }}
          lock={lock}
        />
      </Card>

      <AddressBlock
        title="Shipping Address"
        lock={modal_ship_lock}
        onLock={() => {
          toggle("modal_ship_lock");
        }}
        copyButton={{
          text: "Same as Primary?",
          onClick: () => {},
          hidden: true,
        }}
        address1={{
          value: modal_ship_address1,
          onChange: (e) => {
            set("modal_ship_address1", e.target.value);
          },
          error: state.error["modal_ship_address1"],
          placeholder: "Address Line 1",
          required: true,
        }}
        address2={{
          value: modal_ship_address2,
          onChange: (e) => {
            set("modal_ship_address2", e.target.value);
          },
          placeholder: "Address Line 2",
          required: false,
        }}
        address3={{
          value: modal_ship_address3,
          onChange: (e) => {
            set("modal_ship_address3", e.target.value);
          },
          placeholder: "Address Line 3",
          required: false,
        }}
        city={{
          value: modal_ship_city,
          onChange: (e) => {
            set("modal_ship_city", e.target.value);
          },
          error: state.error["modal_ship_city"],
          placeholder: "City",
          required: true,
        }}
        state={{
          value: modal_ship_state,
          onChange: (e) => {
            set("modal_ship_state", e.target.value);
          },
          error: state.error["modal_ship_state"],
          placeholder: "State",
        }}
        country={{
          value: modal_ship_country,
          onClick: (value) => {
            console.log(value);
            set("modal_ship_country", value);
          },
          placeholder: "country",
          required: true,
        }}
        postalCode={{
          value: modal_ship_postalCode,

          onChange: (e) => {
            set("modal_ship_postalCode", e.target.value);
          },
          error: state.error["modal_ship_postalCode"],
          placeholder: "Postal Code",
          required: true,
        }}
      />
      <AddressBlock
        title="Billing Address"
        lock={modal_bill_lock}
        onLock={() => {
          toggle("modal_bill_lock");
        }}
        copyButton={{
          text: "Same as Primary?",
          onClick: () => {
            copy_shipping();
          },
          hidden: false,
        }}
        address1={{
          value: modal_bill_address1,
          onChange: (e) => {
            set("modal_bill_address1", e.target.value);
          },
          error: state.error["modal_bill_address1"],
          placeholder: "Address Line 1",
          required: true,
        }}
        address2={{
          value: modal_bill_address2,
          onChange: (e) => {
            set("modal_bill_address2", e.target.value);
          },
          placeholder: "Address Line 2",
          required: false,
        }}
        address3={{
          value: modal_bill_address3,
          onChange: (e) => {
            set("modal_bill_address3", e.target.value);
          },
          placeholder: "Address Line 3",
          required: false,
        }}
        city={{
          value: modal_bill_city,
          onChange: (e) => {
            set("modal_bill_city", e.target.value);
          },
          error: state.error["modal_bill_city"],
          placeholder: "City",
          required: true,
        }}
        state={{
          value: modal_bill_state,
          onChange: (e) => {
            set("modal_bill_state", e.target.value);
          },
          error: state.error["modal_bill_state"],
          placeholder: "State",
        }}
        country={{
          value: modal_bill_country,
          onClick: (value) => {
            console.log(value);
            set("modal_bill_country", value);
          },
          placeholder: "country",
          required: true,
        }}
        postalCode={{
          value: modal_bill_postalCode,

          onChange: (e) => {
            set("modal_bill_postalCode", e.target.value);
          },
          error: state.error["modal_bill_postalCode"],
          placeholder: "Postal Code",
          required: true,
        }}
      />
      {mode === "new" ? <Provider /> : <></>}
      <Content
        style={`flex-direction: column; align-items:center; padding-bottom: var(--mar);`}
      >
        <ButtonPrimary
          title={mode === "new" ? "Create Customer" : "Edit Customer"}
          onClick={mode === "new" ? create : edit}
        />
      </Content>
    </>
  );
}
export default CustomerNew;
