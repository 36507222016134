import CueSidebar from "./CueSidebar";
import CueTable from "./CueTable";
import OrderTable from "./OrderTable";
import usePage from "../../../usePage/usePage";
import { useEffect, useMemo } from "react";
import Card from "../../../components/Card";
import useServer from "../../../hooks/useServer";

const filterOrders = (orders, filters) => {
  if (!Array.isArray(orders)) return [];
  if (!filters || filters?.length === 0) return orders;
  const filtered = [];
  let chronoOrder = "newest";
  orders?.forEach((each) => {
    let filterCounter = 0;
    filters.forEach((filter) => {
      const re = new RegExp(filter.id, "gi");
      if (filter.type === "customer" && each.customerId === filter.id)
        filterCounter++;
      if (filter.type === "search" && each.orderNumber.match(re))
        filterCounter++;
      if (filter.type === "date") {
        chronoOrder = filter.id;
        filterCounter++;
      }
    });
    if (filterCounter === filters.length) filtered.push(each);
  });

  if (chronoOrder === "newest") {
    sortByNewest(filtered);
  }
  if (chronoOrder === "oldest") {
    sortByOldest(filtered);
  }

  return filtered;
};

const sortByOldest = (orders) => {
  orders.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateA - dateB;
  });
};

const sortByNewest = (orders) => {
  orders.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateB - dateA;
  });
};

function Body() {
  const { state, set, load } = usePage();
  const { post } = useServer();
  const { cueSidebar, cueTable, orders, customers, mode, filters } = state;

  const filteredOrders = useMemo(() => {
    return filterOrders(orders, filters);
  }, [orders, filters]);

  useEffect(() => {
    set("menu.count", filteredOrders.length);
  }, [filteredOrders]); // i should never i have used react. We can't "set" menu.count in memo

  return (
    <Card
      regions={cueSidebar?.open ? `'sidebar table table'` : `'table'`}
      style={`margin:0; padding: 0;`}
    >
      {cueSidebar?.open && <CueSidebar />}
      {cueTable?.open === true ? (
        <CueTable />
      ) : (
        <OrderTable
          orders={filteredOrders}
          customers={customers}
          mode={mode}
          set={set}
          load={load}
          post={post}
        />
      )}
    </Card>
  );
}

export default Body;
