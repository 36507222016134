import styled from "styled-components";
const Container = styled.div`
  box-sizing: content-box;
  display: flex;
  height: 50px;
  border: 1px solid var(--secondary);
  border-radius: 3px;
  margin-bottom: var(--margin);
  &:focus-within {
    outline: 2px solid #38464e;
  }
`;

const Input = styled.input`
  display: block;

  flex-grow: 1;
  padding: 10px;
  font-size: 11pt;
  color: #38464e;
  background-color: #fff;
  background-image: none;
  border: none;
  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  display: inline-block;
  padding: 10px;
  font-size: 11pt;
  color: var(--primary);
  background: var(--btn-color);
  border: none;
  font-weight: 600;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  &:hover {
    background: var(--primary);
    color: var(--background);
    cursor: pointer;
  }
`;

export default function SearchBar(props) {
  const {
    value = "",
    name,
    placeholder,
    action = () => {},
    lock,
    click,
    change,
    btnName,
    region,
    style,
  } = props;

  const mergedStyle = {
    gridArea: region,
    ...style,
  };

  return (
    <Container style={mergedStyle}>
      <Input
        disabled={lock}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={(e) => {
          change(e);
        }}
        onKeyDown={(e) => {
          action(e);
        }}
      />
      <Button
        disabled={lock}
        onClick={(e) => {
          e.preventDefault();
          click(e);
        }}
      >
        {btnName === undefined ? "Search" : btnName}
      </Button>
    </Container>
  );
}
