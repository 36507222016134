import styled from "styled-components";
import { useState, useEffect, useRef, useContext, useCallback } from "react";
import React from "react";
import ErrorBox from "./ErrorBox";
import { DynamicCellContext } from "./Table";
import { DragStackContext } from "./DragList";

const Container = styled.div`
  grid-area: ${(props) => props.$region};
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: var(--background);
  color: var(--text);
  ${(props) => props.$more}
`;

const Button = styled.div`
  position: relative;
  box-sizing: content-box;
  background-color: var(--background);
  border-radius: var(--border-radius);
  color: var(--text);
  font-size: 11pt;
  height: 30px;
  width: 168px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
`;

const Text = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const OptionContainer = styled.div`
  position: absolute;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: var(--background);
  z-index: 999;
  font-size: 11pt;
  color: var(--text);
  width: 100%;
  min-width: max-content;
  overflow-y: scroll;
  max-height: 200px;
  box-shadow: var(--shadow);
`;

//COMPONENT
const DropDown = (props) => {
  const {
    children,
    lock = false,
    title = "",
    onOpen = () => {},
    error = false,
    region,
    style,
    value = null,
  } = props;
  const self = useRef();
  const selfButton = useRef();
  const selfChildren = useRef();
  const optionsRef = useRef();
  const [optionsIndex, setOptionIndex] = useState(-1);
  const [activeElement, setActiveElement] = useState();
  const [show, setShow] = useState(false);
  const [choosing, setChoosing] = useState(false);
  const [search, setSearch] = useState("");
  const { setAllowOverflow, allowOverflow } = useContext(DynamicCellContext);
  const { setStack, stack } = useContext(DragStackContext);

  const choosingRef = useRef(choosing);

  useEffect(() => {
    choosingRef.current = choosing;
  }, [choosing]);

  useEffect(() => {
    const checkMouseOver = (e) => {
      try {
        let x = e.clientX;
        let y = e.clientY;
        let parent = self.current.getBoundingClientRect();
        let children = selfChildren.current.getBoundingClientRect();
        let bounds = {
          left: parent.left,
          right: children.right,
          top: parent.top,
          bottom: children.bottom,
        };
        if (
          x < bounds.left ||
          x > bounds.right ||
          y < bounds.top ||
          y > bounds.bottom
        ) {
          {
            setChoosing(false);
            setShow(false);
            setAllowOverflow(false);
            setStack(false);
          }
        }
      } catch (error) {
        setChoosing(false);
        setShow(false);
        setAllowOverflow(false);
        setStack(false);
      }
    };

    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        setShow(false);
        setAllowOverflow(false);
      }
    });
    document.addEventListener("mousemove", (e) => {
      if (choosingRef.current === true) {
        checkMouseOver(e);
      }
    });
    //Clean up
    return () => {
      document.removeEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          setShow(false);
          setAllowOverflow(false);
        }
      });
      document.removeEventListener("mousemove", (e) => {
        if (choosingRef.current === true) {
          checkMouseOver(e);
        }
      });
    };
  }, []);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!show) return;

      // Ignore special key presses
      if (
        e.key === "ArrowUp" ||
        e.key === "ArrowDown" ||
        e.key === "Enter" ||
        e.key === "Escape" ||
        e.key === "Tab" ||
        e.ctrlKey ||
        e.altKey ||
        e.metaKey
      ) {
        return;
      }

      if (e.key === "Backspace") {
        setSearch((prev) => prev.slice(0, -1));
      } else if (e.key.length === 1) {
        // Only add printable characters
        setSearch((prev) => prev + e.key);
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [show]);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setShow(true);
      if (selfButton.current === activeElement && e.key === "ArrowDown") {
        setShow(true);
      }
      if (show) {
        const option = selfChildren.current.children;
        if (optionsIndex + 1 > option.length - 1) return;
        let i = optionsIndex + 1;
        optionsRef.current = option[i];
        setOptionIndex(i);
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (optionsIndex === 0 && e.key === "ArrowUp") {
        setShow(false);
        return;
      }
      if (show) {
        const option = selfChildren.current.children;
        let i = optionsIndex - 1;
        optionsRef.current = option[i];
        setOptionIndex(i);
      }
      setShow(true);
    } else if (e.key === "Enter") {
      e.preventDefault();
      setChoosing(false);
      setShow(false);
      setOptionIndex(-1);
    } else if (e.key === "Escape") {
      e.preventDefault();
      setShow(false);
      setAllowOverflow(false);
      setStack(false);
    }
  };

  useEffect(() => {
    setActiveElement(document.activeElement);
  }, [document.activeElement]);

  useEffect(() => {
    if (optionsRef.current) {
      optionsRef.current.focus();
    }
  }, [optionsRef.current]);

  const outline = () => {
    if (error && choosing) {
      return "2px solid var(--error)";
    }
    if (choosing) {
      return "2px solid black";
    }
  };

  const editStyle = {
    border: "1px solid #ccc",
    outline: outline(),
  };

  const lockStyle = {
    backgroundColor: "var(--lock)",
    color: "var(--text)",
    border: "1px solid #fff",
    padding: "0.125rem",
  };

  const arrowDisplay = () => {
    if (lock === true) {
      return "";
    } else if (lock === false && show === false) {
      return "▼";
    } else if (lock === false && show === true) {
      return "▲";
    }
  };

  const mergedStyle = {
    ...style,
    gridArea: region,
  };

  //COMPONENT
  return (
    <Container
      $more={style}
      $region={region}
      style={lock === true ? lockStyle : editStyle}
      ref={self}
    >
      <ErrorBox error={error} style={mergedStyle}>
        <Button
          ref={selfButton}
          tabIndex={0}
          onClick={(e) => {
            if (lock) return;
            e.preventDefault();
            show === false ? setShow(true) : setShow(false);
            setChoosing(!choosing);
            onOpen();
            setAllowOverflow(!allowOverflow);
            setStack(!stack);
          }}
          onKeyDown={(e) => {
            if (lock) return;
            handleKeyDown(e);
          }}
          disabled={choosing}
        >
          <Text>{`${arrowDisplay()} ${title}`}</Text>
        </Button>
        {show && (
          <OptionContainer
            ref={selfChildren}
            onClick={(e) => {
              setChoosing(false);
              setShow(false);
              setAllowOverflow(false);
              setStack(false);
            }}
          >
            {React.Children.map(children, (child, index) => {
              if (child) {
                return React.cloneElement(child, {
                  myIndex: index,
                  selectedIndex: optionsIndex,
                  search: search,
                  props: props,
                });
              }
              return null;
            })}
          </OptionContainer>
        )}
      </ErrorBox>
    </Container>
  );
};

const Option = styled.div`
  padding: 10px;
  cursor: pointer;
  overflow: hidden;
  white-space: normal;
  text-overflow: clip;
  &:hover {
    background-color: var(--tertiary);
  }
`;

export const Options = ({
  children,
  title,
  onClick,
  myIndex,
  selectedIndex,
  search,
  value = null,
}) => {
  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Enter" && myIndex === selectedIndex) {
        e.preventDefault();
        onClick();
      }
    });
  }, [myIndex, selectedIndex]);

  const check = useCallback(() => {
    if (!search) return true;
    return value.toLowerCase().includes(search);
  }, [value, search]);

  return (
    <>
      {check() && (
        <Option
          style={
            myIndex === selectedIndex
              ? { backgroundColor: "var(--tertiary)" }
              : null
          }
          onClick={() => {
            onClick();
          }}
        >
          {title}
          {children}
        </Option>
      )}
    </>
  );
};
DropDown.Options = Options;

export default DropDown;
