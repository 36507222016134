import useGlobalAlert from "../../../context/GlobalAlert/useGlobalAlert";
import Table, { StickyRow, Row, Cell } from "../../../components/Table";
import PST_age from "../../../utils/PST_age";
import date_trim from "../../../utils/date_trim";
import order_format_modal from "../order_format_modal";
import order_async_format_modal from "../order_async_format_modal";
import { memo } from "react";
import Card from "../../../components/Card";

const OrderTable = memo(function MemoOrderTable({
  set,
  mode,
  orders,
  customers,
  post,
}) {
  const format_cue_table = (order) => {
    const customer = customer_lookup(order);
    const payload = {
      ...order,
      open: true,
      customerName: customer.profileName,
    };
    set("cueTable", payload);
  };

  const match_batch = (each) => {
    const batches = [];
    each.orderItems.forEach((item) => {
      item.batch.forEach((batchItem) => {
        batches.push(batchItem.batchName);
      });
    });

    return batches;
  };

  const item_totals = (each) => {
    let batched = 0;
    let total = 0;
    each.orderItems.forEach((item) => {
      total += item.quantity;
      item.batch.forEach((batch) => {
        batched += batch.quantity;
      });
    });

    return { batched, total };
  };

  const setQtyColor = (batched, total) => {
    if (batched === total) return `color: var(--primary);`;
    if (batched > total) return `color: var(--warning);`;
    return "";
  };

  const customer_lookup = (each) => {
    const customer = customers.find(
      (customer) => customer._id === each.customerId
    );
    return customer;
  };

  return (
    <Card region="table" style={"box-shadow: var(--shadow); height: 79vh;"}>
      <Table
        header={`'batches batches items order order age date customer'`}
        style={`overflow-y: scroll;  `}
      >
        <StickyRow
          style={`background-color: var(--tertiary); gap: var(--margin);`}
        >
          <Cell region="batches" title="Batches" element="h4" />
          <Cell region="items" title="Items" element="h4" />
          <Cell region="order" title="Order #" element="h4" />
          <Cell region="age" title="Age" element="h4" />
          <Cell region="date" title="Date" element="h4" />
          <Cell region="customer" title="Customer Profile" element="h4" />
        </StickyRow>
        {Array.isArray(orders) &&
          orders?.map((order) => {
            const batches = match_batch(order);
            const { batched, total } = item_totals(order);
            return (
              <Row key={order._id} style={`gap: var(--margin);`}>
                <Cell
                  region="batches"
                  title={[...new Set(batches)].toString().replace(/,/g, ", ")}
                />
                <Cell
                  region="items"
                  title={`${batched}/${total}`}
                  textStyle={setQtyColor(batched, total)}
                />
                <Cell
                  region="order"
                  title={order.orderNumber}
                  hover={true}
                  onClick={async () => {
                    if (mode === "create") {
                      order_format_modal(order, customers, set);
                      await order_async_format_modal(order, set, post);
                    } else {
                      format_cue_table(order);
                    }
                  }}
                />
                <Cell region="age" title={PST_age(order.createdAt)} />
                <Cell region="date" title={date_trim(order.createdAt)} />
                <Cell
                  region="customer"
                  title={customer_lookup(order)?.profileName}
                />
              </Row>
            );
          })}
      </Table>
    </Card>
  );
});

export default OrderTable;
