import { useCallback, useReducer, useMemo, memo } from "react";
import { produce, current } from "immer";
import { getErrors, setErrors } from "./errors";
import path_retrieval from "./path_retrieval.js";

//Initials state must be created outside of the component calling the hook

//TODO:: change initial state to recieve any nested data structure and then flatten it, such that it can still be accessed
// by the same path in a dot notation format. exmaple: modal.nested.data.1.open

//TODO:: make _init overwrite previous initial values and make init add to the initial values

//TODO:: make reset() return any state matching the parent path to the initial state

//TODO:: allow any component calling usePage() to add to the initial state

//TODO:: change all data to be a memoized selector() function and not pure state. sel(), _(), $(), etc. page()?

//TODO:: during the flattening stage add extra properties like loading, error, etc. to the same path as the data

export default function usePageSetup() {
  const reserved = ["error", "errorFunc", "initial", "loading", "loadingFunc"];

  const traverse = (obj, path) => {
    if (!path) return obj;
    let clone = { ...obj };
    const route = path.split(".");
    for (let i = 0; i < route.length; i++) {
      if (clone[route[i]] === undefined) return undefined;
      clone = clone[route[i]];
    }
    return clone;
  };

  const recurse = (prop, path, payload) => {
    let clone = Array.isArray(prop) ? [...prop] : { ...prop };
    let pathArr = path.split(".");
    let key = pathArr.shift();

    if (pathArr.length === 0) {
      clone[key] = payload;
    } else {
      if (/\d+/.test(key)) {
        key = parseInt(key);
      }
      clone[key] = clone[key] || {};
      clone[key] = recurse(clone[key], pathArr.join("."), payload);
    }
    return clone;
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "set":
        const validPattern = /^[a-zA-Z0-9\[\]_.]+$/;
        if (!validPattern.test(action.name)) {
          console.error(`set(${action.name}) contains invalid characters.`);
          return;
        }

        if (action.name === "initial") {
          console.error(`cannot "set" initial, use init instead`);
          return;
        }
        if (action.name === "error") {
          console.error(
            `cannot "set" error, pass error object as a second argument to usePage or use errorInit`
          );
          return;
        }
        if (reserved.includes(action.name)) {
          console.error(
            `"${action.name}" is a reserved word, please use another name`
          );
          return;
        }
        return produce(state, (draft) => {
          const path = action.name.split(".");
          if (action.loading) {
            //check later for heavily nested properties
            draft.loading[action.name] = 0;
          }

          if (draft.errorFunc[action.name] !== undefined) {
            draft.error[action.name] = getErrors(
              draft.errorFunc[action.name],
              action.value
            );
          }
          let recurse = (path, draft, value) => {
            if (path.length === 1) {
              if (typeof value === "function") {
                draft[path[0]] = value();
                return;
              }
              draft[path[0]] = value;
            } else {
              const [nextKey, ...restOfPath] = path;
              if (!draft[nextKey]) draft[nextKey] = {};
              recurse(restOfPath, draft[nextKey], value);
            }
          };
          recurse(path, draft, action.value);
        });

      case "init":
        return produce(state, (draft) => {
          Object.keys(action.obj).forEach((key) => {
            if (reserved.includes(key)) {
              console.error(
                `"${key}" is a reserved word, please use another name`
              );
              return;
            }
          });
          Object.entries(action.obj).forEach(([key, value]) => {
            draft[key] = value;
            draft.initial[key] = value;
            draft.loading[key]
              ? (draft.loading[key] -= 1)
              : (draft.loading[key] = 0);
          });
          Object.entries(action.err).forEach(([key, value]) => {
            draft.errorFunc[key] = setErrors(value);
            draft.error[key] = false;
          });
        });

      case "reset":
        return produce(state, (draft) => {
          if (!action.name) return state.initial;
          if (action.name === "error") return state.initial;
          const initial = traverse(draft.initial, action.name);
          if (draft.errorFunc[action.name] !== undefined)
            draft.error[action.name] = false;
          return recurse(draft, action.name, initial);
        });

      case "inc":
        return produce(state, (draft) => {
          if (draft.errorFunc[action.name] !== undefined) {
            draft.error[action.name] = getErrors(
              draft.errorFunc[action.name],
              draft[action.name] + 1
            );
          }
          draft[action.name]++;
        });

      case "dec":
        return produce(state, (draft) => {
          if (draft.errorFunc[action.name] !== undefined) {
            draft.error[action.name] = getErrors(
              draft.errorFunc[action.name],
              draft[action.name] - 1
            );
          }
          draft[action.name]--;
        });

      case "errorCleanup":
        return produce(state, (draft) => {
          Object.keys(action.obj).forEach((key) => {
            delete draft.errorFunc[key];
            draft.error[key] = false;
          });
        });

      case "errorInit":
        return produce(state, (draft) => {
          Object.entries(action.obj).forEach(([key, value]) => {
            draft.errorFunc[key] = setErrors(value);
            draft.error[key] = false;
          });
        });

      case "errorCheck":
        return produce(state, (draft) => {
          if (action.name === undefined) {
            Object.keys(draft.errorFunc).forEach((key) => {
              let value = path_retrieval(key, draft);
              draft.error[key] = getErrors(draft.errorFunc[key], value);
            });
          }

          if (draft.errorFunc[action.name] !== undefined) {
            let check =
              action.value === undefined ? draft[action.name] : action.value;

            draft.error[action.name] = getErrors(
              draft.errorFunc[action.name],
              check
            );
          }
        });

      case "errorForce":
        if (action.name === undefined) {
          console.error(`property name is required for errorForce`);
          return state;
        }
        return produce(state, (draft) => {
          draft.error[action.name] = "error";
          if (action.value !== undefined) {
            draft.error[action.name] = action.value;
          }
        });

      case "toggle":
        return produce(state, (draft) => {
          const path = action.name.split(".");
          let recurse = (path, draft) => {
            if (path.length === 1) {
              // if (typeof draft[path[0]] !== "boolean") {
              //   console.error(`state.${action.name} is not a boolean value`);
              //   return state;
              // }
              draft[path[0]] = !draft[path[0]];
            } else {
              const [nextKey, ...restOfPath] = path;
              if (!draft[nextKey]) draft[nextKey] = {};
              recurse(restOfPath, draft[nextKey]);
            }
          };
          recurse(path, draft);
        });

      case "add":
        return produce(state, (draft) => {
          const addpath = action.name.split(".");
          let errorPath = [];
          let recurse = (path, draft, value) => {
            if (path.length === 1) {
              if (!Array.isArray(draft[path[0]])) {
                console.error(`state.${draft[path[0]]} is not an array`);
                return state;
              }
              if (action.shift) {
                draft[path[0]].unshift(value);
              } else {
                draft[path[0]].push(value);
              }
              errorPath = [...path];
            } else {
              let [nextKey, ...restOfPath] = path;
              if (Array.isArray(draft[nextKey])) {
                let int = parseInt(restOfPath[0]);
                restOfPath[0] = int;
              }
              if (!draft[nextKey]) draft[nextKey] = {};
              recurse(restOfPath, draft[nextKey], value);
            }
          };
          recurse(addpath, draft, action.value);

          if (state.errorFunc[action.name] !== undefined) {
            let targetArray = addpath.reduce((acc, key) => acc[key], draft);
            let err = getErrors(state.errorFunc[action.name], targetArray);
            if (!draft.error) draft.error = {};
            draft.error[action.name] = err;
          }
        });

      case "remove":
        if (isNaN(action.key) || action.key === "" || action.key === null) {
          console.error(`key must be a number`);
          return state;
        }
        return produce(state, (draft) => {
          const removepath = action.name.split(".");
          let errorPath = [];
          let recurse = (path, draft, value) => {
            if (path.length === 1) {
              if (!Array.isArray(draft[path[0]])) {
                console.error(`state.${draft[path[0]]} is not an array`);
                return state;
              }
              draft[path[0]] = draft[path[0]].filter(
                (item, index) => index !== parseInt(action.key)
              );
              errorPath = [...path];
            } else {
              let [nextKey, ...restOfPath] = path;
              if (Array.isArray(draft[nextKey])) {
                let int = parseInt(restOfPath[0]);
                restOfPath[0] = int;
              }
              if (!draft[nextKey]) draft[nextKey] = {};
              recurse(restOfPath, draft[nextKey], value);
            }
          };
          recurse(removepath, draft, action.value);

          if (state.errorFunc[action.name] !== undefined) {
            let targetArray = removepath.reduce((acc, key) => acc[key], draft);
            let err = getErrors(state.errorFunc[action.name], targetArray);
            if (!draft.error) draft.error = {};
            draft.error[action.name] = err;
          }
        });

      case "func":
        return produce(state, (draft) => {
          draft[action.name] = action.func;
        });

      case "loading":
        return produce(state, (draft) => {
          draft.loading[action.name]
            ? (draft.loading[action.name] += 1)
            : (draft.loading[action.name] = 1);
        });

      case "loadingFunc":
        return produce(state, (draft) => {
          draft.loadingFunc[action.name] = action.obj;
        });

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    error: {},
    errorFunc: {},
    initial: {},
    loading: {},
    loadingFunc: {},
  });
  /*
set wants a string as its first argument that names the state to be updated. 
It can be a nested state, but it must be a string with a format of "prop[0].nested.deeply"
The second argument is the value to be set.
*/
  const set = useCallback((name, value, loading) => {
    dispatch({ type: "set", name: name, value: value, loading: loading });
  }, []);

  const inc = useCallback((name, value) => {
    dispatch({ type: "inc", name: name, value: value });
  }, []);

  const dec = useCallback((name, value) => {
    dispatch({ type: "dec", name: name, value: value });
  }, []);

  const errorInit = useCallback((obj) => {
    dispatch({ type: "errorInit", obj: obj });
  }, []);

  const errorCleanup = useCallback((obj) => {
    dispatch({ type: "errorCleanup", obj: obj });
  }, []);
  /*
  if no arguments are provided to errorCheck it will check all stored error functions
  otherwise it will check the error function for the provided name
  */

  const errorCheck = useCallback(
    (name, value) => {
      dispatch({ type: "errorCheck", name: name, value: value });
      let fail = false;
      if (name && value) {
        fail = getErrors(state.errorFunc[name], value);
      } else if ((name && !value) || (!name && value)) {
        console.error(
          "errorCheck requires either no agruments or a name and a value"
        );
      } else {
        const check = Object.keys(state.errorFunc);
        const deepClone = JSON.parse(JSON.stringify(state));
        for (let i = 0; i < check.length; i++) {
          let key = check[i];
          let value = path_retrieval(key, deepClone);
          fail = getErrors(state.errorFunc[key], value);
          if (fail) {
            fail = true;
            break;
          }
        }
      }
      return fail;
    },
    [state]
  );
  /*
  errorForce by passing any error functions and forces an error message to be displayed
*/
  const errorForce = useCallback((name, value) => {
    dispatch({ type: "errorForce", name: name, value: value });
  }, []);

  const init = useCallback((obj, err, loading) => {
    err = err || {};
    typeof obj !== "object"
      ? console.error("init must be an object")
      : dispatch({ type: "init", obj: obj, err: err, loading: loading });
  }, []);

  const reset = useCallback((name) => {
    dispatch({ type: "reset", name: name });
  }, []);

  const toggle = useCallback((name) => {
    dispatch({ type: "toggle", name: name });
  }, []);

  const add = useCallback((name, value, shift) => {
    dispatch({ type: "add", name: name, value: value, shift: shift });
  }, []);

  const remove = useCallback((name, key) => {
    dispatch({ type: "remove", name: name, key: key });
  }, []);

  const loaded = useCallback(
    (name) => {
      return state.loading[name] === undefined ? false : !state.loading[name];
    },
    [state]
  );

  const loadingfunc = useCallback((name, obj) => {
    dispatch({ type: "loadingFunc", name: name, obj: obj });
  }, []);

  const reload = useCallback(
    async (name) => {
      try {
        let options = state.loadingFunc[name].options;
        if (options === undefined) console.error("options are required");
        if (options.type === undefined)
          console.error("option type is required");
        dispatch({ type: "loading", name: name });
        let data = await state.loadingFunc[name].func();
        if (data) {
          if (options.then !== undefined) {
            data = options.then(data);
          }
          switch (options.type) {
            case "set":
              set(name, data, true);
              break;
            case "init":
              init({ [name]: data }, {}, true);
              break;

            default:
              return data;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    [state]
  );

  /*
  If you need to modify the data after loading use the following pattern.
    1. await load("stateTarget", ()=> asyncFunc(), {type:"init",  
    then:(res) =>{
      res.somePropAdd = "new value"
      return res
      })
  Otherwise use the following pattern
    2.  await load("stateTarget", ()=> asyncFunc(), {type:"init"}) 
  Multiple promises can be tied to the same stateTarget.
  These patterns insure that reload can target the correct function and
  will properly guard against multiple calls to the same stateTarget.
    */
  const load = useCallback(async (name, asyncFunc, options) => {
    try {
      if (options === undefined) console.error("options are required");
      if (options.type === undefined) console.error("option type is required");
      dispatch({ type: "loading", name: name });
      let data = await asyncFunc();
      if (state.loadingFunc[name] === undefined) {
        loadingfunc(name, { func: asyncFunc, options: options });
      }
      if (data) {
        if (options.then !== undefined) {
          data = options.then(data);
        }
        switch (options.type) {
          case "set":
            set(name, data, true);
            break;
          case "init":
            init({ [name]: data }, {}, true);
            break;
          default:
            return data;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const func = useCallback((name, func) => {
    dispatch({ type: "func", name: name, func: func });
  }, []);

  return {
    set,
    inc,
    dec,
    init,
    memo,
    reset,
    errorCleanup,
    errorInit,
    errorCheck,
    errorForce,
    toggle,
    add,
    remove,
    loaded,
    load,
    reload,
    func,
    state,
  };
}
