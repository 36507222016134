import styled from "styled-components";

const Button = styled.button`
  grid-area: ${(props) => props.$region};
  margin-bottom: var(--mar);
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: var(--primary);
  &:hover {
    text-decoration: underline;
  }
  ${(props) => props.$more}
`;

export default function TextButton({
  children,
  style,
  title,
  onClick,
  region,
}) {
  return (
    <Button
      $more={style}
      $region={region}
      onClick={() => {
        onClick();
      }}
    >
      {title}
      {children}
    </Button>
  );
}
