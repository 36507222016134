import React from "react";
import DropDown from "../../../components/DropDown";
import Close from "../../../icons/Close";
import usePage from "../../../usePage/usePage";
import Card from "../../../components/Card";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import ButtonIcon from "../../../components/ButtonIcon";
import Content from "../../../components/Content";

function ShipstationStores() {
  const { state, add, remove, set } = usePage();
  const { modal_provider_data, shipstation } = state;

  return (
    <Card
      region="shipstation"
      regions={`'stores button button' 'store store store'`}
      style={`padding: 0; margin: 0;`}
    >
      <Text region="stores" title="ShipStation Stores" element="h3" />
      <Button
        region="button"
        title="+ Store"
        onClick={() => {
          add("modal_provider_data.shipstationStoreIds", {
            storeId: shipstation[0].storeId,
            storeName: shipstation[0].storeName,
          });
        }}
      />
      <Content
        region="store"
        regions={`'close num dropdown'`}
        style={`flex-direction: column;`}
      >
        {modal_provider_data.shipstationStoreIds?.map((store, index) => {
          return (
            <Content
              key={store.storeId + index}
              style={`align-items: center; & > * {margin-right: var(--mar) !important;};`}
            >
              <ButtonIcon
                onClick={() =>
                  remove("modal_provider_data.shipstationStoreIds", index)
                }
              >
                <Close />
              </ButtonIcon>
              <Text title={`${index + 1}.`} style={`margin-bottom: 0;`} />
              <DropDown
                title={modal_provider_data.shipstationStoreIds[index].storeName}
              >
                {shipstation.map((s) => {
                  return modal_provider_data.shipstationStoreIds[index]
                    .storeId === s.storeId ? (
                    <React.Fragment key={s.storeId}></React.Fragment>
                  ) : (
                    <DropDown.Options
                      className="dropdown-option"
                      key={s.storeId}
                      onClick={() => {
                        set(
                          `modal_provider_data.shipstationStoreIds.${index}`,
                          {
                            storeId: s.storeId,
                            storeName: s.storeName,
                          }
                        );
                      }}
                    >
                      {s.storeName}
                    </DropDown.Options>
                  );
                })}
              </DropDown>
            </Content>
          );
        })}
      </Content>
    </Card>
  );
}
export default ShipstationStores;
