import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./calendar.css";
import "./modal.css";
import "./flyin.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { UserProvider } from "./context/User/UserProvider";
import { GlobalAlertProvider } from "./context/GlobalAlert/GlobalAlertProvider";
import { PageProvider } from "./usePage/usePageProvider";

//TODO:: research react-router-dom 6.4 createBrowserRouter api

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserProvider>
      <PageProvider>
        <GlobalAlertProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </BrowserRouter>
        </GlobalAlertProvider>
      </PageProvider>
    </UserProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
