import DropDown from "../../components/DropDown";
import NumberInput from "../../components/NumberInput";
import Close from "../../icons/Close";
import trunc_str from "../../utils/trunc_str";
import usePage from "../../usePage/usePage";
import { useEffect } from "react";

import Table, { Row, Cell, DynamicCell } from "../../components/Table";
import Text from "../../components/Text";
import ButtonIcon from "../../components/ButtonIcon";

export default function AddTask() {
  const { state, set, remove, errorInit } = usePage();
  const { m2_products, m2_items, error } = state;

  //ATTACH ERRORS
  useEffect(() => {
    m2_products.map((product, index) => {
      errorInit({
        [`m2_products.${index}.quantity`]: {
          minValue: [1, "Quantity is required"],
        },
        [`m2_products.${index}.sku`]: {
          minLength: [1, "Please select a product"],
        },
        [`m2_products.${index}.step`]: {
          minLength: [1, "Please select a step"],
        },
      });
    });
  }, [m2_products]);

  //COMPONENT
  return (
    <Table header={`'qty product product product step step step close'`}>
      {m2_products.map((product, index) => {
        return (
          <Row
            key={`${product.productId}${index}`}
            last={m2_products.length === index + 1}
          >
            <Cell region="qty">
              <Text title="Qty:" />
              <NumberInput
                value={product.quantity}
                error={error[`m2_products.${index}.quantity`]}
                onChange={(e) => {
                  set(`m2_products.${index}.quantity`, e.target.value);
                }}
              />
              <Text title={`/${product.outOf}`} />
            </Cell>
            <DynamicCell region="product">
              <Text title="Product:" />
              <DropDown
                title={
                  m2_products[index].product === ""
                    ? "Item"
                    : m2_products[index].product
                }
                error={error[`m2_products.${index}.sku`]}
              >
                {m2_items.map((item) => {
                  return (
                    <DropDown.Options
                      key={`${item._id}${index}`}
                      onClick={() => {
                        set(`m2_products.${index}.product`, item.name);
                        set(`m2_products.${index}.productId`, item.productId);
                        set(`m2_products.${index}.sku`, item.sku);
                        set(`m2_products.${index}.skuId`, item.skuId);
                        set(`m2_products.${index}.quantity`, item.quantity);
                        set(`m2_products.${index}.outOf`, item.quantity);
                        set(`m2_products.${index}.steps`, item.steps);
                      }}
                    >
                      <div className="bar">
                        <div>Product:</div>
                        <b className="item">{item.name}</b>
                        <div>Sku:</div>
                        <b className="item">{item.sku}</b>
                      </div>
                    </DropDown.Options>
                  );
                })}
              </DropDown>
            </DynamicCell>
            <DynamicCell region="step">
              <Text title="Step:" />
              <DropDown
                title={product.step === "" ? "Step" : trunc_str(product.step)}
                value={product.step}
                error={state.error[`m2_products.${index}.step`]}
              >
                {product.steps.map((step, i) => {
                  return step.constant === 0 ? (
                    <DropDown.Options
                      key={`${step._id}${i}`}
                      onClick={() => {
                        set(`m2_products.${index}.stepId`, step._id);
                        set(
                          `m2_products.${index}.step`,
                          `${i + 1}. ${step.name}`
                        );
                        set(`m2_products.${index}.desc`, step.desc);
                      }}
                    >
                      <div className="bar">
                        <b className="item">{`${i + 1}.`}</b>
                        <div>Name:</div>
                        <b className="item">{trunc_str(step.name)}</b>
                        <div>Desc:</div>
                        <b className="item">{trunc_str(step.desc)}</b>
                      </div>
                    </DropDown.Options>
                  ) : (
                    <></>
                  );
                })}
              </DropDown>
            </DynamicCell>
            <Cell region="close" style={{ justifySelf: "right" }}>
              <ButtonIcon
                onClick={() => {
                  remove(`m2_products`, index);
                }}
              >
                <Close />
              </ButtonIcon>
            </Cell>
          </Row>
        );
      })}
    </Table>
  );
}
