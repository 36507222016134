import styled from "styled-components";

const SpinnerStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ $more }) => $more}
`;

function Spinner({ style }) {
  return (
    <SpinnerStyle $more={style}>
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </SpinnerStyle>
  );
}

export default Spinner;
