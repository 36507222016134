const Customer = (order, customers) => {
  const customer = customers.find(
    (customer) => customer._id === order.customerId
  );

  return {
    _id: order.customerId,
    profileName: customer.profileName,
    addressBook: customer.addressBook,
    provider: order.provider,
  };
};

const OrderInformation = (order) => {
  return {
    lock: true,
    _id: order._id,
    orderNumber: order.orderNumber,
    provider: {
      name: order.provider.name,
      data: order.provider?.data ?? {},
    },
    customerId: order.customerId,
    orderDate: order.createdAt,
    createdAt: order.createdAt,
    status: order.status,
  };
};

const GeneralInformation = (order) => {
  return {
    lock: true,
    firstName: order.customer.firstName,
    lastName: order.customer.lastName,
    company: order?.customer.company ?? "",
    phone: order.customer?.phone ?? "",
    email: order?.customer.customerEmail ?? "",
  };
};

const ShippingInformation = (order) => {
  return {
    lock: true,
    address1: order.customer.shippingAddress.address1,
    address2: order?.customer.shippingAddress.address2 ?? "",
    address3: order?.customer.shippingAddress.address3 ?? "",
    city: order.customer.shippingAddress.city,
    state: order.customer.shippingAddress.state,
    postalCode: order.customer.shippingAddress.postalCode,
    country: order.customer.shippingAddress.country,
    residential: order.customer.shippingAddress.residential,
  };
};

const BillingInformation = (order) => {
  return {
    lock: true,
    address1: order.customer.billingAddress.address1,
    address2: order?.customer.billingAddress.address2 ?? "",
    address3: order?.customer.billingAddress.address3 ?? "",
    city: order.customer.billingAddress.city,
    state: order.customer.billingAddress.state,
    postalCode: order.customer.billingAddress.postalCode,
    country: order.customer.billingAddress.country,
    residential: order.customer.billingAddress.residential,
  };
};

const order_format_modal = async (order, customers, set) => {
  let orderInformation = {};
  let currentCustomer = {};
  let general = {};
  let shipping = {};
  let billing = {};

  currentCustomer = Customer(order, customers);
  orderInformation = OrderInformation(order);
  general = GeneralInformation(order);
  shipping = ShippingInformation(order);
  billing = BillingInformation(order);

  if (order.provider.source === "shipstation") {
    currentCustomer.provider = {
      ...currentCustomer.provider,
      data: {
        ...currentCustomer.provider.data,
        selectedStore: `${order.provider.data.selectedStore}`,
        shipstationOrderId: `${order.provider.data.shipStationOrderId}`,
      },
    };
  }

  set("modal.currentCustomer", currentCustomer);
  set("modal.orderInformation", orderInformation);
  set("modal.general", general);
  set("modal.shippingAddress", shipping);
  set("modal.billingAddress", billing);
  set("modal.general.hidden", true);
  set("modal.shippingAddress.hidden", true);
  set("modal.billingAddress.hidden", true);

  set("mode", "edit");
  set("modal.open", true);
};

export default order_format_modal;
