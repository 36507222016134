import styled from "styled-components";
import React, { createContext, useContext, useState } from "react";
import Text from "./Text";
import ErrorBox from "./ErrorBox";
import DropDown from "./DropDown";

const TableContext = createContext();
const SubTableContext = createContext();
export const DynamicCellContext = createContext({
  setAllowOverflow: () => {},
  allowOverflow: false,
});

const StyleTabled = styled.div`
  position: relative;
  grid-area: ${(props) => props.$region};
  width: 100%;
  height: 100%;
  border: 1px solid var(--secondary);
  ${({ $more }) => $more}
`;

const Table = ({ children, header, style, region }) => {
  const $header = header;
  const childrenArray = React.Children.toArray(children);
  const total = childrenArray.length;

  return (
    <TableContext.Provider value={{ $header }}>
      <StyleTabled $region={region} $more={style}>
        {childrenArray.map((child, index) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              index: index,
              total: total,
            });
          }
          return child;
        })}
      </StyleTabled>
    </TableContext.Provider>
  );
};

const SubTableStyle = styled.div`
  position: relative;
  overflow: visible;
  grid-area: ${(props) => props.$region};
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ $more }) => $more}
`;

export function SubTable({ children, header, style, region, className }) {
  const $header = header;
  return (
    <SubTableContext.Provider value={{ $header }}>
      <SubTableStyle className={className} $more={style} $region={region}>
        {children}
      </SubTableStyle>
    </SubTableContext.Provider>
  );
}

const StickyRowStyle = styled.div`
  top: 0;
  position: sticky;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  align-items: center;
  border-bottom: 1px solid var(--secondary);
  background-color: var(--background);
  &:hover {
    cursor: ${(props) => (props.$hover ? "pointer" : "default")};
    background-color: ${(props) => (props.$hover ? "var(--primary)" : "")};
  }
  ${(props) => props.$more}
`;

export function StickyRow({ children, style, hover, onClick = () => {} }) {
  const mainHeader = useContext(TableContext);
  const subHeader = useContext(SubTableContext);

  const header = subHeader ? subHeader.$header : mainHeader.$header;
  const mergedStyle = {
    gridTemplateAreas: header,
  };

  return (
    <StickyRowStyle
      style={mergedStyle}
      $more={style}
      $hover={hover}
      onClick={(e) => {
        onClick(e);
      }}
    >
      {children}
    </StickyRowStyle>
  );
}

const RowStyle = styled.div`
  position: relative;
  overflow: visible;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  align-items: center;
  border-top: ${(props) =>
    props.$first ? "none" : "1px solid var(--secondary)"};
  &:hover {
    cursor: ${(props) => (props.$hover ? "pointer" : "default")};
    background-color: ${(props) => (props.$hover ? "var(--primary)" : "")};
  }
  ${(props) => props.$more}
`;

export function Row({
  children,
  style,
  total,
  index,
  hover,
  first,
  last,
  onClick = () => {},
}) {
  const mainHeader = useContext(TableContext);
  const subHeader = useContext(SubTableContext);

  const header = subHeader ? subHeader.$header : mainHeader.$header;
  const mergedStyle = {
    gridTemplateAreas: header,
  };

  return (
    <RowStyle
      style={mergedStyle}
      $first={(index === 0) | first}
      $last={(index === total - 1) | last}
      $more={style}
      $hover={hover}
      onClick={(e) => {
        onClick(e);
      }}
    >
      {children}
    </RowStyle>
  );
}

const InputCellContainer = styled.div`
  grid-area: ${(props) => props.$region};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: none;
  ${(props) => (props.$last ? "" : "border-right: 1px solid var(--secondary);")}
  ${(props) => props.$focus && "outline: 2px solid black; z-index: 1;"}
  ${(props) => props.$more}
`;

const InputCellStyle = styled.input`
  box-sizing: border-box;
  outline: none;
  font-size: 11pt;
  padding: 10px;
  width: 100%;
  height: 100%;
  color: var(--text);
  border: none;
  &:hover {
    cursor: ${(props) => (props.$hover ? "pointer" : "")};
    & > * {
      color: ${(props) => (props.$hover ? "var(--primary)" : "var(--text)")};
    }
  }
`;

export function InputCell({
  style,
  region,
  value,
  lock = false,
  last = false,
  hover = false,
  error = false,
  type = "text",
  children,
  onChange = () => {},
  onClick = () => {},
  onKeyDown = () => {},
}) {
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <InputCellContainer
      $hover={hover}
      $region={region}
      $last={last}
      $more={style}
      $focus={hasFocus}
    >
      <ErrorBox error={error} style={`height: 100%; width:100%;`}>
        <InputCellStyle
          value={value}
          disabled={lock}
          onFocus={() => {
            setHasFocus(true);
          }}
          onBlur={() => {
            setHasFocus(false);
          }}
          onChange={(e) => {
            if (type === "number") {
              const regex = /^[0-9.]*$/;
              const isNumeric = regex.test(e.target.value);
              if (isNumeric) onChange(e);
            }
            if (type === "text") {
              onChange(e);
            }
          }}
          onClick={(e) => {
            onClick(e);
          }}
          onKeyDown={(e) => {
            onKeyDown(e);
          }}
        />
      </ErrorBox>
      {children}
    </InputCellContainer>
  );
}

const CellStyle = styled.div`
  grid-area: ${(props) => props.$region};
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: 100%;
  ${(props) => (props.$last ? "" : "border-right: 1px solid var(--secondary);")}
  &:hover {
    cursor: ${(props) => (props.$hover ? "pointer" : "")};
    & > * {
      color: ${(props) => (props.$hover ? "var(--primary)" : "")};
    }
  }
  ${(props) => props.$more}
`;

export function Cell({
  children,
  style,
  region,
  title,
  last = false,
  hover = false,
  element = "p",
  textStyle = "",
  onClick = () => {},
  onKeyDown = () => {},
}) {
  return (
    <CellStyle
      $hover={hover}
      $region={region}
      $last={last}
      $more={style}
      onClick={(e) => {
        onClick(e);
      }}
      onKeyDown={(e) => {
        onKeyDown(e);
      }}
    >
      {children}
      {title && (
        <Text
          title={title}
          element={element}
          style={`margin: 0; ${textStyle}`}
        />
      )}
    </CellStyle>
  );
}

const DynamicCellStyle = styled.div`
  grid-area: ${(props) => props.$region};
  position: relative;
  display: flex;
  white-space: nowrap;
  overflow: ${(props) => (props.$allowOverflow ? "visible" : "hidden")};
  text-overflow: ellipsis;
  height: 100%;
  border-right: 1px solid var(--secondary);
  align-content: center;
  padding: 0.5rem;
  ${(props) => props.$more}
`;

export function DynamicCell({ children, style, region }) {
  const [allowOverflow, setAllowOverflow] = useState(false);
  return (
    <DynamicCellContext.Provider
      value={{
        setAllowOverflow: setAllowOverflow,
        allowOverflow: allowOverflow,
      }}
    >
      <DynamicCellStyle
        $region={region}
        $more={style}
        $allowOverflow={allowOverflow}
      >
        {children}
      </DynamicCellStyle>
    </DynamicCellContext.Provider>
  );
}

Table.Row = Row;
Table.Cell = Cell;
Table.DynamicCell = DynamicCell;
export default Table;
