import styled from "styled-components";
import Card from "../../components/Card";
import Text from "../../components/Text";
import { Calendar } from "react-calendar";
import usePage from "../../usePage/usePage";
import Ellipse from "../../icons/Ellipse";
import useServer from "../../hooks/useServer";
import { useEffect } from "react";

const Tile = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function EmployeeCalendar({ employee }) {
  const { post } = useServer();
  const { state, loaded, set, load } = usePage();
  const { timesheets, logs, selectedMonth } = state;
  const loading = !loaded("logs") && !loaded("timesheets"); //loading is at the top so this runs before the component

  console.log(logs);

  //LOAD LOGS
  useEffect(() => {
    async function effect() {
      await load(
        "logs",
        () =>
          post("/employees/logs", {
            empId: employee._id,
            date: selectedMonth.toISOString(),
          }),
        {
          type: "init",
        }
      );
    }
    effect();
  }, [load, post, selectedMonth]);

  //LOAD TIMESHEETS
  useEffect(() => {
    async function effect() {
      await load(
        "timesheets",
        () =>
          post("/employees/timesheets", {
            empId: employee._id,
            date: selectedMonth.toISOString(),
          }),
        {
          type: "init",
        }
      );
    }
    effect();
  }, [load, post, selectedMonth]);

  const editHours = (date) => {
    const day = new Date(date);
    day.setHours(0, 0, 0, 0);

    const dayKey = day.toISOString().split("T")[0];
    const customer = logs[0]?.logs[dayKey] ?? {};

    Object.entries(timesheets[0].days).forEach(([key, value]) => {
      const { earliestLogin, latestLogout } = value;
      const login = new Date(earliestLogin.date);
      login.setHours(0, 0, 0, 0);
      if (day.getTime() === login.getTime()) {
        set("m3_clockin_id", earliestLogin._id);
        set("m3_clockin", format(earliestLogin.date));
        set("m3_clockout_id", latestLogout._id);
        set("m3_clockout", format(latestLogout.date));
      }
    });
    set(
      "m3_title",
      `${employee.firstName} ${employee.lastName} ${
        day.toISOString().split("T")[0]
      }`
    );
    set("m3_logs", customer);
    set("m3_employee", `${employee.firstName} ${employee.lastName}`);
    set("m3_empId", employee._id);
    set("m3_date", day.toISOString().split("T")[0]);
    set("m3_open", true);
  };

  //Format Login/Logout
  const format = (data) => {
    const date = new Date(data);
    const local = date.toLocaleDateString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
    });
    let string = local.split(",")[1].trim();
    if (string[0] === "0") {
      string = string.slice(1);
    }
    return string;
  };

  const tileContent = ({ date }) => {
    const day = new Date(date);
    day.setHours(0, 0, 0, 0);

    //Find Total Hours
    let clockin = "";
    let clockout = "";
    let display = 0;
    if (loading) return <Ellipse />;
    /*There is some bullshit going on with (!loaded("logs") && !loaded("timesheets")) returning a 
    a true value even though one of the two is loading. 
    */
    if (!timesheets || !logs) return null;
    Object.entries(timesheets[0].days).forEach(([key, value]) => {
      const { earliestLogin, latestLogout, hours } = value;
      const login = new Date(earliestLogin.date);
      login.setHours(0, 0, 0, 0);
      if (day.getTime() === login.getTime()) {
        clockin = format(earliestLogin.date);
        clockout = format(latestLogout.date);
        display = hours;
      }
    });

    //Find Total Production
    let display3 = 0;
    //Find Logged Work
    let display2 = 0;
    if (loading) return <Ellipse />;
    Object.entries(logs[0].logs).forEach(([key, value]) => {
      //key is in the format of "YYYY-MM-DD"
      const [year, month, lday] = key.split("-").map(Number);
      // Create a new Date object using local timezone
      // Note: month is 0-indexed in JavaScript Date, so we subtract 1
      const logDay = new Date(year, month - 1, lday);
      if (day.getTime() === logDay.getTime()) {
        display2 = value.hours;
        display3 = value.total;
      }
    });

    return display === 0 ? null : (
      <Tile>
        <Text
          title={`${clockin} - ${clockout}`}
          style={`font-size: 6pt; &:hover {cursor: pointer; color: var(--primary)}`}
          onClick={() => {
            editHours(date);
            console.log("click");
          }}
        />
        <Text
          title={`${display?.toFixed(2) ?? "N/A"} / ${
            display2?.toFixed(2) ?? "N/A"
          }`}
        />

        <Text
          title={`Efficiency: ${((display2 / display) * 100).toFixed(0)}%`}
        />
        <Text title={`Total Steps: ${display3}`} style={`font-size: 6pt;`} />
      </Tile>
    );
  };

  return (
    <Card>
      <Calendar
        tileContent={tileContent}
        onActiveStartDateChange={(action) => {
          set("selectedMonth", new Date(action.activeStartDate));
        }}
        onClickDay={(date) => {
          editHours(date);
        }}
      ></Calendar>
    </Card>
  );
}
