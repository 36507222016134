import Card from "../../components/Card";
import Text from "../../components/Text";
import usePage from "../../usePage/usePage";
import TextButton from "../../components/TextButton";
import Table, { Row, Cell } from "../../components/Table";
export default function ProcessFix() {
  const { state, set } = usePage();
  const { processLogs, modal } = state;

  //FUNCTIONS
  const formatDate = (date) => {
    return new Date(date).toLocaleString();
  };

  const format = (data) => {
    const date = new Date(data);
    const local = date.toLocaleDateString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
    });
    let string = local.split(",")[1].trim();
    if (string[0] === "0") {
      string = string.slice(1);
    }
    return string;
  };

  //COMPONENT
  return (
    <Card regions={`'title' 'table'`} style={`box-shadow: var(--shadow);`}>
      <Text region="title" title={"Unfinished Process Logs"} />
      <Table
        region="table"
        header={`'customer employee process start end actions'
        `}
      >
        <Row>
          <Cell region="customer" title="Customer" element="b" />

          <Cell region="employee" title="Employee" element="b" />
          <Cell region="process" title="Process" element="b" />
          <Cell region="start" title="Start" element="b" />
          <Cell region="end" title="End" element="b" />
        </Row>
        {processLogs?.map((log) => {
          return (
            <Row key={`log${log._id}`}>
              <Cell region="customer">{log.customer}</Cell>
              <Cell region="employee">{`${log.empFirstName} ${log.empLastName}`}</Cell>
              <Cell region="process">{log.processName}</Cell>
              <Cell region="start">{formatDate(log.start)}</Cell>

              <Cell region="end">
                <TextButton
                  title="Set End"
                  onClick={() => {
                    set("modal.open", true);
                    set("modal.processLogId", log._id);
                    set(
                      "modal.title",
                      `${log.customer}'s ${log.processName} process`
                    );
                    set(
                      "modal.msg",
                      `Started ${log.processName} on ${formatDate(
                        log.start
                      )} ended at:`
                    );
                    set("modal.date", log.start.split("T")[0]);
                    set("modal.end", format(log.start));
                  }}
                />
              </Cell>
            </Row>
          );
        })}
      </Table>
    </Card>
  );
}
