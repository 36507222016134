import Card from "../../components/Card";
import Text from "../../components/Text";
import Content from "../../components/Content";
import TextInput from "../../components/TextInput";
import usePage from "../../usePage/usePage";
import ButtonPrimary from "../../components/ButtonPrimary";
import useServer from "../../hooks/useServer";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
import first_char_cap from "../../utils/first_char_cap";
import TextButton from "../../components/TextButton";
import Table, { Row, Cell } from "../../components/Table";

const timeRegex = new RegExp(
  "^(?:(?:[01]\\d|2[0-3]):[0-5]\\d|(?:0?[1-9]|1[0-2]):[0-5]\\d(?:\\s?[AaPp][Mm]))$"
);

const errors = {
  m3_clockin: {
    minLength: [1, "Clock-In is required"],
    permittedChars: [
      timeRegex,
      "Time must be in 24 hour time or 12 hour with AM or PM",
    ],
  },
  m3_clockout: {
    minLength: [1, "Clock-Out is required"],
    permittedChars: [
      timeRegex,
      "Time must be in 24 hour time or 12 hour with AM or PM",
    ],
  },
};

export default function EditHours() {
  const { state, set, errorCheck, reset, load, toggle } = usePage({}, errors);
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();
  const {
    selectedMonth,
    m3_clockin_id,
    m3_clockin,
    m3_clockout_id,
    m3_clockout,
    m3_employee,
    m3_empId,
    m3_date,
    m3_logs,
    m3_edit,
  } = state;

  const is12Hour = (timeStr) => {
    const is12HourFormat = /(am|pm)/i.test(timeStr);

    const time24 = is12HourFormat ? convertTo24Hour(timeStr) : timeStr;
    const dateTimeString = `${m3_date}T${time24}`;
    return new Date(dateTimeString);
  };

  const convertTo24Hour = (timeStr) => {
    const time = timeStr.substring(0, timeStr.length - 2).trim();
    const modifier = timeStr.substring(timeStr.length - 2);
    let [hours, minutes] = time.split(":");

    if (modifier === "PM" || modifier === "pm") {
      hours = parseInt(hours, 10) + 12;
    }
    if (parseInt(hours, 10) < 10) {
      hours = `0${hours}`;
    }
    if (hours === "12") {
      hours = "00";
    }
    return `${hours}:${minutes}`;
  };

  const editHours = async () => {
    if (errorCheck()) return;
    let clockInCheck = is12Hour(m3_clockin);
    let clockOutcheck = is12Hour(m3_clockout);
    const clockInISO = clockInCheck.toISOString();
    const clockOutISO = clockOutcheck.toISOString();

    const data = await post("employee/edit/hours", {
      empId: m3_empId,
      clockin: { _id: m3_clockin_id, date: clockInISO },
      clockout: { _id: m3_clockout_id, date: clockOutISO },
    });
    if (data) {
      setAlert({ msg: data.msg, type: data.type });
      reset("m3_clockin_id");
      reset("m3_clockin");
      reset("m3_clockout_id");
      reset("m3_clockout");
      reset("m3_date");
      reset("m3_open");
      await load(
        "timesheets",
        () =>
          post("/employees/timesheets", {
            empId: m3_empId,
            date: selectedMonth.toISOString(),
          }),
        {
          type: "init",
        }
      );
    }
  };

  return (
    <Card
      regions={`
    'title title'
    'in out'
    'labor labor'
    'button button'
    `}
    >
      <TextButton
        region="title"
        title="Edit Hours"
        onClick={() => {
          toggle("m3_edit");
        }}
      />
      <Content region="in">
        <Text title="Clock-In" element="h4" />
        <TextInput
          lock={!m3_edit}
          label="Clock In"
          value={m3_clockin}
          error={state.error["m3_clockin"]}
          onChange={(e) => {
            set("m3_clockin", e.target.value);
          }}
        />
      </Content>
      <Content region="out">
        <Text title="Clock-Out" element="h4" />
        <TextInput
          lock={!m3_edit}
          label="Clock Out"
          value={m3_clockout}
          error={state.error["m3_clockout"]}
          onChange={(e) => {
            set("m3_clockout", e.target.value);
          }}
        />
      </Content>
      <Table
        region="labor"
        header={`'customer product product step step count'`}
      >
        <Row>
          <Cell region="customer" title="Customer" />
          <Cell region="product" title="Product" />
          <Cell region="step" title="Step" />
          <Cell region="count" title="Count" />
        </Row>
        {Object.values(m3_logs?.customer ?? {}).map((customer, index) => {
          return (
            <>
              <Row key={`log-${index}`}>
                <Cell region="customer">{customer.name}</Cell>
                <Cell region="product" />
                <Cell region="step" />
                <Cell region="count" />
              </Row>
              {Object.values(customer.products).map((product, i) => {
                return (
                  <>
                    <Row key={`logs-${i}`}>
                      <Cell region="customer" />
                      <Cell region="product">{product.product}</Cell>
                      <Cell region="step" />
                      <Cell region="count" />
                    </Row>
                    {Object.values(product.steps).map((step, j) => {
                      return (
                        <Row key={`step-${j}`}>
                          <Cell region="customer" />
                          <Cell region="product" />
                          <Cell region="step">{step.name}</Cell>
                          <Cell region="count">{step.total}</Cell>
                        </Row>
                      );
                    })}
                  </>
                );
              })}
            </>
          );
        })}
      </Table>
      {/* <Content region="labor" style={"flex-direction: column;"}>
        <Text title="Day's Totals" element="h4" />
        {format_logs(m3_logs).map((log, i) => {
          return <Text key={`log-${i}`} title={log} />;
        })}
      </Content> */}
      {m3_edit && (
        <ButtonPrimary
          region="button"
          title="Update"
          style={`justify-self: end;`}
          onClick={editHours}
        />
      )}
    </Card>
  );
}
