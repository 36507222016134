import usePage from "../../usePage/usePage";
import Table, { Row, Cell } from "../../components/Table";
import ButtonPrimary from "../../components/ButtonPrimary";
import ButtonLink from "../../components/ButtonLink";
import TextButton from "../../components/TextButton";
import Card from "../../components/Card";
import React from "react";
import Text from "../../components/Text";

const filter_search = (search, data) => {
  const filter = new RegExp(search, "i");
  return filter.test(data);
};

function truncateUrl(url) {
  const match = url.match(/^(https?:\/\/[^\/]+)/);
  return match ? match[0] : url;
}

function Materials() {
  const { state, set } = usePage();
  const { materials, search } = state;
  return (
    <Card region="body" regions={`'button' 'table'`}>
      <ButtonPrimary
        region="button"
        title="New Material"
        onClick={() => {
          set("open", true);
        }}
      />
      <Table
        region="
        table"
        header={`'material material nickname nickname quantity price supplier supplier link link'`}
      >
        <Row>
          <Cell
            region="material / material / nickname / nickname"
            title="Material"
            style={{ fontWeight: "bold" }}
          />
          <Cell
            region="quantity"
            title="Quantity"
            style={{ fontWeight: "bold" }}
          />
          <Cell region="price" title="Price" style={{ fontWeight: "bold" }} />
          <Cell
            region="supplier"
            title="Supplier"
            style={{ fontWeight: "bold" }}
          />
          <Cell region="link" title="Link" style={{ fontWeight: "bold" }} />
        </Row>
        {materials.map((material, index) => {
          const color = index % 2 ? "var(--tertiary)" : "";
          return filter_search(search, material.name) ? (
            <Row
              style={{ backgroundColor: color }}
              key={material._id}
              last={materials.length === index + 1}
            >
              <Cell region="material">
                <TextButton
                  title="Edit"
                  style={{ paddingRight: "var(--mar)" }}
                  onClick={() => {
                    set("open", true);
                    set("mode", "edit");
                    set("_id", material._id);
                    set("name", material.name);
                    set("nickName", material.nickName);
                    set("description", material.description);
                    set("amount", material.amount);
                    set("type.selected", material.type);
                    set("units.selected", material.units);
                    set("cost", material.cost);
                    set("supplier", material.supplier);
                    set("website", material.website);
                  }}
                />
                <Text title={material.name} style={{ margin: 0, padding: 0 }} />
              </Cell>
              <Cell region="nickname" title={material.nickName} />
              <Cell
                region="quantity"
                title={`${material.amount} ${material.units}`}
              />
              <Cell region="price" title={`$${material.cost.toFixed(2)}`} />
              <Cell region="supplier" title={`${material.supplier}`} />
              <Cell region="link">
                <ButtonLink
                  title={truncateUrl(material.website)}
                  href={material.website}
                  newTab={true}
                />
              </Cell>
            </Row>
          ) : (
            <React.Fragment key={material._id}></React.Fragment>
          );
        })}
      </Table>
    </Card>
  );
}
export default Materials;
