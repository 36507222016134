export default function path_retrieval(root, from) {
  let value;
  const pathArray = root.split(".");
  let recurse = (path, state) => {
    if (path.length === 1) {
      value = state[path[0]];
    } else {
      const [nextKey, ...restOfPath] = path;
      if (!state[nextKey]) state[nextKey] = {};
      recurse(restOfPath, state[nextKey]);
    }
  };
  recurse(pathArray, from);
  return value;
}
