import TextInput from "./TextInput";
import Lock from "../icons/Lock";
import Unlock from "../icons/Unlock";
import CountryCodeDropDownCopy from "./CountryCodeDropDown";
import Card from "./Card";
import Text from "./Text";
import Content from "./Content";
import ButtonIcon from "./ButtonIcon";
import TextButton from "./TextButton";

function AddressBlock(props) {
  const {
    title,
    copyButton,
    address1,
    address2,
    address3,
    city,
    state,
    country,
    postalCode,
    lock,
    onLock,
  } = props;
  return (
    <Card
      regions={`
    ' . title title lock .'
    ' . address1 input1 input1 .'
    ' . address2 input2 input2 .'
    ' . address3 input3 input3 .'
    ' . location city state .'
    ' . country dropdown dropdown .'
    ' . postalcode input4 input4 .'
    `}
    >
      <Content
        region="title"
        style={`flex-direction: column; align-items: flex-start;`}
      >
        <Text title={title} element="h3" />
        {!copyButton.hidden && (
          <TextButton title={copyButton.text} onClick={copyButton.onClick} />
        )}
      </Content>
      <ButtonIcon region="lock" onClick={onLock} style={`justify-self: end`}>
        {lock ? <Lock fill="var(--text)" /> : <Unlock />}
      </ButtonIcon>
      <Text region="address1" title="Address Line 1" element="h5" />
      <TextInput
        region="input1"
        value={address1.value}
        onChange={address1.onChange}
        placeholder={address1?.placeholder ?? "Address Line 1"}
        required={address1?.required ?? false}
        error={address1.error}
        lock={lock}
      />
      <Text region="address2" title="Address Line 2" element="h5" />
      <TextInput
        region="input2"
        value={address2.value}
        onChange={address2.onChange}
        placeholder={address2?.placeholder ?? "Address Line 2"}
        required={address2?.required ?? false}
        error={address2.error}
        lock={lock}
      />
      <Text region="address3" title="Address Line 3" element="h5" />
      <TextInput
        region="input3"
        value={address3.value}
        onChange={address3.onChange}
        placeholder={address3?.placeholder ?? "Address Line 3"}
        required={address3?.required ?? false}
        error={address3.error}
        lock={lock}
      />
      <Text region="location" title="Location" element="h5" />
      <TextInput
        region="city"
        title="City"
        value={city.value}
        onChange={city.onChange}
        placeholder="City"
        required={city?.required ?? false}
        error={city.error}
        lock={lock}
      />
      <TextInput
        region="state"
        title="State"
        value={state.value}
        onChange={state.onChange}
        placeholder="State"
        required={state?.required ?? false}
        error={state.error}
        lock={lock}
      />
      <Text region="country" title="Country" element="h5" />
      <CountryCodeDropDownCopy
        region="dropdown"
        value={country.value === "" ? "US" : country.value}
        css={country.css}
        required={country?.required ?? false}
        onClick={country.onClick}
        error={country.error}
        lock={lock}
      />

      <Text region="postalcode" title="Postal Code" element="h5" />
      <TextInput
        region="input4"
        value={postalCode.value}
        onChange={postalCode.onChange}
        placeholder="Postal Code"
        required={postalCode?.required ?? false}
        error={postalCode.error}
        lock={lock}
      />
    </Card>
  );
}
export default AddressBlock;
