import Menu from "../features/metrics/Menu";
import ProductList from "../features/metrics/ProductList";
import Spinner from "../icons/Spinner";
import usePage from "../usePage/usePage";
import useServer from "../hooks/useServer";
import { useEffect } from "react";
import Container from "../components/Container";
import ProcessFix from "../features/metrics/ProcessFix";
import ModalBase from "../components/ModalBase";
import SetEndTime from "../features/metrics/SetEndTime";
import { STATES } from "mongoose";

const initial = {
  modal: {
    open: false,
    title: "",
    date: "",
    end: "",
    msg: "",
    processLogId: "",
  },
  employee: {},
  startDate: "",
  endDate: "",
  empOne: { _id: "", firstName: "", lastName: "", product: { _id: "" } },
  customer: {},
  selected: { skuId: "", sku: "" },
  employees: [],
  products: [],
  customers: [],
  processLogs: [],
};

export default function Metrics() {
  const { state, load, loaded, set, reset } = usePage(initial);
  const { get } = useServer();

  //LOAD EMPLOYEES
  useEffect(() => {
    async function effect() {
      load("employees", () => get("/employees"), {
        type: "init",
        then: (data) => {
          data.map((employee) => {
            return { ...employee, info: false };
          });
          return data;
        },
      });
    }
    effect();
  }, [load, get]);

  //LOAD CUSTOMERS
  useEffect(() => {
    async function effect() {
      load("customers", () => get("/customer/list"), { type: "init" });
    }
    effect();
  }, [load, get]);

  //LOAD UNFINISHED PROCESS LOGS
  useEffect(() => {
    async function effect() {
      load("processLogs", () => get("/process/unfinished/logs"), {
        type: "init",
      });
    }
    effect();
  }, [load, get]);

  return (
    <Container style={{ flexDirection: "column" }}>
      <ModalBase
        title={state?.modal?.title}
        open={state?.modal?.open}
        close={() => {
          reset("modal");
        }}
      >
        <SetEndTime />
      </ModalBase>
      <Menu />
      <ProcessFix />
      {/* {loaded("customers") ? <ProductList /> : <Spinner />} */}
    </Container>
  );
}
