function format_product_payload(state) {
  const {
    address_customerId,
    address_profileName,
    product_id,
    product_name,
    product_desc,
    product_skus,
    product_skus_delete,
    product_process_id,
    product_process_name,
    product_parts,
    product_type,
  } = state;

  return {
    _id: product_id ?? "",
    name: product_name,
    desc: product_desc,
    type: product_type,
    customerId: address_customerId,
    customerName: address_profileName,
    processId: product_process_id,
    processName: product_process_name,
    parts: product_parts,
    archived: false,
    skus: [...product_skus].map((sku) => {
      return {
        ...sku,
        requirements: [...sku.requirements].map((req) => {
          return {
            ...req,
            usage: parseFloat(req.usage),
          };
        }),
      };
    }),
    skus_delete: product_skus_delete,
  };
}
export default format_product_payload;
