import styled from "styled-components";
import { useState, useEffect } from "react";
import ErrorBox from "./ErrorBox";

const Container = styled.div`
  grid-area: ${(props) => props.$region};
  position: relative;
  ${(props) => props.$more}
`;

const CheckBoxStyle = styled.input`
  appearance: none;
  margin: 0;
  font: inherit;
  color: var(--text);
  width: 1.15em;
  height: 1.15em;
  border: 2px solid var(--text);
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  background-color: #fff;
  -webkit-appearance: none;
  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em
      ${({ $lock }) => ($lock ? `var(--secondary)` : `var(--primary)`)};
  }
  ${(props) =>
    props.$checked &&
    `
  &::before {
    transform: scale(1);
  }
`}
`;

function CheckBox(props) {
  const {
    value,
    onClick = () => {},
    region,
    error = false,
    lock,
    style,
  } = props;

  const [isError, setIsError] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  //check for errors
  useEffect(() => {
    error === true ? setIsError(true) : setIsError(false);
  }, [error]);

  const lockStatus = () => {
    if (lock === undefined) {
      return "";
    } else if (lock === false) {
      return "input-unlock";
    } else if (lock === true) {
      return "input-lock";
    }
  };

  return (
    <Container className={`${lockStatus()}`} $region={region} $more={style}>
      <ErrorBox error={error}>
        <CheckBoxStyle
          type="checkbox"
          value={value}
          $checked={value}
          $lock={lock}
          disabled={lock}
          onClick={(e) => {
            onClick(e);
          }}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
        />
      </ErrorBox>
    </Container>
  );
}
export default CheckBox;
